import React from 'react';
import styled from 'styled-components';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';

import Heading from './Heading';
import Image from './Image';
import ArrowIcon from './ArrowIcon';

const ADAIcon = ({
  ada_accessible,
  ada_description,
  className,
  isDarkMode,
}) => {
  return (
    <ADAWrapper isDarkMode={isDarkMode}>
      <button
        className={`ada-button ${className ? className : ''}`}
        aria-labelledby={`tooltip-${
          ada_accessible ? 'accessible' : 'non-accessible'
        }`}
      >
        {ada_accessible ? (
          <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M66 33.7C66 15.9 51.5 1.5 33.7 1.5C15.9 1.5 1.5 15.9 1.5 33.7C1.5 51.5 15.9 66 33.7 66C51.5 66 66 51.5 66 33.7ZM0 33.7C0 15.1 15.1 0 33.7 0C52.3 0 67.4 15.1 67.4 33.7C67.4 52.3 52.3 67.5 33.7 67.5C15.1 67.5 0 52.3 0 33.7Z" />
            <path d="M41.8 18.3C40.2 18.3 38.8 19.6 38.8 21.3C38.8 22.9 40.1 24.3 41.8 24.3C43.4 24.3 44.8 23 44.8 21.3C44.7 19.7 43.4 18.3 41.8 18.3Z" />
            <path d="M31.4 20.5C30.4 20.5 29.3 20.9 28.5 21.7L25 25L26.8 26.9L30.3 23.5C30.6 23.2 31 23.1 31.4 23.1C31.7 23.1 31.9 23.2 32.1 23.3L34.5 24.6L26.8 31.8C26.7 31.8 26.7 31.9 26.6 31.9L28.1 33.4C29 32.9 30 32.7 31.1 32.7C34.7 32.7 37.7 35.6 37.7 39.3C37.7 40.4 37.4 41.4 37 42.3L38.5 43.8C39.3 42.5 39.8 41 39.8 39.3C39.8 37.7 39.4 36.2 38.6 34.9L42.4 34.7L41.5 43.6L44.1 43.9L45.1 33.5C45.1 33.1 45 32.7 44.7 32.5C44.5 32.2 44.1 32.1 43.8 32.1H43.7L37.5 32.5L40.8 28.2C41.7 27.1 41.3 25.5 40.1 24.9L36.9 23L33.4 21C32.7 20.7 32 20.5 31.4 20.5Z" />
            <path d="M25.3 32.9C23.6 34.5 22.5 36.7 22.5 39.3C22.5 44 26.4 47.9 31.1 47.9C33.6 47.9 35.9 46.8 37.5 45.1L36 43.6C34.8 45 33 45.8 31.1 45.8C27.5 45.8 24.5 42.9 24.5 39.2C24.5 37.2 25.4 35.5 26.7 34.3L25.3 32.9Z" />
          </svg>
        ) : (
          <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M41.8 25.8H50.6V28H44V35.7H36.3V43.4H28.6V51.1H20.1V48.9H26.4V41.2H34.1V33.5H41.8V25.8ZM20.5 34.1L34.8 19.8V25H37V16H28V18.2H33.2L19 32.5L20.5 34.1ZM67.1 33.6C67.1 52.1 52 67.2 33.5 67.2C15.1 67.1 0 52.1 0 33.6C0 15.1 15.1 0 33.6 0C52.1 0 67.1 15.1 67.1 33.6ZM65.7 33.6C65.7 15.9 51.3 1.5 33.6 1.5C15.9 1.5 1.5 15.9 1.5 33.6C1.5 51.3 15.9 65.7 33.6 65.7C51.3 65.7 65.7 51.3 65.7 33.6Z" />
          </svg>
        )}

        <span
          className="ada-tooltip"
          id={`tooltip-${ada_accessible ? 'accessible' : 'non-accessible'}`}
        >
          {ada_description.text}
        </span>
      </button>
    </ADAWrapper>
  );
};

const ParkEntranceAlt = ({ content, index, isDarkMode }) => {
  const {
    name,
    location,
    link_text,
    google_maps_url,
    ada_accessible,
    ada_description,
    image,
  } = content;

  return (
    <Row>
      <Col base={12}>
        <EntranceWrapper index={index} isDarkMode={isDarkMode}>
          <Row>
            <MediaContainer>
              <Image image={image} aspectRatio={16 / 9} />
            </MediaContainer>

            <Col base={12} className="entrance-info">
              <ArrowIcon width={48} rotation={0} />

              <ADAIcon
                ada_accessible={ada_accessible}
                ada_description={ada_description}
                isDarkMode={isDarkMode}
                className="ada--icon"
              />

              <Row className="row-1" center="sm">
                <Col base={12} sm={8} md={6}>
                  <Heading level="h3" size="m" content={name} />
                </Col>
              </Row>

              <Row className="row-2">
                <Col base={8} xs={6} sm={12} lg={6}>
                  <p>{location}</p>
                </Col>

                <Col base={4} xs={6} sm={12} lg={6}>
                  <a
                    href={google_maps_url}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={`Get directions to the ${name}`}
                    className="link-text"
                  >
                    {link_text}
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </EntranceWrapper>
      </Col>
    </Row>
  );
};

export default ParkEntranceAlt;

/**
 * Styled Components
 *
 */
const EntranceWrapper = styled.div`
  display: block;
  margin-bottom: var(--space-xl);
  position: relative;

  @media (${bp.min.xs_sm}) {
    margin-bottom: 7.2rem;
  }

  @media (${bp.min.md}) {
    margin-bottom: var(--space-xl);
  }

  ${props =>
    props.index === 1 &&
    `
    &:before {
      content: '';
      width: 0.1rem;
      height: calc(100% + var(--space-m));
      background-color: var(--color-black);
      position: absolute;
      top: 0;
      left: -1.2rem;
      display: none;

      @media (${bp.min.sm}) {
        display: block;
      }

      @media (${bp.min.md}) {
        display: block;
        height: calc(100% + 6rem);
        top: -2.4rem;
      }
    }
  `}

  ${props =>
    props.isDarkMode &&
    `
    &:before {
      background-color: var(--color-white);

    }
  `}

  &:hover {
    color: var(--color-black);
  }

  .arrow-icon {
    width: 4rem;
    height: auto;
    transition: all 200ms var(--ease-cubic-in-out);
    stroke: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};

    @media (${bp.min.sm}) {
      display: none;
    }
  }

  .entrance-info {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: var(--space-s);
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};

    @media (${bp.min.sm_md}) {
      margin-top: 0;
    }

    .row-1 {
      margin-top: var(--space-m);
      margin-bottom: var(--space-s);

      @media (${bp.min.sm}) {
        margin-top: var(--space-l);
        margin-bottom: var(--space-m);
        text-align: center;
      }

      @media (${bp.min.lg}) {
        margin-bottom: var(--space-xxl);
      }
    }

    .row-2 {
      align-items: baseline;

      @media (${bp.min.sm}) {
        text-align: center;
        padding: 0 var(--space-l);
      }

      @media (${bp.min.sm_md}) {
        text-align: center;
        padding: 0 var(--space-xl);
      }

      div:last-of-type {
        display: flex;
        justify-content: flex-end;

        @media (${bp.min.sm}) {
          display: block;
        }
      }

      p {
        line-height: 1;

        @media (${bp.min.sm}) {
          line-height: 1.4;
        }

        @media (${bp.min.lg}) {
          line-height: 1;
        }
      }
    }
  }

  .link-text {
    font-size: 1.3rem;
    line-height: 1;
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
    transition: color 200ms var(--ease-quart-in-out);
    text-align: right;
    white-space: nowrap;

    @media (${bp.min.xs}) {
      font-size: 1.7rem;
    }

    @media (${bp.min.sm}) {
      font-size: 2rem;
      text-align: center;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-neon-green);

        &:after {
          transform: translate(0.2rem, -0.2rem);
        }
      }
    }

    &:after {
      content: '↗';
      display: inline-block;
      margin-left: 0.2rem;
      transition: transform 200ms var(--ease-quart-in-out);

      @media (${bp.min.xs}) {
        margin-left: var(--space-xxxs);
      }
    }
  }
`;

const MediaContainer = styled.div`
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  pointer-events: none;
  display: block;

  @media (${bp.min.sm}) {
    display: none;
  }
`;

const ADAWrapper = styled.div`
  .ada--icon {
    display: block;
    right: 0.4rem;

    @media (${bp.min.sm}) {
      right: initial;
      display: flex;
      text-align: center;
      margin: 0 auto;
    }
  }

  .ada--icon,
  .ada-button {
    position: absolute;
    top: 0;
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};

    @media (${bp.min.sm}) {
      position: relative;
    }

    svg {
      width: 4.4rem;
      height: 4.4rem;

      @media (${bp.min.sm}) {
        width: 4.8rem;
        height: 4.8rem;
      }

      @media (${bp.min.sm_md}) {
        width: 6.8rem;
        height: 6.8rem;
        position: relative;
      }
    }
  }

  .ada-button {
    cursor: pointer;

    .ada--icon {
      right: 0.4rem;

      @media (${bp.min.sm}) {
        right: initial;
      }
    }

    svg {
      max-width: 100%;
      transition: all 200ms var(--ease-cubic-in-out);
    }

    &:hover,
    &:focus {
      color: var(--color-neon-green);

      .ada-tooltip {
        transform: translateX(0);
        opacity: 1;

        @media (${bp.min.sm}) {
          transform: translate(-50%, 0);
        }
      }
    }
  }

  .ada-tooltip {
    position: absolute;
    top: -110%;
    right: 130%;
    width: 22rem;
    font-size: 1.5rem;
    color: var(--color-white);
    padding: var(--space-xxs);
    background-image: linear-gradient(
      180deg,
      var(--color-neon-green-light) -40.64%,
      var(--color-neon-green) 102.15%
    );
    border-radius: 0.4rem 0.4rem 0 0.4rem;
    transform: translateX(0.4rem);
    opacity: 0;
    pointer-events: none;
    transition: all 300ms var(--ease-cubic-in-out);

    @media (${bp.min.sm}) {
      left: 50%;
      top: -185%;
      transform: translate(-50%, 0.4rem);
      border-radius: 0.4rem;
    }

    @media (${bp.min.sm_md}) {
      top: -135%;
    }

    @media (${bp.min.lg}) {
      top: -165%;
      width: 28rem;
      font-size: 2rem;
    }

    &:before {
      --size: 0.8rem;

      content: '';
      position: absolute;
      right: calc(var(--size) * -1);
      bottom: 0;
      width: 0;
      height: 0;
      z-index: 5;
      border-style: solid;
      border-width: var(--size) 0 0 var(--size);
      border-color: transparent transparent transparent var(--color-neon-green);

      @media (${bp.min.sm}) {
        bottom: -0.8rem;
        left: 50%;
        transform: translateX(-50%);
        border-width: 0.8rem 0.6rem 0 0.6rem;
        border-color: var(--color-neon-green) transparent transparent
          transparent;
      }
    }
  }
`;
