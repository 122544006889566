import React, { useRef, useEffect } from 'react';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { gsap } from 'gsap';
import FocusTrap from 'focus-trap-react';

import {
  createTranslation,
  toKebabCase,
  isBrowser,
} from '../../assets/js/utilities';
import { Row, Col } from '../grid-system';
import bp from '../../assets/js/breakpoints';

import SocialLinks from '../SocialLinks';
import Logo from '../Logo';
import Link from '../Link';

const Menu = ({
  content: {
    menu_desktop_main,
    menu_desktop_visit,
    menu_desktop_vision,
    menu_social,
    copyright,
    charitable_disclaimer,
    address_title,
    address,
    contact_title,
    contact,
    entrance_title,
    entrance,
    hours_and_info_title,
    hours_and_info,
  },
  lang,
  menuIsOpen,
  toggleMenuIsOpen,
}) => {
  const menuRef = useRef(null);

  const isMobile = isBrowser && window.innerWidth < 750;

  useEffect(() => {
    if (!menuIsOpen) return;
    const q = gsap.utils.selector(menuRef);

    gsap.from(q('h2, li, p, svg'), {
      opacity: 0,
      y: 16,
      duration: 0.32,
      stagger: 0.02,
      ease: 'power2.out',
    });
  }, [menuIsOpen]);

  return (
    <FocusTrap
      active={!isMobile && menuIsOpen}
      focusTrapOptions={{
        allowOutsideClick: true,
        clickOutsideDeactivates: true,
        preventScroll: true,
        checkCanFocusTrap: trapContainers => {
          const results = trapContainers.map(trapContainer => {
            return new Promise(resolve => {
              const interval = setInterval(() => {
                if (getComputedStyle(trapContainer).visibility !== 'hidden') {
                  resolve();
                  clearInterval(interval);
                }
              }, 150);
            });
          });

          // Return a promise that resolves when all the trap containers
          //  are able to receive focus
          return Promise.all(results);
        },
      }}
    >
      <MenuWrapper menuIsOpen={menuIsOpen} ref={menuRef}>
        <MenuInner>
          <Row>
            <Col base={12} sm={3} sm_md={2} lgOffset={1}>
              <MainMenu>
                {menu_desktop_main.map(item => (
                  <li key={item.link_text}>
                    <Link
                      url={item.link_url}
                      anchorLink={
                        item.anchor_link
                          ? toKebabCase(item.anchor_link_hash)
                          : null
                      }
                      onMenuClick={toggleMenuIsOpen}
                    >
                      {item.link_text}
                    </Link>
                  </li>
                ))}
              </MainMenu>
            </Col>

            <Col base={12} sm={4} sm_md={3} sm_mdOffset={1} lgOffset={0}>
              <VisitMenu>
                <h2>{createTranslation('Visit', lang)}</h2>

                <ul>
                  {menu_desktop_visit.map(item => (
                    <li key={item.link_text}>
                      <Link
                        url={item.link_url}
                        anchorLink={
                          item.anchor_link
                            ? toKebabCase(item.anchor_link_hash)
                            : null
                        }
                        onMenuClick={toggleMenuIsOpen}
                      >
                        {item.link_text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </VisitMenu>
            </Col>

            <Col base={12} sm={4} sm_md={3}>
              <VisionMenu>
                <h2>{createTranslation('Vision', lang)}</h2>

                <ul>
                  {menu_desktop_vision.map(item => (
                    <li key={item.link_text}>
                      <Link
                        url={item.link_url}
                        anchorLink={
                          item.anchor_link
                            ? toKebabCase(item.anchor_link_hash)
                            : null
                        }
                        onMenuClick={toggleMenuIsOpen}
                      >
                        {item.link_text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </VisionMenu>
            </Col>

            <Col base={12} sm_md={3}>
              <InfoColumn>
                <Section>
                  <h2>{hours_and_info_title}</h2>
                  <RichText render={hours_and_info.richText} />
                </Section>

                <Section>
                  <h2>{entrance_title}</h2>
                  <RichText render={entrance.richText} />
                </Section>

                <Social>
                  <h2>{createTranslation('Follow Along', lang)}</h2>
                  <SocialLinks content={menu_social} />
                </Social>
              </InfoColumn>
            </Col>
          </Row>

          <Row className="menu__bottom-row">
            <Col base={12} sm_md={2} lgOffset={1}>
              <Link
                url={{ url: '/', link_type: 'Document' }}
                onMenuClick={toggleMenuIsOpen}
              >
                <Logo fill="var(--color-white)" />
              </Link>
            </Col>

            <Col base={12} sm_md={3} sm_mdOffset={1} lgOffset={0}>
              <SectionSmall>
                <h2>{contact_title}</h2>
                <RichText render={contact.richText} />
              </SectionSmall>
            </Col>

            <Col base={12} sm_md={3}>
              <SectionSmall>
                <h2>{address_title}</h2>
                <RichText render={address.richText} />
              </SectionSmall>
            </Col>

            <Col base={12} sm_md={3}>
              <Copyright>
                <p>
                  © {new Date().getFullYear()} {copyright}
                </p>
                <RichText render={charitable_disclaimer.richText} />
              </Copyright>
            </Col>
          </Row>
        </MenuInner>
      </MenuWrapper>
    </FocusTrap>
  );
};

export default Menu;

/**
 * Styled Components
 *
 */
const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
  z-index: 100;
  padding-top: 11.6rem;
  display: none;
  opacity: ${props => (props.menuIsOpen ? 1 : 0)};
  pointer-events: ${props => (props.menuIsOpen ? 'auto' : 'none')};
  transition: all 100ms var(--ease-quart-in-out);

  @media (${bp.min.sm}) {
    display: block;
    visibility: ${props => (props.menuIsOpen ? 'visible' : 'hidden')};
  }
`;

const MenuInner = styled.div`
  height: 100%;
  padding: 5% var(--space-m) 0;
  max-width: 168rem;
  margin: 0 auto;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.2);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (${bp.min.sm_md}) {
    padding: 5% 4.8rem 0;
  }

  h2,
  a,
  p {
    color: var(--color-white);
  }

  a {
    &:hover {
      color: var(--color-neon-green);
    }
  }

  .menu__bottom-row {
    margin-top: 9.6rem;
    margin-bottom: var(--space-xxl);
    display: none;

    @media (${bp.min.sm_md}) {
      display: flex;
    }

    h2,
    p,
    a {
      font-size: 1.2rem;
      line-height: 2rem;

      @media (${bp.min.md}) {
        font-size: 1.5rem;
        line-height: 2.4rem;
      }
    }

    a:hover svg {
      fill: var(--color-rail-park-green);
    }

    svg {
      width: 7.2rem;
      height: auto;
    }
  }
`;

const MainMenu = styled.ul`
  li {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    width: fit-content;

    & + li {
      margin-top: var(--space-m);
    }
  }
`;

const VisitMenu = styled.div`
  h2 {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  li {
    font-family: var(--font-family-condensed);
    font-size: clamp(8rem, 6.8302rem + 1.8868vw, 10rem);
    text-transform: uppercase;
    line-height: 0.76;
    letter-spacing: -0.1rem;
    margin-top: var(--space-xl);
    width: fit-content;

    a {
      --hover-offset: var(--space-xs);

      display: inline-block;
      position: relative;
      transition: all 200ms var(--ease-cubic-in-out);

      &:before {
        content: '';
        position: absolute;
        width: var(--hover-offset);
        height: 100%;
        left: 0;
      }

      &:hover {
        transform: translateX(var(--hover-offset));

        &:before {
          left: calc(var(--hover-offset) * -1);
        }
      }
    }
  }
`;

const VisionMenu = styled(VisitMenu)``;

const InfoColumn = styled.div`
  display: none;

  @media (${bp.min.sm_md}) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const Section = styled.div`
  & + & {
    margin-top: var(--space-xl);
  }

  h2 {
    --margin-bottom: var(--space-s);

    @media (${bp.min.sm_md}) {
      --margin-bottom: var(--space-l);
    }

    font-size: 1.5rem;
    line-height: 2.4rem;
    margin-bottom: var(--margin-bottom);
  }

  p {
    --font-size: 2rem;

    @media (${bp.min.md}) {
      --font-size: 2.4rem;
    }

    @media (${bp.min.lg}) {
      --font-size: 3rem;
    }

    font-size: var(--font-size);
    line-height: 1.35;
    letter-spacing: -0.03rem;
  }

  a,
  p {
    color: var(--color-white);
  }

  a {
    display: inline-block;

    &:hover {
      color: var(--color-neon-green);
    }
  }
`;

const Social = styled(Section)`
  margin-top: auto !important;

  h2 {
    --margin-bottom: var(--space-m);

    @media (${bp.min.sm_md}) {
      --margin-bottom: var(--space-xxs);
    }

    @media (${bp.min.lg}) {
      --margin-bottom: var(--space-m);
    }
  }
`;

const Copyright = styled.div`
  margin-top: var(--space-l);

  @media (${bp.min.sm_md}) {
    margin-top: 0;
  }

  p {
    --font-size: 1rem;
    --line-height: 1.6rem;

    @media (${bp.min.sm}) {
      --font-size: 1.2rem;
    }

    @media (${bp.min.sm_md}) {
      --font-size: 1.5rem;
      --line-height: 2.4rem;
    }

    font-size: var(--font-size);
    line-height: var(--line-height);
  }

  p + p {
    @media (${bp.min.sm_md}) {
      margin-top: var(--space-xs);
    }
  }
`;

const SectionSmall = styled.div`
  h2 {
    --margin-bottom: var(--space-xs);

    @media (${bp.min.sm_md}) {
      --margin-bottom: var(--space-xs);
    }

    line-height: 2.4rem;
    margin-bottom: var(--margin-bottom);
  }

  h2,
  p,
  a {
    font-size: 1.5rem;
    line-height: 2.4rem;
  }

  a {
    display: inline-block;
  }
`;
