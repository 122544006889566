import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { createTranslation } from '../assets/js/utilities';

const SearchBar = ({ lang, toggleMenuIsOpen }) => {
  const [query, setQuery] = useState('');

  const handleInput = event => {
    setQuery(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    toggleMenuIsOpen(false);

    const searchURL =
      lang === 'en'
        ? `/search/`
        : lang === 'es'
        ? `/es/search/`
        : `/${lang}/search/`;

    navigate(`${searchURL}?query=${query}`);
  };

  return (
    <SearchBarWrapper>
      <form onSubmit={handleSubmit}>
        <svg
          width="28"
          height="29"
          viewBox="0 0 28 29"
          fill="none"
          stroke="#131313"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11.5133" cy="11.5133" r="10.5133" />
          <line x1="18.6956" y1="19.2251" x2="27.3536" y2="27.883" />
        </svg>
        <input
          type="text"
          placeholder={createTranslation('Search', lang)}
          onChange={handleInput}
          value={query}
        />
      </form>
    </SearchBarWrapper>
  );
};

export default SearchBar;

const SearchBarWrapper = styled.div`
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    stroke: var(--color-white);
    stroke-width: 0.2rem;
  }

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: 2.8rem;
    color: var(--color-white);
    padding-left: 4.8rem;
    padding-right: var(--space-m);

    &:focus {
      outline: 0;

      &::placeholder {
        opacity: 0.25;
      }
    }

    &::placeholder {
      color: var(--color-white);
      opacity: 0.7;
      transition: opacity 200ms var(--ease-cubic-in-out);
    }
  }
`;
