import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';

import Heading from './Heading';
import Link from './Link';

import donateClouds from '../assets/images/donate-clouds.jpg';
import donateLeaves from '../assets/images/donate-leaves.png';
import donateBranch from '../assets/images/donate-branch.png';

const DonateCallout = ({ content: { headline, link_text, link_url } }) => {
  return (
    <CalloutWrapper>
      <Row>
        <Col base={12} sm={9} md={8} smOffset={1} mdOffset={2}>
          <Link url={{ url: link_url.url, link_type: 'Document' }}>
            <Row center="base" middle="sm">
              <Col base={10} xs={8} sm={5} lg={4}>
                <DonateFlower>
                  <div className="image-background" />
                </DonateFlower>
              </Col>

              <Col base={12} sm={7} lg={8} className="donate-info">
                <Heading level="h2" size="l" content={headline} />
                <div className="button-text">{link_text}</div>
              </Col>
            </Row>
          </Link>
        </Col>
      </Row>
    </CalloutWrapper>
  );
};

export default DonateCallout;

/**
 * Styled Components
 *
 */
const CalloutWrapper = styled.section`
  padding: 12rem 0 var(--space-xxl);
  background-color: var(--color-off-white);
  position: relative;

  @media (${bp.min.sm}) {
    padding: var(--space-xxxl) 0 9.6rem;
  }

  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: var(--color-off-white);
    z-index: -1;
  }

  a {
    display: block;

    @media (hover: hover) {
      &:hover {
        color: var(--color-black);

        .image-background {
          clip-path: circle(46%);
        }

        .button-text {
          color: var(--color-white);
          background-color: var(--color-neon-green);
          border-color: var(--color-neon-green);
        }
      }
    }
  }

  .donate-info {
    text-align: center;

    @media (${bp.min.sm}) {
      text-align: left;
    }
  }

  .heading-tag {
    margin-bottom: var(--space-m);

    p:lang(en) {
      white-space: nowrap;
    }

    @media (${bp.min.sm_md}) {
      margin-bottom: var(--space-l);
    }
  }

  .button-text {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    border: 0.1rem solid var(--color-black);
    padding: var(--space-xs) var(--space-m);
    border-radius: 4rem;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.05rem;
    cursor: pointer;
    transition: all 200ms var(--ease-quart-in-out);
  }
`;

const DonateFlower = styled.div`
  position: relative;
  margin-bottom: var(--space-m);

  @media (${bp.min.sm}) {
    margin-right: var(--space-l);
    margin-bottom: 0;
  }

  &:before {
    content: '';
    background-image: url(${donateBranch});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: -19%;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  .image-background {
    position: relative;
    background-image: linear-gradient(to top, #8bd4da, #10aac6);
    padding-top: 100%;
    clip-path: circle(50%);
    transition: clip-path 400ms var(--ease-bounce-in-out);

    &:before {
      content: '';
      background-image: url(${donateLeaves});
      background-size: 101%;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
    }

    &:after {
      content: '';
      background-image: url(${donateClouds});
      background-size: contain;
      background-repeat: repeat-x;
      width: 300rem;
      height: 100%;
      position: absolute;
      top: 0;
      filter: blur(0.2rem);
      animation: cloudMove 200s linear forwards infinite;
    }

    @keyframes cloudMove {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-150rem);
      }
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment DonateCallout on PrismicDonateCallout {
    data {
      headline {
        richText
      }
      link_text
      link_url {
        link_type
        url
      }
    }
  }
`;
