import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ children, url, anchorLink, onMenuClick }) => {
  const anchorHash = !!anchorLink ? `#${anchorLink}` : '';

  if (url.link_type === 'Document') {
    return (
      <GatsbyLink
        to={`${url.url}${anchorHash}`}
        activeClassName="is-active"
        className="link-tag"
        onClick={onMenuClick}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={url.url} target="_blank" rel="noreferrer" className="link-tag">
      {children}
    </a>
  );
};

export default Link;
