import React from 'react';
import styled from 'styled-components';
import bp from '../../assets/js/breakpoints';

const Text = ({ slice }) => {
  const data = slice.primary;

  return (
    <TextWrapper>
      <div dangerouslySetInnerHTML={{ __html: data.text.html }} />
    </TextWrapper>
  );
};

const TextWrapper = styled.div`
  width: 100%;
  margin-top: var(--space-xs);

  @media (${bp.min.sm}) {
    margin-top: var(--space-xl);
  }

  h2 {
    font-size: clamp(3.5rem, 3.1471rem + 1.1029vw, 5rem);
    line-height: 1;
    letter-spacing: -0.15rem;
    margin-bottom: var(--space-xs);
  }

  h3 {
    font-size: clamp(2rem, 1.6471rem + 1.1029vw, 3.5rem);
    line-height: 1.2;
    letter-spacing: -0.05rem;
    margin-bottom: var(--space-xxxs);
  }

  h4 {
    font-size: 2rem;
    line-height: 1.4;
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: var(--space-xxxs);
  }

  a {
    color: var(--color-neon-green);

    &:hover {
      color: var(--color-neon-green-light);
    }
  }

  .intro {
    font-size: 2.4rem;
  }

  .button a,
  a span.button {
    display: inline-flex;
    align-items: center;
    border: 0.1rem solid var(--color-black);
    background-color: transparent;
    color: var(--color-black);
    padding: var(--space-xs) var(--space-m);
    margin-top: var(--space-s);
    border-radius: 4rem;
    font-size: 1.8rem;
    line-height: 1;
    cursor: pointer;
    transition: all 200ms var(--ease-quart-in-out);

    &:hover {
      color: var(--color-white);
      background-color: var(--color-neon-green);
      border-color: var(--color-neon-green);
    }
  }

  p {
    & + h2,
    & + h3,
    & + h4 {
      margin-top: var(--space-xxl);
    }

    & + p {
      margin-top: var(--space-m);
    }
  }

  ul,
  ol {
    margin-top: var(--space-xxs);

    li {
      margin-top: var(--space-xxxs);
    }

    & + h2,
    & + h3,
    & + h4 {
      margin-top: var(--space-xl);

      @media (${bp.min.sm}) {
        margin-top: var(--space-xxl);
      }
    }

    & + p {
      margin-top: var(--space-m);
    }
  }

  ul {
    list-style-position: inside;
    margin-bottom: var(--space-l);

    li {
      margin-left: var(--space-m);

      &:before {
        content: '–';
        margin-left: -2.4rem;
        position: absolute;
      }
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  .block-img {
    & + p {
      margin-top: 0;
    }
  }

  img {
    max-width: 100%;
    border-radius: 0.5rem;
  }
`;

export default Text;
