import React from 'react';
import styled from 'styled-components';

const Embed = ({ slice }) => {
  const data = slice.primary;

  return (
    <EmbedWrapper>
      <div dangerouslySetInnerHTML={{ __html: data.embed.text }} />
    </EmbedWrapper>
  );
};

export default Embed;

/**
 * Styled Components
 *
 */
const EmbedWrapper = styled.div`
  margin: var(--space-xl) 0;
`;
