import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Grid from './Grid';
import Row from './Row';
import Col from './Col';

const GridWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1000;
  display: ${p => !p.showGrid && 'none'};

  ${Col},
  ${Row} {
    height: 100%;
  }

  ${Col} {
    div {
      height: 100%;
      background: rgba(255, 50, 50, 0.1);
    }
  }
`;

const OverlayGrid = ({ theme, enableGridOverlay }) => {
  const [showGrid, setShowGrid] = useState(false);

  useEffect(() => {
    if (!enableGridOverlay) {
      setShowGrid(false);

      return;
    }

    function handleKeyDown(e) {
      if (enableGridOverlay && e.code === 'KeyG' && e.ctrlKey) {
        setShowGrid(prevState => !prevState);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [enableGridOverlay]);

  return (
    <GridWrapper showGrid={showGrid}>
      <Grid>
        <Row>
          {[...Array(theme.flexboxgrid.columns).keys()].map(column => (
            <Col key={column} base>
              <div />
            </Col>
          ))}
        </Row>
      </Grid>
    </GridWrapper>
  );
};

export default OverlayGrid;
