import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import Image from '../components/Image';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText);
}

const Icon = ({ variant, isDarkMode }) => {
  return (
    <IconWrapper isDarkMode={isDarkMode}>
      {variant === 'day' ? (
        <svg
          width="28"
          height="29"
          viewBox="0 0 28 29"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="day"
        >
          <path d="M13.8162 20.126C16.74 20.126 19.1102 17.7558 19.1102 14.8321C19.1102 11.9083 16.74 9.53809 13.8162 9.53809C10.8924 9.53809 8.52222 11.9083 8.52222 14.8321C8.52222 17.7558 10.8924 20.126 13.8162 20.126Z" />
          <path d="M13.8162 1.39355V6.48392" />
          <path d="M13.8162 23.1804V28.2708" />
          <path d="M21.7571 3.97266L18.7029 8.11282" />
          <path d="M8.92946 21.5515L5.94312 25.6917" />
          <path d="M26.6441 10.6919L21.7573 12.2529" />
          <path d="M5.94314 17.4111L1.0564 18.9722" />
          <path d="M26.6441 18.9724L21.7573 17.4114" />
          <path d="M5.94314 12.2529L1.0564 10.6919" />
          <path d="M21.7571 25.6917L18.7029 21.5515" />
          <path d="M8.92946 8.11282L5.94312 3.97266" />
        </svg>
      ) : (
        <svg
          width="23"
          height="24"
          viewBox="0 0 23 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="night"
        >
          <path d="M15.3186 18.6697C10.9113 18.1383 7.38539 14.6176 6.91075 10.2998C6.50392 6.5799 8.33466 3.32496 11.1825 1.46499C11.3859 1.33214 11.3181 1 11.0469 1C5.21562 1.59785 0.740483 6.5799 1.0117 12.5584C1.35073 18.1383 6.02929 22.6553 11.7249 22.9875C16.0644 23.1867 19.9293 20.9946 21.9635 17.6068C22.0991 17.4076 21.8279 17.2083 21.6245 17.2747C19.8615 18.3375 17.624 18.9354 15.3186 18.6697Z" />
        </svg>
      )}
    </IconWrapper>
  );
};

const VisitHero = ({
  content: { hero_title, hero_image_day, hero_image_night },
  isDarkMode,
  setIsDarkMode,
  lang,
}) => {
  const imageWrapper = useRef(null);
  const heroRef = useRef(null);
  const tl = useRef();

  useEffect(() => {
    const query = gsap.utils.selector(heroRef);

    tl.current = gsap.timeline();
    const splitText = new SplitText(query('h1'), {
      type: 'words',
    });

    tl.current
      .to(heroRef.current, {
        autoAlpha: 1,
        duration: 0.8,
        delay: 0.3,
      })
      .from(
        splitText.words,
        {
          opacity: 0,
          yPercent: 20,
          duration: 0.7,
          stagger: 0.05,
          ease: 'power2.out',
        },
        '<'
      )
      .from(
        imageWrapper.current,
        {
          opacity: 0,
          y: 20,
          duration: 0.5,
          ease: 'cubic.inOut',
        },
        '>-.7'
      );

    return () => {
      tl.current.kill();
    };
  }, []);

  useEffect(() => {
    const query = gsap.utils.selector(imageWrapper);

    gsap.to(query('.image--day'), {
      opacity: isDarkMode ? 0 : 1,
      duration: 0.75,
      ease: 'power1.inOut',
    });

    gsap.to(query('.image--night'), {
      opacity: isDarkMode ? 1 : 0,
      duration: 0.75,
      ease: 'power1.inOut',
    });
  }, [isDarkMode]);

  return (
    <HeroWrapper isDarkMode={isDarkMode} ref={heroRef}>
      <HeroTitle>{hero_title}</HeroTitle>

      <Row middle="base">
        <Col base={false} sm={2}>
          <button onClick={() => setIsDarkMode(false)}>
            <Icon variant="day" isDarkMode={isDarkMode} />
            {createTranslation('Day', lang)}
          </button>
        </Col>

        <Col base={false} sm={8}>
          <ImageWrapper ref={imageWrapper}>
            <div className="image--night">
              <Image
                image={hero_image_night}
                aspectRatio={16 / 9}
                borderRadius={5}
                loading="eager"
              />
            </div>
            <div className="image--day">
              <Image
                image={hero_image_day}
                aspectRatio={16 / 9}
                borderRadius={5}
                loading="eager"
              />
            </div>
          </ImageWrapper>
        </Col>

        <Col base={false} sm={2}>
          <button onClick={() => setIsDarkMode(true)}>
            <Icon variant="night" isDarkMode={isDarkMode} />
            {createTranslation('Night', lang)}
          </button>
        </Col>
      </Row>
    </HeroWrapper>
  );
};

export default VisitHero;

/**
 * Styled Components
 *
 */
const IconWrapper = styled.span`
  background-color: transparent;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin-bottom: var(--space-xxs);
  color: ${props =>
    props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};

  &,
  svg {
    transition: all 200ms var(--ease-cubic-in-out);
  }
`;

const ImageWrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(9 / 16 * 100%);
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .image--night,
  .image--day {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;

const HeroWrapper = styled.section`
  margin: var(--space-l) 0 var(--space-xl);
  text-align: center;

  @media (${bp.min.xs_sm}) {
    margin: var(--space-xl) 0;
  }

  @media (${bp.min.sm}) {
    margin: 4.8rem 0 8rem;
  }

  @media (${bp.min.md}) {
    margin: 4.8rem 0 12rem;
  }

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    font-size: 1.7rem;
    line-height: 1;
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};

    @media (${bp.min.md}) {
      font-size: 2rem;
    }

    &:hover {
      ${IconWrapper} {
        background-color: var(--color-white);

        svg {
          transform: scale(0.95);
        }

        .day {
          stroke: #ff9900;
        }

        .night {
          stroke: #7541cc;
        }
      }
    }
  }

  .dark-mode-animation-enter {
    opacity: 0;
  }

  .dark-mode-animation-enter-active {
    opacity: 1;
    transition: all 600ms var(--ease-cubic-in-out);
  }

  .dark-mode-animation-exit {
    opacity: 1;
  }

  .dark-mode-animation-exit-active {
    opacity: 0;
    transition: all 600ms var(--ease-cubic-in-out);
  }
`;

const HeroTitle = styled.h1`
  font-family: var(--font-family-condensed);
  color: var(--color-neon-green);
  text-transform: uppercase;
  font-size: clamp(10.5rem, 16vw, 25.5rem);
  line-height: 0.76;
  letter-spacing: -0.2rem;
  padding: 0 var(--space-xs);

  @media (${bp.min.sm}) {
    letter-spacing: -0.3rem;
    padding: 0 var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  @media (${bp.min.md}) {
    letter-spacing: -0.5rem;
  }

  &:lang(zh-cn) {
    font-size: clamp(6rem, 12vw, 13rem);
    line-height: 1.1;
    letter-spacing: 0;
    text-transform: none;
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment VisitHero on PrismicVisit {
    data {
      hero_title
      hero_image_day {
        alt
        gatsbyImageData(width: 1080)
      }
      hero_image_night {
        alt
        gatsbyImageData(width: 1080)
      }
    }
  }
`;
