import React from 'react';
import styled from 'styled-components';
import bp from '../../assets/js/breakpoints';

import Image from '../Image';

const LargeImage = ({ slice }) => {
  const data = slice.primary;

  return (
    <ImageWrapper>
      <Image image={data.image} borderRadius={5} />
    </ImageWrapper>
  );
};

export default LargeImage;

/**
 * Styled Components
 *
 */

const ImageWrapper = styled.div`
  --image-width: 96vw;

  width: var(--image-width);
  position: relative;
  top: 0;
  left: 50%;
  right: 50%;
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
  margin-left: calc((var(--image-width) / 2) * -1);
  margin-right: calc((var(--image-width) / 2) * -1);

  @media (${bp.min.sm}) {
    --image-width: 88vw;

    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  @media (${bp.min.md}) {
    --image-width: 70vw;
  }

  @media (${bp.min.xl}) {
    --image-width: 60vw;
  }
`;
