import React from 'react';

import Text from './slices/Text';
import LargeImage from './slices/LargeImage';
import Embed from './slices/Embed';
import YouTube from './slices/YouTube';
import TwoColumnText from './slices/TwoColumnText';
import ThreeColumnText from './slices/ThreeColumnText';

const SliceZone = ({ slices, activeDoc }) => {
  const sliceComponents = {
    text: Text,
    large_image: LargeImage,
    embed: Embed,
    youtube: YouTube,
    two_column_text: TwoColumnText,
    three_column_text: ThreeColumnText,
  };

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];

    if (SliceComponent) {
      return (
        <SliceComponent
          slice={slice}
          key={`slice-${index}`}
          activeDoc={activeDoc}
        />
      );
    }

    return null;
  });
};

export default SliceZone;
