import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Parallax } from 'react-scroll-parallax';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import bp from '../assets/js/breakpoints';
import {
  getActiveDocLanguage,
  toKebabCase,
  getSiteMeta,
  isBrowser,
  prefersReducedMotion,
} from '../assets/js/utilities';
import linkResolver from '../utilities/link-resolver';

import Seo from '../components/Seo';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import VisionHero from '../components/VisionHero';
import VisionFacts from '../components/VisionFacts';
import DonateCallout from '../components/DonateCallout';
import VisionMap from '../components/VisionMap';
import AnimateFadeIn from '../components/AnimateFadeIn';

gsap.registerPlugin(ScrollTrigger);

const Vision = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;
  const donateCallout = data.prismicDonateCallout;

  // Get page data
  const vision = data.prismicVision;
  const {
    title,
    hero_title,
    hero_subtitle,
    hero_description,
    vision_map_title,
    vision_map_description,
    vision_map_link_text,
    vision_map_link_url,
    facts,
    sections,
    meta_description,
    meta_image,
  } = vision.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(vision);
  const defaultMeta = getSiteMeta(layout.data);

  const imageHeader = useRef([]);
  const isMobile = isBrowser && window.innerWidth < 750;

  useEffect(() => {
    if (isMobile) return;

    setTimeout(() => {
      imageHeader.current.forEach((image, i) => {
        const query = gsap.utils.selector(image);

        gsap.set(query('h2, .section__neighborhood'), {
          y: 30,
          opacity: 0,
        });

        gsap.to(query('.scroll-container'), {
          clipPath: 'inset(1.5% round 1rem)',
          duration: 2,
          ease: 'none',
          scrollTrigger: {
            trigger: image,
            start: 'top center',
            end: 'bottom center',
            scrub: 0.5,
          },
        });

        gsap.to(query('h2, .section__neighborhood'), {
          y: 0,
          opacity: 1,
          duration: 0.5,
          delay: 0.2,
          stagger: 0.05,
          ease: 'cubic.inOut',
          scrollTrigger: {
            trigger: image,
            start: 'top 25%',
          },
        });
      });

      window.dispatchEvent(new Event('resize'));
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data} hideOverflowX>
      <Seo
        title={title}
        description={meta_description}
        image={meta_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
      />

      <AnimateFadeIn>
        <VisionHero content={{ hero_title, hero_subtitle, hero_description }} />

        <VisionMap
          content={{
            vision_map_title,
            vision_map_description,
            vision_map_link_text,
            vision_map_link_url,
          }}
          lang={activeDoc.locale}
        />

        <VisionFacts content={facts} lang={activeDoc.locale} />

        {sections.map((section, i) => {
          const isEven = i % 2 === 0;

          return (
            <VisionSections key={section.title}>
              <div
                id={
                  section.title === 'The Future'
                    ? 'progress'
                    : toKebabCase(section.title)
                }
              />
              <ImageHeader ref={el => (imageHeader.current[i] = el)}>
                <div className="scroll-container">
                  <Image image={section.main_image} aspectRatio={16 / 9} />

                  <div className="section-info">
                    <h2>{section.title}</h2>

                    <div className="section__neighborhood">
                      {section.neighborhood_1}

                      {section.neighborhood_2 && (
                        <>
                          <span>→</span>
                          {section.neighborhood_2}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </ImageHeader>

              <ImageGrid variant={isEven ? 'overview-right' : 'overview-left'}>
                <Parallax
                  y={[-10, 10]}
                  disabled={isMobile || prefersReducedMotion}
                  className="section-1"
                >
                  <Image
                    image={section.image_1}
                    aspectRatio={isEven ? 4 / 5 : 16 / 9}
                  />
                </Parallax>

                <Parallax
                  y={[-30, 35]}
                  disabled={isMobile || prefersReducedMotion}
                  className="section-2"
                >
                  <Image
                    image={section.image_2}
                    aspectRatio={isEven ? 16 / 9 : 4 / 5}
                  />
                </Parallax>

                <Parallax
                  y={[15, -15]}
                  disabled={isMobile || prefersReducedMotion}
                  className="section-3"
                >
                  <Image
                    image={section.image_3}
                    aspectRatio={isEven ? 1 / 1 : 16 / 9}
                  />
                </Parallax>

                <Parallax
                  y={[-25, 25]}
                  disabled={isMobile || prefersReducedMotion}
                  className="overview"
                >
                  <Heading level="h3" size="s" content={section.subtitle} />
                  <RichText render={section.description.richText} />
                </Parallax>
              </ImageGrid>
            </VisionSections>
          );
        })}

        <DonateCallout content={donateCallout.data} />
      </AnimateFadeIn>
    </Layout>
  );
};

export default withPrismicPreview(Vision, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const VisionSections = styled.section`
  margin-bottom: var(--space-xxl);

  @media (${bp.min.xs}) {
    margin: var(--space-xxl) 0;
  }

  @media (${bp.min.xs_sm}) {
    margin: var(--space-xxxl) 0 20rem;
  }
`;

const ImageHeader = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: var(--space-xl);
  border-radius: 0.5rem;
  overflow: hidden;
  transform: scale(0.95);

  @media (${bp.min.sm}) {
    margin-bottom: 12rem;
    height: 100vh;
    min-height: 70rem;
    border-radius: 0;
    transform: none;
  }

  .scroll-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .image-wrapper {
    overflow: hidden;

    &:after {
      content: '';
      background-image: linear-gradient(
        to bottom,
        hsla(0, 0%, 0%, 0) 0%,
        hsla(0, 0%, 0%, 0.013) 8.1%,
        hsla(0, 0%, 0%, 0.049) 15.5%,
        hsla(0, 0%, 0%, 0.104) 22.5%,
        hsla(0, 0%, 0%, 0.175) 29%,
        hsla(0, 0%, 0%, 0.259) 35.3%,
        hsla(0, 0%, 0%, 0.352) 41.2%,
        hsla(0, 0%, 0%, 0.45) 47.1%,
        hsla(0, 0%, 0%, 0.55) 52.9%,
        hsla(0, 0%, 0%, 0.648) 58.8%,
        hsla(0, 0%, 0%, 0.741) 64.7%,
        hsla(0, 0%, 0%, 0.825) 71%,
        hsla(0, 0%, 0%, 0.896) 77.5%,
        hsla(0, 0%, 0%, 0.951) 84.5%,
        hsla(0, 0%, 0%, 0.987) 91.9%,
        hsl(0, 0%, 0%) 100%
      );
      opacity: 0.5;
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .image-wrapper,
  .image {
    width: 100%;
    height: 100%;
  }

  .section-info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: var(--color-white);
    padding: 0 var(--space-m) var(--space-m);
    z-index: 5;

    @media (${bp.min.sm_md}) {
      padding: 0 4.8rem 5.6rem;
    }

    h2 {
      font-size: clamp(4.5rem, 2.0294rem + 7.7206vw, 15rem);
      font-weight: var(--font-weight-semi-bold);
      line-height: 0.88;
      letter-spacing: -0.2rem;

      @media (${bp.min.sm_md}) {
        letter-spacing: -0.5rem;
      }
    }
  }

  .section__neighborhood {
    font-size: 1.5rem;
    margin-top: var(--space-xxs);
    white-space: nowrap;
    display: none;

    @media (${bp.min.sm}) {
      display: block;
    }

    @media (${bp.min.sm_md}) {
      margin-top: var(--space-s);
    }

    @media (${bp.min.md}) {
      font-size: 2rem;
    }

    span {
      margin: 0 0.4rem;
    }
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  gap: 3.2rem 0.8rem;
  align-items: end;

  @media (${bp.min.sm}) {
    gap: var(--space-m) var(--space-m);
    align-items: start;
  }

  .overview {
    grid-column: 1 / -1;

    @media (${bp.min.xs_sm}) {
      grid-column: 1 / 9;
    }

    .heading-tag {
      @media (${bp.max.sm}) {
        font-weight: var(--font-weight-semi-bold);
        line-height: 1.5;
      }
    }
  }

  ${props =>
    props.variant === 'overview-right' &&
    `
   .section-1 {
     grid-column: 1 / 7;

     @media (${bp.min.sm}) {
       grid-column: 1 / 6;
       grid-row: 1 / 3;
     }
   }

   .section-2 {
     grid-column: 4 / -1;

     @media (${bp.min.sm}) {
       grid-column: 8 / -1;
       margin-top: var(--space-xxl);
     }
   }

   .section-3 {
     grid-column: 1 / 9;

     @media (${bp.min.sm}) {
       grid-column: 4 / 9;
     }
   }

   .overview {
     @media (${bp.min.sm}) {
       grid-column: 7 / 13;
       grid-row: 2 / 3;
       margin: 4.8rem 0;
     }

     @media (${bp.min.sm_md}) {
       margin: 9.6rem 0;
     }

     @media (${bp.min.lg}) {
       grid-column: 7 / 12;
       margin: 12rem 0;
     }

     p {
       margin-top: var(--space-m);
     }
   }
 `}

  ${props =>
    props.variant === 'overview-left' &&
    `
   .section-1 {
     grid-column: 2 / -1;

     @media (${bp.min.sm}) {
       grid-column: 2 / 10;
       grid-row: 1 / 1;
     }
   }

   .section-2 {
     grid-column: 1 / 8;

     @media (${bp.min.sm}) {
       grid-column: 8 / 12;
       grid-row: 2 / 4;
       margin-top: var(--space-xxl);
     }
   }

   .section-3 {
     grid-column: 5 / -1;

     @media (${bp.min.sm}) {
       grid-column: 3 / 7;
     }
   }

   .overview {
     @media (${bp.min.sm}) {
       grid-column: 1 / 7;
       grid-row: 2 / 3;
       margin: 12rem 0;
     }

     @media (${bp.min.sm_md}) {
       grid-column: 2 / 7;
       margin: 9.6rem 0;
     }

     @media (${bp.min.lg}) {
       margin: 12rem 0;
     }

     p {
       margin-top: var(--space-m);
     }
   }
 `}
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($language: String) {
    prismicVision(lang: { eq: $language }) {
      _previewable
      data {
        title
        sections {
          title
          subtitle {
            richText
          }
          description {
            richText
          }
          neighborhood_1
          neighborhood_2
          main_image {
            alt
            gatsbyImageData(width: 1200)
          }
          image_1 {
            alt
            gatsbyImageData(width: 650)
          }
          image_2 {
            alt
            gatsbyImageData(width: 600)
          }
          image_3 {
            alt
            gatsbyImageData(width: 600)
          }
        }
        meta_description
        meta_image {
          url
        }
      }
      ...VisionHero
      ...VisionMap
      ...VisionFacts
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
    prismicDonateCallout(lang: { eq: $language }) {
      ...DonateCallout
    }
  }
`;
