import React from 'react';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';

import Link from './Link';
import ArrowIcon from './ArrowIcon';

const Button = ({ children, link = '/', withArrow, variant = 'outline' }) => {
  const { link_type, url } = link;
  const isExternal = link_type !== 'Document';

  if (isExternal) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        <ButtonWrapper
          withArrow={withArrow}
          variant={variant}
          className="button-wrapper"
        >
          <span>{children}</span>

          {withArrow && <ArrowIcon width={16} color="currentColor" />}
        </ButtonWrapper>
      </a>
    );
  } else {
    return (
      <Link url={{ url: url, link_type: 'Document' }}>
        <ButtonWrapper
          variant={variant}
          withArrow={withArrow}
          className="button-wrapper"
        >
          <span>{children}</span>

          {withArrow && <ArrowIcon width={16} color="currentColor" />}
        </ButtonWrapper>
      </Link>
    );
  }
};

export default Button;

/**
 * Styled Components
 *
 */
const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: ${props => props.withArrow && `space-between`};
  border: ${props =>
    props.variant === 'outline' ? '0.1rem solid var(--color-black)' : 'none'};
  background-color: ${props =>
    props.variant === 'fill' ? 'var(--color-rail-park-green)' : 'transparent'};
  color: ${props =>
    props.variant === 'fill' ? 'var(--color-white)' : 'var(--color-black)'};
  padding: ${props =>
    props.withArrow
      ? `var(--space-xs) 2rem var(--space-xs) var(--space-m)`
      : `var(--space-xs) var(--space-m)`};
  border-radius: 4rem;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
  transition: all 200ms var(--ease-quart-in-out);

  &:hover {
    color: var(--color-white);
    background-color: var(--color-neon-green);
    border-color: var(--color-neon-green);
  }

  span {
    --margin-right: var(--space-xs);
    margin-right: ${props => props.withArrow && `var(--margin-right)`};

    @media (${bp.min.sm}) {
      --margin-right: var(--space-m);
    }
  }
`;
