import React from 'react';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Image = ({ image, aspectRatio, borderRadius = 0, loading = 'lazy' }) => {
  const imageData = getImage(image);

  return (
    <ImageWrapper
      aspectRatio={aspectRatio}
      borderRadius={borderRadius}
      className="image-wrapper"
    >
      {imageData ? (
        <GatsbyImage
          className="image"
          image={imageData}
          alt={image.alt ? image.alt : ' '}
          loading={loading}
        />
      ) : (
        <StaticImage
          src="../assets/images/image-fallback.jpg"
          alt="Placeholder"
          className="image"
          loading={loading}
        />
      )}
    </ImageWrapper>
  );
};

export default Image;

/**
 * Styled Components
 *
 */
const ImageWrapper = styled.div`
  width: 100%;

  .image {
    overflow: hidden;
    max-width: 100%;
    width: 100%;

    img {
      /* Convert whole number to decimal for rem units (ex: 5 -> 0.5) */
      border-radius: ${props => props.borderRadius / 10}rem;
      width: 100%;
      object-fit: cover;
    }

    /* If aspect ratio is set, apply supported aspect ratio method */
    ${props =>
      props.aspectRatio &&
      `
      --aspect-ratio: ${props.aspectRatio};

      @supports not (aspect-ratio: 1/1) {
        height: 0;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
      }

      @supports (aspect-ratio: 1/1) {
        aspect-ratio: var(--aspect-ratio);
      }
    `}
  }
`;
