import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import Link from './Link';
import Heading from './Heading';

const VisionMap = ({
  content: {
    vision_map_title,
    vision_map_link_text,
    vision_map_link_url,
    vision_map_description,
  },
  lang,
}) => {
  const [isPhaseOne, setIsPhaseOne] = useState(true);

  return (
    <MapWrapper>
      <MapBackground>
        <StaticImage
          src={'../assets/images/vision-map-bkg.jpg'}
          quality={100}
          alt=""
        />

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 1296">
          <a href="#the-tunnel" onMouseEnter={() => setIsPhaseOne(false)}>
            <path d="M715.93,681.9c-3.07-1.38-6.51-3.17-9.24-4.52-30.27-15-46.4-30.31-46.55-30.46L271.46,328.33C244,305.79,230,283.52,210.18,249.52l-23.29-39.27H169.24l28.21,46.68c20,34.4,35.32,58.75,64.67,82.8l388,318c2.72,2.57,23.46,21.33,60,37.85Z" />
            <text x="300" y="475">
              {createTranslation('The Tunnel', lang)}
            </text>
          </a>

          <a href="#the-cut" onMouseEnter={() => setIsPhaseOne(false)}>
            <path d="M1131.44,717c-13.47.34-39.42.94-56,1.38-76.76,2-156.12,4.11-234.24,4.11-53.16,0-96.61-12.07-128-25.58L719,683.18c28.27,12.17,70.3,24.57,122.2,24.57,77.92,0,157.19-2.09,233.85-4.1,18.6-.49,37.79-1,56.38-1.43Z" />
            <text x="850" y="690">
              {createTranslation('The Cut', lang)}
            </text>
          </a>

          <a className={isPhaseOne ? 'phase-one' : ''}>
            <path d="M1276.64,753l15.27.06c-2.58-7.75-14.51-37.84-46.79-47.08-20.1-5.76-80.35-4.76-105.36-4v14.23c29.58-.89,84.68-1.34,101.45,3.46,21.18,6.06,31.54,24.4,35.52,33.6Z" />
            <text x="1080" y="690">
              {createTranslation('Phase One', lang)}
            </text>
          </a>

          <a href="#the-viaduct" onMouseEnter={() => setIsPhaseOne(false)}>
            <path d="M1431.15,548.14l-.8-65.93-14.75-2.82.79,69.1v.38c0,.86,4.77,86.27-55.82,132.83a164,164,0,0,0-53.4,71.44l-30.54-.11c7.94,19.75,14.12,49,14.12,70.92h18.67c-.41-9.93-1.16-83.45,60.14-130.54C1435.44,642.8,1431.5,554.19,1431.15,548.14Z" />
            <text x="1240" y="640">
              {createTranslation('The Viaduct', lang)}
            </text>
          </a>
        </svg>
      </MapBackground>

      <MapOverview>
        <Row>
          <Col base={10} xs={8} sm={5} lg={4} lgOffset={1}>
            <Heading level="h2" size="m" content={vision_map_title} />
            <Link url={vision_map_link_url}>{vision_map_link_text}</Link>
          </Col>

          <Col base={12} sm={7} lg={5} lgOffset={1}>
            <RichText render={vision_map_description.richText} />
          </Col>
        </Row>
      </MapOverview>
    </MapWrapper>
  );
};

export default VisionMap;

/**
 * Styled Components
 *
 */
const MapWrapper = styled.section`
  position: relative;
  margin-top: -4.8rem;
`;

const MapBackground = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  top: 0;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: -4.8rem;
  z-index: 0;

  @media (${bp.min.sm}) {
    margin-bottom: -15rem;
  }

  @media (${bp.min.lg}) {
    margin-bottom: -20rem;
  }

  &:before,
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
  }

  &:before {
    height: 20%;
    top: 0;
    background-image: linear-gradient(
      to bottom,
      var(--color-off-white),
      rgba(250, 249, 247, 0)
    );
  }

  &:after {
    height: 50%;
    bottom: 0;
    background-image: linear-gradient(
      to top,
      var(--color-off-white),
      rgba(250, 249, 247, 0)
    );
  }

  img {
    max-width: 100%;
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;

    path {
      fill: var(--color-neon-green);
      transition: all 200ms var(--ease-cubic-in-out);
      stroke-width: 4rem;
      stroke: transparent;
    }

    @media (${bp.min.sm_md}) {
      .phase-one path,
      path:hover {
        fill: var(--color-neon-green-light);

        & ~ text,
        & > text {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    text {
      font-size: clamp(4rem, 3.1471rem + 1.1029vw, 6rem);
      pointer-events: none;
      transition: all 400ms var(--ease-quart-in-out);

      @media (${bp.min.sm_md}) {
        opacity: 0;
        transform: translateY(1.6rem);
      }
    }
  }
`;

const MapOverview = styled.div`
  position: relative;
  z-index: 5;

  .heading-tag {
    margin-bottom: var(--space-s);

    @media (${bp.min.sm}) {
      margin-bottom: var(--space-xxl);
    }
  }

  .link-tag {
    &:hover {
      color: var(--color-neon-green);

      &:after {
        transform: translate(0.2rem, -0.2rem);
      }
    }

    &:after {
      content: '↗';
      display: inline-block;
      margin-left: var(--space-xxxs);
      transition: transform 200ms var(--ease-quart-in-out);
    }
  }

  p {
    font-size: 1.7rem;
    line-height: 1.6;

    @media (${bp.min.sm_md}) {
      font-size: 2rem;
    }

    &:first-of-type {
      margin-top: var(--space-l);

      @media (${bp.min.sm}) {
        margin-top: var(--space-xxl);
      }
    }

    & + p {
      margin-top: var(--space-m);
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment VisionMap on PrismicVision {
    data {
      vision_map_title
      vision_map_description {
        richText
      }
      vision_map_link_text
      vision_map_link_url {
        link_type
        url
      }
    }
  }
`;
