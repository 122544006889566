import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { OverlayGrid, Grid } from './grid-system';
import bp from '../assets/js/breakpoints';
import { isBrowser, prefersReducedMotion } from '../assets/js/utilities';

import Main from './Main';
import Header from './Header';
import Footer from './Footer';
import NewsletterSignup from './NewsletterSignup';

import '../assets/css/fonts.css';

// Setup initial grid settings
const theme = {
  enableGridOverlay: true,
  flexboxgrid: {
    columns: 12,
    maxWidth: 192,
    gutterWidth: {
      mobile: 0.8,
      desktop: 2.4,
    },
    outerMargin: {
      mobile: 2.4,
      desktop: 4.8,
    },
    breakpoints: {
      base: 0,
      xs: bp.breakpoints.xs,
      xs_sm: bp.breakpoints.xs_sm,
      sm: bp.breakpoints.sm,
      sm_md: bp.breakpoints.sm_md,
      md: bp.breakpoints.md,
      lg: bp.breakpoints.lg,
      xl: bp.breakpoints.xl,
    },
  },
};

const Layout = ({
  children,
  activeDoc,
  layoutData,
  variant,
  hideOverflowX,
}) => {
  useEffect(() => {
    function playPauseVideo() {
      let videos = document.querySelectorAll('video');

      videos.forEach(video => {
        // We can only control playback without interaction if video is mute
        video.muted = true;

        // Play is a promise so we need to check we have it
        let playPromise = video.play();

        if (playPromise !== undefined) {
          playPromise.then(_ => {
            let observer = new IntersectionObserver(
              entries => {
                entries.forEach(entry => {
                  if (!entry.isIntersecting && !video.paused) {
                    video.pause();
                  } else if (
                    video.paused &&
                    isBrowser &&
                    !prefersReducedMotion
                  ) {
                    video.play();
                  }
                });
              },
              { threshold: 0.2 }
            );

            observer.observe(video);
          });
        }
      });
    }

    // And you would kick this off where appropriate with:
    playPauseVideo();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle variant={variant} hideOverflowX={hideOverflowX} />

      <OverlayGrid theme={theme} enableGridOverlay={theme.enableGridOverlay} />

      <a href="#main" className="skip-to-main">
        Skip to main content
      </a>

      <Grid fluid>
        <Header
          activeDoc={activeDoc}
          layoutData={layoutData}
          variant={variant}
        />

        <Main variant={variant}>{children}</Main>

        <NewsletterSignup activeDoc={activeDoc} layoutData={layoutData} />
        <Footer activeDoc={activeDoc} layoutData={layoutData} />
      </Grid>
    </ThemeProvider>
  );
};
export default Layout;

/**
 * Styled Components
 *
 */
const GlobalStyle = createGlobalStyle`
  ${reset}

  :root {
    font-size: 62.5%;

    --color-rail-park-green: #00B451;
    --color-neon-green: #04C75C;
    --color-neon-green-light: #0CEE72;
    --color-off-white: #FAF9F7;
    --color-white: #FFFFFF;
    --color-black: #131313;

    --font-family-sans: 'Armin Grotesk', 'Helvetica Neue', Helvetica, sans-serif;
    --font-family-condensed: 'Mongoose', Impact, Helvetica, sans-serif;
    --font-weight-normal: 400;
    --font-weight-semi-bold: 700;

    --font-size: 1.7rem;

    --width-max: 192rem;

    --spacing: .8rem;
    --space-xxxs: calc(var(--spacing) * 0.5);
    --space-xxs: calc(var(--spacing) * 1);
    --space-xs: calc(var(--spacing) * 1.5);
    --space-s: calc(var(--spacing) * 2);
    --space-m: calc(var(--spacing) * 3);
    --space-l: calc(var(--spacing) * 4);
    --space-xl: calc(var(--spacing) * 5);
    --space-xxl: calc(var(--spacing) * 9);
    --space-xxxl: calc(var(--spacing) * 18);

    --ease-cubic-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
    --ease-quart-in-out: cubic-bezier(0.77, 0, 0.18, 1);
    --ease-bounce-in-out: cubic-bezier(0.68, -0.4, 0.27, 1.4);

    --header-height-mobile: 8.8rem;
    --header-height-desktop: 12rem;
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    width: 100%;
    margin: 0;
  }

  #___gatsby {
    ${props =>
      props.hideOverflowX &&
      `
      overflow-x: hidden;
      `};
  }

  body {
    background-color: ${props =>
      props.variant === 'inverted'
        ? 'var(--color-black)'
        : 'var(--color-off-white)'};
    font-family: var(--font-family-sans);
    color: ${props =>
      props.variant === 'inverted'
        ? 'var(--color-white)'
        : 'var(--color-black)'};
    font-size: var(--font-size);
    line-height: 2.8rem;
    letter-spacing: .02rem;
    text-align: left;
    font-variant-numeric: lining-nums;
    font-feature-settings: 'lnum';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 300ms var(--ease-cubic-in-out),
      color 300ms var(--ease-cubic-in-out);

    ${props =>
      props.hideOverflowX &&
      `
      overflow-x: hidden;
    `};


    @media (${bp.min.sm}) {
      --font-size: 2.0rem;
      line-height: 3.2rem;
    }
  }

  ::selection {
    background-color: var(--color-neon-green-light);
    color: var(--color-black);
  }

  a {
    color: var(--color-black);
    transition: all 100ms var(--ease-cubic-in-out);
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    @media (hover: hover) {
      &:hover {
        color: var(--color-neon-green);
      }
    }
  }

  strong {
    font-weight: var(--font-weight-semi-bold);
  }

  em {
    font-style: italic;
  }

  button {
    appearance: none;
    border: none;
    background: transparent;
    padding: 0;
  }

  button,
  input {
    font-family: var(--font-family-sans);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: normal;
  }

  .skip-to-main {
    position: absolute;
    top: 0;
    left: -9999rem;
    z-index: 1000;
    transform: translateY(-100%);
    padding: var(--space-xs) var(--space-m);
    background-color: var(--color-neon-green);
    color: var(--color-black);
    transition: transform 500ms var(--ease-cubic-in-out);

    &:focus {
      left: 0;
      transform: translateY(0);
      outline-color: var(--color-neon-green);
    }
  }

  .sr-only {
    position: absolute;
    left: -1000rem;
    top: auto;
    width: .1rem;
    height: .1rem;
    overflow: hidden;
  }

  @keyframes fadeDownOut {
    to {
      transform: translateY(.8rem);
      opacity: 0;
    }
  }

  @keyframes fadeUpIn {
    from {
      transform: translateY(.8rem);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment SiteMeta on PrismicLayout {
    lang
    type
    data {
      site_title
      site_description
      site_image {
        url
      }
    }
  }
  fragment Menus on PrismicLayout {
    lang
    type
    data {
      menu_desktop_header {
        link_text
        link_url {
          url
          target
          link_type
        }
      }
      menu_desktop_main {
        link_text
        link_url {
          url
          target
          link_type
        }
        anchor_link
        anchor_link_hash
      }
      menu_desktop_visit {
        link_text
        link_url {
          url
          target
          link_type
        }
        anchor_link
        anchor_link_hash
      }
      menu_desktop_vision {
        link_text
        link_url {
          url
          target
          link_type
        }
        anchor_link
        anchor_link_hash
      }
      menu_mobile_main {
        link_text
        link_url {
          url
          target
          link_type
        }
      }
      menu_mobile_secondary {
        link_text
        link_url {
          url
          target
          link_type
        }
        anchor_link
        anchor_link_hash
      }
      menu_social {
        link_text
        link_url {
          url
          target
          link_type
        }
      }
      newsletter_text
      newsletter_placeholder
    }
  }
  fragment ParkInfo on PrismicLayout {
    lang
    type
    data {
      hours_and_info_title
      hours_and_info {
        richText
      }
      entrance_title
      entrance {
        richText
      }
      contact_title
      contact {
        richText
      }
      address_title
      address {
        richText
      }
      copyright
      charitable_disclaimer {
        richText
      }
    }
  }
`;
