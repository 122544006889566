import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import Image from './Image';
import Link from './Link';
import Heading from './Heading';
import Button from './Button';

const Callout = ({ content, index, limit, isDarkMode, lang }) => {
  const { data, type, url } = content.node;
  const { thumbnail, title, start } = data;
  const formattedDate = new Date(start).toLocaleDateString(lang, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const isFirst = index === 0;
  const isLast = index === limit - 1;

  return (
    <Col
      base={isLast ? false : 6}
      xs={isLast ? false : 5}
      xl={2}
      xsOffset={isFirst ? 1 : 0}
      as="li"
    >
      <Link url={{ url: url, link_type: 'Document' }}>
        <CalloutWrapper isDarkMode={isDarkMode}>
          {thumbnail && (
            <ImageContainer>
              <Image image={thumbnail} />
            </ImageContainer>
          )}

          <EventDate>{formattedDate}</EventDate>

          <Heading level="h3" size="s" content={title} />

          <p className="link-text">
            {type === 'event'
              ? createTranslation('Details', lang)
              : createTranslation('Read more', lang)}
          </p>
        </CalloutWrapper>
      </Link>
    </Col>
  );
};

const NewsEventsCallout = ({ events, limit = 5, isDarkMode, lang }) => {
  // Get today's date
  const today = new Date().toISOString().slice(0, 10);

  // Filter and sort events to just upcoming events after today
  const feed = events.edges
    .filter(item => {
      const eventDate = item.node.data.start;
      const eventDateTruncated = eventDate ? eventDate.slice(0, 10) : eventDate;

      return eventDateTruncated > today;
    })
    .sort((a, b) => new Date(a.node.data.start) - new Date(b.node.data.start))
    .slice(0, limit);

  return (
    <>
      {feed.length > 0 && (
        <NewsEventsWrapper>
          <Row as="ul" end="xs">
            <Col base={12} xs={1} as="li" className="news-events-title">
              <VerticalHeading>
                <Heading
                  level="h2"
                  size="m"
                  content={createTranslation('Upcoming Events', lang)}
                />
              </VerticalHeading>
            </Col>

            {feed.map((item, i) => (
              <Callout
                key={item.node.uid}
                content={item}
                index={i}
                limit={limit}
                isDarkMode={isDarkMode}
                lang={lang}
              />
            ))}

            <Col base={12} xsOrder={-1} as="li">
              <ButtonContainer isDarkMode={isDarkMode}>
                <Button
                  link={{ url: '/news-and-events', link_type: 'Document' }}
                  withArrow
                >
                  {createTranslation('Full Event Calendar', lang)}
                </Button>
              </ButtonContainer>
            </Col>
          </Row>
        </NewsEventsWrapper>
      )}
    </>
  );
};

export default NewsEventsCallout;

/**
 * Styled Components
 *
 */
const NewsEventsWrapper = styled.section`
  margin: var(--space-xl) 0;

  .news-events-title {
    margin-right: auto;
  }
`;

const VerticalHeading = styled.div`
  --margin-bottom: var(--space-m);

  margin-bottom: var(--margin-bottom);

  @media (${bp.min.xs}) {
    --margin-bottom: 0;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }

  @media (${bp.min.sm}) {
    margin-left: auto;
  }
`;

const ImageContainer = styled.div`
  transition: all 200ms var(--ease-cubic-in-out);
`;

const EventDate = styled.p`
  --margin-top: var(--space-xs);
  --margin-bottom: var(--space-xxxs);

  font-size: 1.3rem;
  line-height: 1.3;
  margin-top: var(--margin-top);
  margin-bottom: var(--margin-bottom);
  color: var(--color-black);

  @media (${bp.min.sm}) {
    --margin-top: var(--space-m);
    --margin-bottom: var(--space-xxxs);
  }
`;

const CalloutWrapper = styled.div`
  --margin-bottom: var(--space-s);
  margin-bottom: var(--margin-bottom);

  @media (${bp.min.sm}) {
    --margin-bottom: var(--space-m);
  }

  @media (hover: hover) {
    &:hover {
      .heading-tag {
        color: ${props =>
          props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
      }

      .link-text {
        color: var(--color-neon-green);

        &:after {
          transform: translate(0.2rem, -0.2rem);
        }
      }

      ${ImageContainer} {
        transform: translateY(-1.2rem);
        opacity: 0.9;
      }
    }
  }

  .heading-tag {
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
  }

  .link-text {
    --margin-top: var(--space-xxxs);
    font-size: 1.5rem;
    margin-top: var(--margin-top);
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
    transition: color 200ms var(--ease-quart-in-out);

    @media (${bp.min.sm}) {
      --margin-top: var(--space-xxs);
    }

    &:after {
      content: '↗';
      display: inline-block;
      margin-left: var(--space-xxxs);
      transition: transform 200ms var(--ease-quart-in-out);
    }
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: var(--space-m);

  .button-wrapper {
    ${props =>
      props.isDarkMode &&
      `
      color: var(--color-white);
      border-color: var(--color-white);

      &:hover {
        color: var(--color-black);
        border-color: var(--color-neon-green);
      }
    `}
  }

  @media (${bp.min.xs}) {
    text-align: right;
    margin-bottom: var(--space-m);
  }
`;

/**
 * GraphQL Query
 *
 */
export const query = graphql`
  fragment NewsItems on PrismicNews {
    first_publication_date
    uid
    url
    type
    data {
      title
      thumbnail {
        gatsbyImageData(width: 550)
        alt
      }
    }
  }
  fragment EventItems on PrismicEvent {
    first_publication_date
    uid
    url
    type
    data {
      title
      thumbnail {
        gatsbyImageData(width: 550)
        alt
      }
      start
    }
  }
`;
