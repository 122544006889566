import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard } from 'swiper';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';

import Image from '../components/Image';
import Link from '../components/Link';
import Heading from '../components/Heading';
import SwipeIndicator from './SwipeIndicator';

import 'swiper/css';

SwiperCore.use([Keyboard]);

const Callout = ({ content, index, isDarkMode }) => {
  const { image, link_title, link_text, url } = content;

  const isFirstInRow = index % 5 === 0;

  return (
    <Col base={12} sm_md={2} sm_mdOffset={isFirstInRow ? 1 : 0}>
      <Link url={url}>
        <CalloutWrapper isDarkMode={isDarkMode}>
          <ImageContainer>
            <Image image={image} />
          </ImageContainer>

          <Heading level="h3" size="s" content={link_title} />

          <p className="link-text">{link_text}</p>
        </CalloutWrapper>
      </Link>
    </Col>
  );
};

const LinkCallout = ({
  content: { callout_title, link_item },
  isDarkMode,
  lang,
}) => {
  return (
    <LinkCalloutWrapper isDarkMode={isDarkMode}>
      <Row center="xs_sm">
        <Col base={11} xs={9} xs_sm={7} sm={6} md={5} lg={4}>
          <Heading level="h2" size="m" content={callout_title} />
        </Col>
      </Row>

      <Row className="link-columns">
        {link_item.map((item, i) => (
          <Callout
            content={item}
            index={i}
            key={item.link_title}
            isDarkMode={isDarkMode}
          />
        ))}
      </Row>

      <Swiper
        className="link-carousel"
        spaceBetween={16}
        slidesPerView={2}
        centeredSlides={true}
        keyboard={{
          enabled: true,
        }}
        loop={true}
        lazy={{
          loadPrevNext: true,
          loadPrevNextAmount: link_item.length,
        }}
      >
        {link_item.map((item, i) => (
          <SwiperSlide key={item.link_title}>
            <Callout content={item} index={i} isDarkMode={isDarkMode} />
          </SwiperSlide>
        ))}
      </Swiper>

      <SwipeIndicator isDarkMode={isDarkMode} lang={lang} />
    </LinkCalloutWrapper>
  );
};

export default LinkCallout;

/**
 * Styled Components
 *
 */
const LinkCalloutWrapper = styled.section`
  margin: var(--space-xl) 0 var(--space-xl);

  @media (${bp.min.sm_md}) {
    background-color: ${props =>
      props.isDarkMode ? '#252525' : 'var(--color-white)'};
    border: 0.1rem solid ${props => (props.isDarkMode ? '#383838' : '#e1dfdf')};
    padding: 4.8rem 0 5.6rem;
    border-radius: 1rem;
    margin: var(--space-xl) 0 var(--space-xxl);
  }

  @media (${bp.min.md}) {
    margin: var(--space-xxxl) 0 var(--space-xxl);
    padding: 6.4rem 0 0;
  }

  h2 {
    margin-bottom: var(--space-m);

    @media (${bp.min.xs_sm}) {
      text-align: center;
      margin-bottom: var(--space-xl);
    }

    @media (${bp.min.md}) {
      margin-bottom: var(--space-xxl);
    }
  }

  .link-columns {
    display: none;

    @media (${bp.min.sm_md}) {
      display: flex;
    }

    ${Col} {
      margin-bottom: 5.6rem;
    }
  }

  .link-carousel {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    @media (${bp.min.sm_md}) {
      display: none;
    }

    &:focus {
      outline: 0;
    }
  }
`;

const ImageContainer = styled.div`
  transition: all 200ms var(--ease-cubic-in-out);
`;

const CalloutWrapper = styled.div`
  --margin-bottom: var(--space-s);
  margin-bottom: var(--margin-bottom);

  @media (${bp.min.sm}) {
    --margin-bottom: 0;
  }

  @media (hover: hover) {
    &:hover {
      .heading-tag {
        color: ${props =>
          props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
      }

      .link-text {
        color: var(--color-neon-green);

        &:after {
          transform: translate(0.2rem, -0.2rem);
        }
      }

      ${ImageContainer} {
        transform: translateY(-1.2rem);
        opacity: 0.9;
      }
    }
  }

  h3 {
    --margin-top: var(--space-xxs);
    margin-top: var(--margin-top);
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};

    @media (${bp.min.lg}) {
      --margin-top: var(--space-s);
    }
  }

  .link-text {
    --margin-top: var(--space-xxxs);
    font-size: 1.5rem;
    margin-top: var(--margin-top);
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
    transition: color 200ms var(--ease-cubic-in-out);

    @media (${bp.min.sm}) {
      --margin-top: var(--space-xxs);
    }

    &:after {
      content: '↗';
      display: inline-block;
      margin-left: var(--space-xxxs);
      transition: transform 200ms var(--ease-quart-in-out);
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment LinkCallout on PrismicVisit {
    data {
      callout_title
      link_item {
        image {
          gatsbyImageData(width: 550)
          alt
        }
        link_title
        link_text
        url {
          link_type
          url
        }
      }
    }
  }
`;
