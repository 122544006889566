import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';

import ArrowIcon from './ArrowIcon';
import Heading from './Heading';
import ImageShuffle from './ImageShuffle';

const VisitTravel = ({
  content: { getting_here_title, travel_mode },
  isDarkMode,
}) => {
  const [imageToShow, setImageToShow] = useState(0);
  const [isSameImage, setIsSameImage] = useState(false);

  return (
    <TravelCallout>
      <Row middle="xs">
        <Col base={12} sm_md={false}>
          <Heading level="h2" size="l" content={getting_here_title} />
        </Col>

        <Col base={false} sm_md={5} lg={4} lgOffset={1}>
          <ImageShuffle
            imageArray={travel_mode}
            imageToShow={imageToShow}
            isSameImage={isSameImage}
          />
        </Col>

        <Col base={12} sm_md={6} lg={5} sm_mdOffset={1} as="ul">
          {travel_mode.map((mode, i) => (
            <TravelMode
              key={mode.name}
              onMouseEnter={() => {
                setImageToShow(i);
                setIsSameImage(i === imageToShow);
              }}
              onFocus={() => {
                setImageToShow(i);
                setIsSameImage(i === imageToShow);
              }}
              tabIndex="0"
              aria-label={mode.name}
              isDarkMode={isDarkMode}
              isActive={i === imageToShow}
            >
              <Heading level="h3" size="s" content={mode.name} />
              <ArrowIcon rotation={90} width={20} />
              <div>
                <RichText render={mode.description.richText} />
                {mode.note && <p className="note">{mode.note}</p>}
              </div>
            </TravelMode>
          ))}
        </Col>
      </Row>
    </TravelCallout>
  );
};

export default VisitTravel;

/**
 * Styled Components
 *
 */
const TravelCallout = styled.section`
  margin: var(--space-xl) 0;

  @media (${bp.min.sm}) {
    margin: var(--space-xxxl) 0;
  }

  h2 {
    margin-bottom: var(--space-m);
  }
`;

const TravelMode = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
  border-bottom: 0.1rem solid
    ${props => (props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)')};
  padding: var(--space-s) 0 var(--space-m);
  cursor: default;

  @media (${bp.min.xs_sm}) {
    padding: var(--space-l) 0;
    grid-template-columns: 1.5fr 1fr 2.5fr;
  }

  @media (hover: hover) {
    @media (${bp.min.sm_md}) {
      &:hover {
        p {
          color: var(--color-neon-green);
        }

        .arrow-icon {
          stroke: var(--color-neon-green);
          transform: translateX(0.8rem);
        }
      }
    }
  }

  ${props =>
    props.isActive &&
    `
    @media (${bp.min.sm_md}) {

      p {
        color: var(--color-neon-green);
      }

      .arrow-icon {
        stroke: var(--color-neon-green) !important;
        transform: translateX(0.8rem);
      }
    }
  `}

  &:focus {
    outline: 0;

    @media (${bp.min.sm_md}) {
      outline: initial;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  .heading-tag {
    @media (${bp.max.xs_sm}) {
      font-size: 3.5rem;
    }
  }

  p,
  .arrow-icon {
    transition: all 300ms var(--ease-bounce-in-out);
  }

  .arrow-icon {
    stroke: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
    display: none;
    position: relative;
    top: var(--space-xs);

    @media (${bp.min.xs_sm}) {
      display: block;
    }
  }

  p {
    font-size: clamp(2rem, 1.6471rem + 1.1029vw, 3.5rem);
    line-height: 1.3;
    letter-spacing: -0.05rem;
  }

  .note {
    font-size: 1.5rem;
    margin-top: var(--space-s);

    @media (${bp.min.xs_sm}) {
      font-size: 2rem;
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment VisitTravel on PrismicVisit {
    data {
      getting_here_title
      travel_mode {
        name
        description {
          richText
        }
        note
        image {
          alt
          gatsbyImageData(width: 600)
        }
      }
    }
  }
`;
