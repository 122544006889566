exports.components = {
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-basic-js": () => import("./../../../src/templates/Basic.js" /* webpackChunkName: "component---src-templates-basic-js" */),
  "component---src-templates-donate-js": () => import("./../../../src/templates/Donate.js" /* webpackChunkName: "component---src-templates-donate-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/Event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-faqs-js": () => import("./../../../src/templates/Faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-history-js": () => import("./../../../src/templates/History.js" /* webpackChunkName: "component---src-templates-history-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-news-and-events-js": () => import("./../../../src/templates/NewsAndEvents.js" /* webpackChunkName: "component---src-templates-news-and-events-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/News.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/Resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/Search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-vision-js": () => import("./../../../src/templates/Vision.js" /* webpackChunkName: "component---src-templates-vision-js" */),
  "component---src-templates-visit-js": () => import("./../../../src/templates/Visit.js" /* webpackChunkName: "component---src-templates-visit-js" */)
}

