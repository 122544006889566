import React, { useState, useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useWindowScroll } from 'react-use';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';
import {
  getActiveDocLanguage,
  toKebabCase,
  getSiteMeta,
  createTranslation,
} from '../assets/js/utilities';
import linkResolver from '../utilities/link-resolver';

import AnimateFadeIn from '../components/AnimateFadeIn';
import Seo from '../components/Seo';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import ArrowIcon from '../components/ArrowIcon';

gsap.registerPlugin(ScrollToPlugin);

const Faqs = ({ data }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const input = useRef(null);
  const { y } = useWindowScroll();

  useEffect(() => {
    if (y > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  }, [y]);

  // Get global data
  const layout = data.prismicLayout;

  // Get page data
  const faqs = data.prismicFaqs;
  const {
    title,
    hero_image,
    visiting_title,
    visiting_faqs,
    events_title,
    events_faqs,
    supporting_title,
    supporting_faqs,
    accessibility_title,
    accessibility_faqs,
    tours_title,
    tours_faqs,
    contact_title,
    contact_description,
    contact_email,
    contact_phone_number,
    meta_description,
    meta_image,
  } = faqs.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(faqs);
  const defaultMeta = getSiteMeta(layout.data);

  // Break FAQs into sections with title and color
  const faqSections = [
    {
      title: visiting_title,
      faqs: visiting_faqs,
      color: '#08c65b',
    },
    {
      title: events_title,
      faqs: events_faqs,
      color: '#f37a36',
    },
    {
      title: supporting_title,
      faqs: supporting_faqs,
      color: '#eb147d',
    },
    {
      title: accessibility_title,
      faqs: accessibility_faqs,
      color: '#b2752b',
    },
    {
      title: tours_title,
      faqs: tours_faqs,
      color: '#57b2c3',
    },
  ];

  // Combine all FAQs into one large array for searching through
  const allFaqs = [
    ...visiting_faqs,
    ...events_faqs,
    ...supporting_faqs,
    ...accessibility_faqs,
    ...tours_faqs,
  ];

  // Fuzzy search through FAQs when text is input into search field
  function handleInputChange(e) {
    const value = e.target.value;
    let filteredFaqs = [];

    if (value !== '') {
      setIsSearching(true);

      // Filter through list of concepts as you type,
      //  returning a new array of matching items
      filteredFaqs = allFaqs.filter(item => {
        const question = item.question.text.toLowerCase();
        const answer = item.answer.text.toLowerCase();
        const filter = value.toLowerCase();

        return question.includes(filter)
          ? question.includes(filter)
          : answer.includes(filter);
      });
    } else {
      setIsSearching(false);
    }

    // On input change, add the filtered list to state
    if (filteredFaqs.length > 0) {
      setFilteredList(filteredFaqs);
    } else {
      setFilteredList('no results');
    }
  }

  function scrollToTop() {
    gsap.to(window, {
      duration: 0.7,
      scrollTo: 0,
    });
  }

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data} variant="fullscreen">
      <Seo
        title={title}
        description={meta_description}
        image={meta_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
      />
      <AnimateFadeIn>
        <Hero>
          <Image image={hero_image} />
          <Heading level="h1" size="xl" content={title} />
        </Hero>

        <Row>
          <Col base={12} sm_md={7} lg={6} xlOffset={1}>
            <SearchWrapper>
              <input
                type="text"
                placeholder={createTranslation('Search', activeDoc.locale)}
                ref={input}
                onChange={handleInputChange}
                autoComplete="off"
              />
              <svg
                width="28"
                height="29"
                viewBox="0 0 28 29"
                fill="none"
                stroke="#131313"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="11.5133" cy="11.5133" r="10.5133" />
                <line x1="18.6956" y1="19.2251" x2="27.3536" y2="27.883" />
              </svg>
            </SearchWrapper>

            <AnchorLinkWrapper>
              <p>
                {isSearching && filteredList !== 'no results'
                  ? `${filteredList.length} ${createTranslation(
                      'result',
                      activeDoc.locale
                    )}${
                      filteredList.length > 1 && activeDoc.locale !== 'zh-cn'
                        ? 's'
                        : ''
                    }:`
                  : isSearching && filteredList === 'no results'
                  ? createTranslation('No Results', activeDoc.locale)
                  : `${createTranslation('Jump to', activeDoc.locale)}:`}
              </p>

              {!isSearching && (
                <ul>
                  {faqSections.map(section => (
                    <li key={section.color}>
                      <AnchorLink
                        href={`#${toKebabCase(section.title)}`}
                        sectionColor={section.color}
                      >
                        {section.title.replace('Rail', '')}
                      </AnchorLink>
                    </li>
                  ))}
                </ul>
              )}
            </AnchorLinkWrapper>

            <FAQs>
              {isSearching ? (
                <ul>
                  {!!filteredList &&
                    filteredList !== 'no results' &&
                    filteredList.map(faq => (
                      <li key={faq.question.html}>
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: faq.question.html,
                          }}
                        />
                        <RichText render={faq.answer.richText} />
                      </li>
                    ))}
                </ul>
              ) : (
                <>
                  {faqSections.map(section => (
                    <section
                      key={section.title}
                      className="faq-section"
                      id={toKebabCase(section.title)}
                    >
                      <header>
                        <a
                          href={`#${toKebabCase(section.title)}`}
                          className="faq-section__anchor"
                        >
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="#B4B4B4"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M18.4054 15.9779C18.1073 15.9779 17.8093 15.8661 17.5857 15.6425C17.1386 15.2327 17.1386 14.5248 17.5485 14.0777L17.5857 14.0405L21.6096 10.0166C23.1744 8.45178 23.1744 5.95551 21.6096 4.39068C20.0447 2.82585 17.5485 2.82585 15.9836 4.39068L11.9598 8.41452C10.3949 9.97935 10.3949 12.4756 11.9598 14.0405C12.4069 14.4875 12.4441 15.1954 11.997 15.6425C11.5499 16.0896 10.842 16.1269 10.3949 15.6798C10.3577 15.6798 10.3577 15.6425 10.3577 15.6425C7.89867 13.1835 7.89867 9.2342 10.3577 6.77518L14.3815 2.75133C16.8778 0.329576 20.8271 0.404092 23.2489 2.90037C25.5961 5.32212 25.5961 9.19694 23.2489 11.6187L19.2251 15.6425C19.0015 15.8661 18.7034 15.9779 18.4054 15.9779Z" />
                            <path d="M6.25907 26C2.79409 26 -0.000244141 23.2057 -0.000244141 19.7407C-0.000244141 18.0641 0.670397 16.4993 1.82539 15.307L5.84923 11.2832C6.29633 10.8361 7.04148 10.8733 7.45132 11.3204C7.86116 11.7675 7.86116 12.4381 7.45132 12.8852L3.42748 16.9091C1.93716 18.5112 2.01168 21.0447 3.61376 22.5723C5.14134 24.0253 7.52584 24.0253 9.09066 22.5723L13.1145 18.5484C14.6793 16.9836 14.6793 14.4873 13.1145 12.9225C12.6674 12.5127 12.6674 11.8048 13.0773 11.3577L13.1145 11.3204C13.3381 11.0969 13.5989 10.9851 13.9342 10.9851C14.2322 10.9851 14.5303 11.0969 14.7539 11.3204C17.2129 13.7794 17.2129 17.7288 14.7539 20.1878L10.6928 24.1744C9.5005 25.3294 7.93567 26 6.25907 26Z" />
                          </svg>
                        </a>

                        <Heading level="h2" size="m" content={section.title} />
                      </header>

                      <ul>
                        {section.faqs.map(faq => (
                          <li key={faq.question.html}>
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: faq.question.html,
                              }}
                            />
                            <RichText render={faq.answer.richText} />
                          </li>
                        ))}
                      </ul>
                    </section>
                  ))}
                </>
              )}
            </FAQs>
          </Col>

          <Col base={12} sm_md={5} md={4} xl={3} mdOffset={1}>
            <ContactSidebar>
              <Heading level="h2" size="m" content={contact_title} />
              <p>{contact_description}</p>

              <dl>
                <dt>{createTranslation('Email us', activeDoc.locale)}:</dt>
                <dd>
                  <a href={`mailto:${contact_email}`}>{contact_email}</a>
                </dd>
                <dt>{createTranslation('Call us', activeDoc.locale)}:</dt>
                <dd>
                  <a href={`tel:${contact_phone_number}`}>
                    {contact_phone_number}
                  </a>
                </dd>
              </dl>

              <BackToTop
                onClick={e => scrollToTop(e, '0')}
                showBackToTop={showBackToTop}
              >
                <span>
                  {createTranslation('Back to top', activeDoc.locale)}
                </span>
                <ArrowIcon width={16} rotation={0} color="currentColor" />
              </BackToTop>
            </ContactSidebar>
          </Col>
        </Row>
      </AnimateFadeIn>
    </Layout>
  );
};

export default withPrismicPreview(Faqs, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const Hero = styled.section`
  position: relative;
  width: 100vw;
  height: 30vw;
  min-height: 24rem;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: var(--space-xl);

  @media (${bp.min.sm}) {
    margin-bottom: var(--space-xxl);
  }

  &:after {
    content: '';
    background-image: linear-gradient(
      to bottom,
      hsl(0, 0%, 0%) 0%,
      hsla(0, 0%, 0%, 0.94) 10.8%,
      hsla(0, 0%, 0%, 0.878) 19.9%,
      hsla(0, 0%, 0%, 0.812) 27.5%,
      hsla(0, 0%, 0%, 0.745) 33.9%,
      hsla(0, 0%, 0%, 0.676) 39.3%,
      hsla(0, 0%, 0%, 0.606) 44%,
      hsla(0, 0%, 0%, 0.535) 48.3%,
      hsla(0, 0%, 0%, 0.465) 52.5%,
      hsla(0, 0%, 0%, 0.394) 56.7%,
      hsla(0, 0%, 0%, 0.324) 61.4%,
      hsla(0, 0%, 0%, 0.255) 66.7%,
      hsla(0, 0%, 0%, 0.188) 73%,
      hsla(0, 0%, 0%, 0.122) 80.4%,
      hsla(0, 0%, 0%, 0.06) 89.4%,
      hsla(0, 0%, 0%, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    opacity: 0.6;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
    height: 12rem;
    z-index: 1;
  }

  h1,
  .image-wrapper {
    grid-area: 1 / 1;
  }

  .image-wrapper {
    width: 100vw;
  }

  .image-wrapper,
  .image {
    height: 100%;
  }

  h1 {
    text-align: center;
    color: var(--color-white);
    z-index: 1;
    margin-top: var(--space-s);

    @media (${bp.min.xs_sm}) {
      margin-top: var(--space-xxl);
    }
  }
`;

const SearchWrapper = styled.section`
  margin-bottom: var(--space-l);
  position: relative;

  input {
    --height: 4.8rem;

    width: 100%;
    height: var(--height);
    background-color: var(--color-white);
    border-radius: var(--height);
    border: 0.1rem solid rgba(19, 19, 19, 0.2);
    padding-left: 5.6rem;
    padding-right: var(--space-m);
    font-size: clamp(2rem, 1.7647rem + 0.7353vw, 3rem);
    transition: box-shadow 150ms var(--ease-cubic-in-out);
    appearance: none;

    @media (${bp.min.sm}) {
      --height: 7.2rem;
      padding-left: 8.8rem;
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(19, 19, 19, 0.2);

      & ~ svg {
        stroke: var(--color-neon-green);
      }

      &::placeholder {
        opacity: 1;
      }
    }

    &::placeholder {
      color: var(--color-black);
      opacity: 0.7;
    }
  }

  svg {
    position: absolute;
    left: var(--space-l);
    top: 50%;
    transform: translateY(-50%);
    transition: stroke 200ms var(--ease-cubic-in-out);

    @media (${bp.max.sm}) {
      width: 2rem;
      left: var(--space-s);
    }
  }
`;

const AnchorLinkWrapper = styled.div`
  margin-bottom: var(--space-s);

  @media (${bp.min.sm}) {
    margin-bottom: var(--space-xl);
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (${bp.min.xs_sm}) {
      width: 75%;
    }

    @media (${bp.min.sm_md}) {
      width: 100%;
    }

    @media (${bp.min.xl}) {
      width: 80%;
    }

    li {
      margin-top: var(--space-xs);
      margin-right: var(--space-xxs);

      @media (${bp.min.xs_sm}) {
        margin-top: var(--space-s);
        margin-right: var(--space-s);
      }
    }
  }
`;

const AnchorLink = styled.a`
  display: inline-flex;
  align-items: center;
  height: 4rem;
  padding: 0 var(--space-s);
  border: 0.1rem solid;
  border-color: ${props => props.sectionColor};
  border-radius: 6rem;

  @media (${bp.max.xs}) {
    font-size: 1.4rem;
  }

  @media (${bp.min.xs_sm}) {
    height: 4.8rem;
  }

  @media (${bp.min.sm}) {
    padding: 0 var(--space-m);
    height: 6rem;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.sectionColor};
      color: var(--color-white);
    }
  }
`;

const FAQs = styled.div`
  margin-bottom: 6rem;

  @media (${bp.min.sm_md}) {
    margin-bottom: 9.6rem;
  }

  .faq-section:not(:first-of-type) {
    margin-top: var(--space-xl);

    @media (${bp.min.sm}) {
      margin-top: 9.6rem;
    }
  }

  header {
    position: sticky;
    top: 0;
    background-color: var(--color-off-white);
    padding-top: var(--space-xxxs);

    &:hover {
      a {
        opacity: 1;
      }
    }
  }

  .faq-section__anchor {
    float: left;
    margin-left: -4.8rem;
    padding-top: var(--space-xl);
    position: relative;
    opacity: 0;
    display: none;

    @media (${bp.min.sm}) {
      display: block;
    }

    &:hover {
      svg {
        fill: var(--color-neon-green);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: -1.2rem;
      right: -1.2rem;
      bottom: -1.2rem;
      left: -1.2rem;
    }

    svg {
      transition: fill 200ms var(--ease-cubic-in-out);
    }
  }

  h2 {
    border-bottom: 0.1rem solid var(--color-black);
    padding-bottom: var(--space-s);
    margin-top: var(--space-m);
    margin-bottom: var(--space-m);

    @media (${bp.min.sm}) {
      padding-bottom: var(--space-m);
      margin-bottom: var(--space-l);
    }
  }

  h3 {
    margin-bottom: var(--space-m);
    font-size: clamp(2rem, 1.7647rem + 0.7353vw, 3rem);

    @media (${bp.min.sm}) {
      margin-bottom: var(--space-l);
    }
  }

  p {
    & + p {
      margin-top: var(--space-s);
    }

    a {
      color: var(--color-neon-green);

      &:hover {
        color: var(--color-neon-green-light);
      }
    }
  }

  li {
    & + li {
      border-top: 0.1rem solid var(--color-black);
      padding-top: var(--space-m);
      margin-top: var(--space-m);

      @media (${bp.min.sm}) {
        padding-top: var(--space-xl);
        margin-top: var(--space-l);
      }
    }
  }
`;

const ContactSidebar = styled.section`
  margin-bottom: 9.6rem;

  @media (${bp.min.sm_md}) {
    position: sticky;
    top: var(--space-l);
  }

  h2 {
    margin-bottom: var(--space-l);
  }

  dl {
    margin-top: var(--space-m);
  }

  dd + dt {
    margin-top: var(--space-m);
  }

  dd {
    margin-top: var(--space-xxxs);

    a {
      font-size: 3rem;
    }
  }
`;

const BackToTop = styled.button`
  cursor: pointer;
  font-family: var(--font-family-sans);
  font-size: 0;
  color: var(--color-black);
  transition: color 200ms var(--ease-cubic-in-out),
    opacity 200ms var(--ease-cubic-in-out);

  @media (${bp.max.sm_md}) {
    border-radius: 100%;
    width: 5.6rem;
    height: 5.6rem;
    background-color: rgba(255, 255, 255, 0.95);
    position: fixed;
    bottom: var(--space-l);
    right: var(--space-m);
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(0.2rem);
    z-index: 10;
    opacity: ${props => (props.showBackToTop ? 1 : 0)};
  }

  @media (${bp.min.sm_md}) {
    font-size: 2rem;
    margin-top: var(--space-xl);
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-neon-green);

      .arrow-icon {
        @media (${bp.min.sm_md}) {
          transform: translateY(-0.4rem);
        }
      }
    }
  }

  span {
    @media (${bp.max.sm_md}) {
      display: none;
    }
  }

  .arrow-icon {
    transition: transform 200ms var(--ease-cubic-in-out);

    @media (${bp.max.sm_md}) {
      width: 3.2rem;
    }

    @media (${bp.min.sm_md}) {
      position: relative;
      top: 0.2rem;
      margin-left: var(--space-xs);
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($language: String) {
    prismicFaqs(lang: { eq: $language }) {
      _previewable
      data {
        title
        hero_image {
          alt
          gatsbyImageData(width: 1400)
        }
        visiting_title
        visiting_faqs {
          question {
            html
            text
          }
          answer {
            richText
            text
          }
        }
        events_title
        events_faqs {
          question {
            html
            text
          }
          answer {
            richText
            text
          }
        }
        supporting_title
        supporting_faqs {
          question {
            html
            text
          }
          answer {
            richText
            text
          }
        }
        accessibility_title
        accessibility_faqs {
          question {
            html
            text
          }
          answer {
            richText
            text
          }
        }
        tours_title
        tours_faqs {
          question {
            html
            text
          }
          answer {
            richText
            text
          }
        }
        contact_title
        contact_description
        contact_email
        contact_phone_number
        meta_description
        meta_image {
          url
        }
      }
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
  }
`;
