import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import Headroom from 'headroom.js';

import bp from '../../assets/js/breakpoints';

import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import Menu from '../Menu';

const Header = ({ activeDoc, layoutData, variant }) => {
  const [menuIsOpen, toggleMenuIsOpen] = useToggle(false);
  const [isOnTop, toggleIsOnTop] = useToggle(null);
  const header = useRef(null);

  useEffect(() => {
    const options = {
      offset: 50,
      tolerance: {
        up: 10,
        down: 0,
      },
      onTop: function () {
        toggleIsOnTop(true);
      },
      onNotTop: function () {
        toggleIsOnTop(false);
      },
    };

    const headroom = new Headroom(header.current, options);
    headroom.init();

    return () => {
      headroom.destroy();
    };
  }, [toggleIsOnTop]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (menuIsOpen && e.code === 'Escape') {
        toggleMenuIsOpen(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [menuIsOpen, toggleMenuIsOpen]);

  const { site_title, menu_desktop_header } = layoutData;

  // Lock page to prevent from scrolling while menu is open
  useEffect(() => {
    if (menuIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [menuIsOpen]);

  return (
    <>
      <Menu
        content={layoutData}
        activeDoc={activeDoc}
        menuIsOpen={menuIsOpen}
        toggleMenuIsOpen={toggleMenuIsOpen}
      />

      <HeadroomWrapper ref={header} menuIsOpen={menuIsOpen} variant={variant}>
        <HeaderDesktop
          siteTitle={site_title}
          toggleMenuIsOpen={toggleMenuIsOpen}
          content={menu_desktop_header}
          menuIsOpen={menuIsOpen}
          activeDoc={activeDoc}
          variant={variant}
          isOnTop={isOnTop}
        />
        <HeaderMobile
          siteTitle={site_title}
          toggleMenuIsOpen={toggleMenuIsOpen}
          activeDoc={activeDoc}
          menuIsOpen={menuIsOpen}
          variant={variant}
          isOnTop={isOnTop}
        />
      </HeadroomWrapper>
    </>
  );
};

export default Header;

const HeadroomWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  will-change: transform;
  z-index: 100;
  padding: 0 2.4rem;
  transition: all 300ms var(--ease-cubic-in-out);

  @media (${bp.min.sm_md}) {
    padding: 0 4.8rem;
  }

  &.headroom--top {
    background-color: transparent !important;
  }

  &.headroom--pinned,
  &.headroom--not-top {
    transform: translateY(0);
    background-color: ${props =>
      props.menuIsOpen || props.variant === 'inverted'
        ? 'rgba(19, 19, 19, 0.97)'
        : 'rgba(250, 249, 247, 0.97)'};

    .header-desktop {
      &:after {
        opacity: 0;
      }
    }
  }

  &.headroom--unpinned {
    transform: translateY(-100%);
  }
`;
