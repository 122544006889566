import React from 'react';
import styled from 'styled-components';

const ArrowIcon = ({
  width = 32,
  rotation = 90,
  color = 'var(--color-black)',
}) => {
  return (
    <IconWrapper
      width={width}
      viewBox="0 0 31 30"
      rotation={rotation}
      fill="none"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
      className="arrow-icon"
    >
      <title>Arrow Icon</title>

      <path
        d="M14.968 31.0001L14.968 1M14.968 1L1 16.1733M14.968 1L29 16.1733"
        strokeWidth={width > 32 ? 1 : 1.5}
        strokeMiterlimit="10"
      />
    </IconWrapper>
  );
};

export default ArrowIcon;

/**
 * Styled Components
 *
 */
const IconWrapper = styled.svg`
  --rotation: ${props => `${props.rotation}deg`};
  height: auto;

  path {
    transform: rotate(var(--rotation));
    transform-origin: center;
  }
`;
