import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

import bp from '../assets/js/breakpoints';

import Image from './Image';
import AnimateFadeIn from './AnimateFadeIn';

const Slide = React.forwardRef(({ index, image }, ref) => {
  return (
    <SlideWrapper index={index - 1} key={index} ref={ref}>
      <Image
        image={image.image}
        aspectRatio={3 / 4}
        borderRadius={5}
        loading="eager"
      />
    </SlideWrapper>
  );
});

const AboutHero = ({ data }) => {
  const { hero_title, hero_subtitle, hero_images } = data;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideColor, setSlideColor] = useState(
    hero_images[0].background_color || '#ffffff'
  );

  const slideRef = useRef([]);
  const background = useRef([]);

  // The number of slides to the right of the center slide
  const slidesRight = 3;
  const numberOfSlides = hero_images.length;

  useEffect(() => {
    let slideAnimation;

    slideRef.current.forEach((slide, i) => {
      const index = i > slidesRight ? i - numberOfSlides : i;

      gsap.set(slide, {
        rotation: index * 15,
      });

      slideAnimation = gsap.to(slide, {
        duration: 1,
        delay: 3.5,
        rotation: '-=15',
        modifiers: {
          rotation: value => {
            return parseFloat(value) < -45 ? 45 : value;
          },
        },
        repeat: -1,
        repeatRefresh: true,
        repeatDelay: 2.5,
        ease: 'back.out(0.7)',
      });
    });

    // Hacky, but this is timed with the rotation above. No good way to get
    //  this timed with the repeat above and keep the numbers synced
    let interval = setInterval(() => {
      setCurrentSlide(prevState => prevState + 1);
    }, 3500);

    return () => {
      clearInterval(interval);
      slideAnimation.kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Keep currentSlide between 0 and total number of slides
    const wrap = gsap.utils.wrap(0, numberOfSlides);

    setSlideColor(hero_images[wrap(currentSlide)].background_color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hero_images, currentSlide]);

  return (
    <Hero>
      <HeroBackground bgColor={slideColor} ref={background} />

      <AnimateFadeIn delay={0.35} duration={1}>
        <Projector>
          {hero_images.map((image, i) => (
            <Slide
              key={i}
              index={i}
              image={image}
              ref={el => (slideRef.current[i] = el)}
            />
          ))}
        </Projector>

        <HeroText>
          <p>{hero_title}</p>
          <h1>{hero_subtitle}</h1>
        </HeroText>
      </AnimateFadeIn>
    </Hero>
  );
};

export default AboutHero;

/**
 * Styled Components
 *
 */

const Hero = styled.section`
  text-align: center;
  margin-bottom: var(--space-xxxl);

  @media (${bp.min.sm_md}) {
    margin-bottom: 32rem;
  }
`;

const Projector = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

const SlideWrapper = styled.div`
  --slide-count: 1.75;

  grid-area: 1/1;
  width: calc(100vw / var(--slide-count));
  max-width: 30rem;
  transform-origin: 50% 420%;

  @media (${bp.min.sm}) {
    --slide-count: 5;
    transform-origin: 50% 450%;
  }

  @media (${bp.min.xl}) {
    transform-origin: 50% 470%;
    max-width: 70rem;
  }
`;

const HeroText = styled.div`
  margin: var(--space-xxl) auto 0;
  padding: 0 var(--space-xxs);
  max-width: 68rem;
  width: 100%;

  @media (${bp.min.sm}) {
    margin: 12rem auto 0;
  }

  h1 {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
    font-size: clamp(3.6rem, 4vw, 6rem);
    line-height: 1.1;
    letter-spacing: -0.1rem;
    margin-top: var(--space-xs);

    @media (${bp.min.sm_md}) {
      max-width: 65rem;
    }
  }
`;

const HeroBackground = styled.div`
  background-image: linear-gradient(
    to bottom,
    rgba(250, 249, 247, 0),
    var(--color-off-white)
  );
  background-color: ${props => props.bgColor};
  width: 100%;
  height: 85vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: background-color 400ms var(--ease-cubic-in-out);
`;
