import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { Row, Col } from '../components/grid-system';
import {
  getActiveDocLanguage,
  getFormattedEvents,
  getSiteMeta,
} from '../assets/js/utilities';
import bp from '../assets/js/breakpoints';
import linkResolver from '../utilities/link-resolver';

import Layout from '../components/Layout';
import Heading from '../components/Heading';
import Link from '../components/Link';
import Calendar from '../components/Calendar';
import Seo from '../components/Seo';
import NewsEventsLoadMoreCallout from '../components/NewsEventsLoadMoreCallout';
import DonateCallout from '../components/DonateCallout';
import FeaturedEventHero from '../components/FeaturedEventHero';
import AnimateFadeIn from '../components/AnimateFadeIn';

const NewsAndEvents = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;
  const news = data.allPrismicNews;
  const eventCalendar = data.allPrismicEvent.edges;
  const donateCallout = data.prismicDonateCallout;

  // Get page data
  const newsAndEvents = data.prismicNewsAndEvents;
  const {
    title,
    featured_events,
    callouts,
    meta_description,
    meta_image,
  } = newsAndEvents.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(newsAndEvents);
  const defaultMeta = getSiteMeta(layout.data);

  const flattenedEventsArray = featured_events.map(event => ({
    node: event.event.document,
  }));

  const formattedEvents = getFormattedEvents(
    flattenedEventsArray,
    activeDoc.locale
  );

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data} hideOverflowX>
      <Seo
        title={title}
        description={meta_description}
        image={meta_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
      />

      <AnimateFadeIn>
        <FeaturedEventHero content={formattedEvents} lang={activeDoc.locale} />

        <Row>
          <Col base={12} lg={10} lgOffset={1}>
            <Calendar events={eventCalendar} locale={activeDoc.locale} />

            <LinkCallouts>
              {callouts.map(callout => (
                <li key={callout.title}>
                  <Link url={callout.link}>
                    <Heading level="h3" size="m" content={callout.title} />
                    <RichText render={callout.description.richText} />
                  </Link>
                </li>
              ))}
            </LinkCallouts>
          </Col>
        </Row>

        {news.edges.length > 0 && (
          <>
            <Anchor id="news" />
            <NewsEventsLoadMoreCallout news={news} lang={activeDoc.locale} />
          </>
        )}

        <DonateCallout content={donateCallout.data} />
      </AnimateFadeIn>
    </Layout>
  );
};

export default withPrismicPreview(NewsAndEvents, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * GraphQL
 *
 */
const Anchor = styled.div`
  height: var(--header-height-mobile);
  margin-top: calc(var(--header-height-mobile) * -1);

  @media (${bp.min.sm}) {
    height: var(--header-height-desktop);
    margin-top: calc(var(--header-height-desktop) * -1);
  }
`;

const LinkCallouts = styled.ul`
  margin: var(--space-l) 0;

  @media (${bp.min.sm_md}) {
    margin: 9.6rem 0 var(--space-xxl);
    display: flex;
  }

  li {
    text-align: center;
    background-color: var(--color-white);
    border: 0.1rem solid #e1dfdf;
    border-radius: 1rem;
    transition: all 250ms var(--ease-cubic-in-out);

    @media (${bp.min.sm_md}) {
      width: calc(100% / 3 - 1.6rem);
    }

    @media (hover: hover) {
      &:hover {
        border-color: var(--color-neon-green);
        transform: scale(1.04);
      }
    }

    & + li {
      margin-top: var(--space-s);

      @media (${bp.min.sm_md}) {
        margin-left: var(--space-m);
        margin-top: 0;
      }
    }

    .heading-tag {
      margin-bottom: var(--space-l);
      color: var(--color-black);
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 5.6rem 8%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($language: String) {
    prismicNewsAndEvents(lang: { eq: $language }) {
      _previewable
      data {
        title
        featured_events {
          event {
            document {
              ... on PrismicEvent {
                ...FeaturedEvent
              }
            }
          }
        }
        callouts {
          title
          description {
            richText
          }
          link {
            url
            link_type
          }
        }
        meta_description
        meta_image {
          url
        }
      }
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
    allPrismicNews(
      filter: { lang: { eq: $language }, data: { post_type: { eq: "News" } } }
    ) {
      edges {
        node {
          ...NewsItems
        }
      }
    }
    allPrismicEvent(filter: { lang: { eq: $language } }) {
      edges {
        node {
          id
          url
          lang
          type
          data {
            title
            start
            end
            all_day
            cost
            recurring_event
            frequency
            interval
            recurring_end
            excluded_dates {
              date
            }
            thumbnail {
              alt
              gatsbyImageData(width: 600)
            }
            featured_image {
              alt
              gatsbyImageData(width: 1100)
              url
            }
            location {
              venue
              address {
                richText
                text
              }
              google_maps_url
              website {
                url
              }
            }
          }
        }
      }
    }
    prismicDonateCallout(lang: { eq: $language }) {
      ...DonateCallout
    }
  }
`;
