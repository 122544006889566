import dayjs from 'dayjs';

import About from '../../templates/About';
import Basic from '../../templates/Basic';
import Donate from '../../templates/Donate';
import Event from '../../templates/Event';
import Faqs from '../../templates/Faqs';
import History from '../../templates/History';
import Homepage from '../../templates/Homepage';
import News from '../../templates/News';
import NewsAndEvents from '../../templates/NewsAndEvents';
import Resources from '../../templates/Resources';
import Vision from '../../templates/Vision';
import Visit from '../../templates/Visit';

export const prismicPreviewPages = {
  homepage: Homepage,
  visit: Visit,
  vision: Vision,
  donate: Donate,
  resources: Resources,
  history: History,
  faqs: Faqs,
  about: About,
  news_and_events: NewsAndEvents,
  event: Event,
  news: News,
  basic: Basic,
};

// Test whether code is being run in the browser or on the server
export const isBrowser = typeof window !== 'undefined';

// Detect if user has preference for reduced motion
export const prefersReducedMotion =
  isBrowser && window.matchMedia('(prefers-reduced-motion: reduce)').matches;

// Get the height of an email and return a rem value
export function getElementHeight(elem) {
  if (!elem) {
    return;
  }

  return `${elem.clientHeight / 10}rem`;
}

// Linear Interpolation
export function lerp(start, end, amt) {
  return (1 - amt) * start + amt * end;
}

export function getFormattedEvents(events, locale) {
  return events.map(event => {
    const eventNode = event.node;
    const eventData = eventNode.data;

    // Get event start/end data
    const eventStart = new Date(eventData.start);
    const eventEnd = new Date(eventData.end);
    const recurringEnd = new Date(eventData.recurring_end);

    // Format date - ex: Saturday, October 9, 2021 at 5:00pm
    const dayFormat = {
      dateStyle: 'full',
    };

    const timeFormat = {
      timeStyle: 'short',
    };

    // Convert string to a more readable format
    const frequencyString =
      eventData.frequency === 'Weekly'
        ? 'week'
        : eventData.frequency === 'Daily'
        ? 'day'
        : eventData.frequency === 'Monthly'
        ? 'month'
        : 'year';

    // Get start and end days, formatted for different locales
    const today = dayjs();
    let start = eventStart;
    let startDay = createIntlDateTime(eventStart, dayFormat, locale);
    let startTime = createIntlDateTime(
      eventStart,
      { timeStyle: 'short' },
      locale
    );
    const endTime = createIntlDateTime(eventEnd, timeFormat, locale);

    // Get an array of dates for recurring events
    const dateRange = eventData.recurring_event
      ? generateDateRange(
          eventStart,
          recurringEnd,
          eventData.interval,
          frequencyString
        )
      : null;
    const excludedDates = eventData.excluded_dates.map(date => date.date);

    // If the date range exists, filter down to the next closest event
    //  after today. If the event ended, then display the last date it recurred.
    if (dateRange) {
      const nextEvent = dateRange
        .filter(date => {
          // Remove the time from the date for comparison
          return !excludedDates.includes(date.split(' ')[0]);
        })
        .map(date => {
          return dayjs(date);
        })
        // .sort(date => {
        //   return date;
        // })
        .find(date => {
          return date.isAfter(today);
        });

      if (nextEvent) {
        startDay = createIntlDateTime(nextEvent, dayFormat, locale);
        start = new Date(nextEvent);
      } else {
        startDay = createIntlDateTime(
          dayjs(dateRange[dateRange.length - 1]),
          dayFormat,
          locale
        );

        start = new Date(dayjs(dateRange[dateRange.length - 1]).format());
      }
    }

    const isPastEvent = today.isAfter(start);

    return {
      lang: eventNode.lang,
      url: eventNode.url,
      data: {
        all_day: eventData.all_day,
        cost: eventData.cost,
        start: start,
        end: eventEnd,
        start_day: startDay,
        start_time: startTime,
        end_time: endTime,
        recurring_event: eventData.recurring_event,
        frequency: frequencyString,
        interval: eventData.interval,
        excluded_dates: eventData.excluded_dates,
        recurring_end: eventData.recurring_end,
        title: eventData.title,
        location: eventData.location,
        thumbnail: eventData.thumbnail,
        featured_image: eventData.featured_image,
        isPastEvent: isPastEvent,
        featured_excerpt: eventData.featured_excerpt,
        featured_background_color: eventData.featured_background_color,
      },
    };
  });
}

// Get current page language and return proper language data
export function getActiveDocLanguage(doc) {
  const { lang, type } = doc;
  const alternateLanguages = doc.alternate_languages;
  const locale = lang === 'en-us' ? 'en' : lang === 'es-es' ? 'es' : lang;
  const localePath = locale === 'en' ? '' : `/${locale}`;

  return {
    lang,
    locale,
    localePath,
    type,
    alternateLanguages,
  };
}

// Get meta data for OpenGraph tags
export function getSiteMeta(data) {
  const { site_title, site_description, site_image } = data;

  return {
    site_title,
    site_description,
    site_image,
  };
}

// Calculate number of hours between two date strings
export function getDuration(dt2, dt1) {
  let hoursDiff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
  hoursDiff /= 60 * 60;

  return Math.abs(hoursDiff);
}

// Convert number to a formatted date string (HH:MM)
export function timeConvert(num) {
  var n = new Date(0, 0);
  n.setMinutes(+num * 60);
  return n.toTimeString().slice(0, 5);
}

// Use the current page locale to format date/time strings
export function createIntlDateTime(eventData, formatObj, locale) {
  return new Intl.DateTimeFormat(locale, formatObj).format(eventData);
}

// Converge string to kebab-case (useful for urls/paths/etc.)
export function toKebabCase(str) {
  if (typeof str !== 'string') return;
  return str.replaceAll(' ', '-').toLowerCase();
}

// Get a random number between two supplied numbers
export function randomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// Get a range of dates based on start date, end date, and optional
//  recurring rules
export function generateDateRange(
  from,
  to,
  interval = 1,
  frequency = 'day',
  format = 'YYYY-MM-DD H:mm'
) {
  const dates = [];

  let currentDate = dayjs(from);
  const endDate = dayjs(to);

  while (currentDate <= endDate) {
    dates.push(currentDate.format(format));
    currentDate = currentDate.add(interval, frequency);
  }

  return dates;
}

// Manually translate strings that are not translated within the CMS
export function createTranslation(textToTranslate, currentLanguage) {
  // If no language is provided, or the language is English, just return
  //  the original text back
  if (currentLanguage === undefined || currentLanguage === 'en')
    return textToTranslate;

  // Convert original text to lowercase to prevent issues when using
  //  object notation to find translation
  const textToTranslateLower = textToTranslate.toLowerCase();

  // Dictionary of all items that need to be manually translated outside
  //  of the CMS
  const dictionary = {
    swipe: {
      es: 'Deslizar',
      'zh-cn': '刷卡',
    },
    day: {
      es: 'Día',
      'zh-cn': '日',
    },
    night: {
      es: 'Noche',
      'zh-cn': '夜晚',
    },
    'jump to': {
      es: 'Salta a',
      'zh-cn': '跳到',
    },
    'no results': {
      es: 'No hay resultados',
      'zh-cn': '没有结果',
    },
    result: {
      es: 'resultado',
      'zh-cn': '结果',
    },
    'back to top': {
      es: 'Volver arriba',
      'zh-cn': '回到顶部',
    },
    'call us': {
      es: 'Llámanos',
      'zh-cn': '给我们打电话',
    },
    'email us': {
      es: 'Envíenos un correo electrónico',
      'zh-cn': '给我们发电子邮件',
    },
    search: {
      es: 'Buscar',
      'zh-cn': '搜索',
    },
    'copy email address': {
      es: 'copiar dirección de correo electrónico',
      'zh-cn': '复制电子邮件地址',
    },
    copied: {
      es: 'Copiado',
      'zh-cn': '已复制',
    },
    'unable to copy value': {
      es: 'No se puede copiar el valor',
      'zh-cn': '无法复制值',
    },
    'full event calendar': {
      es: 'Calendario completo de eventos',
      'zh-cn': '完整的活动日历',
    },
    details: {
      es: 'Detalles',
      'zh-cn': '细节',
    },
    'read more': {
      es: 'Lee mas',
      'zh-cn': '阅读更多',
    },
    'featured event': {
      es: 'Evento destacado',
      'zh-cn': '特色活动',
    },
    'news & events': {
      es: 'Noticias y Eventos',
      'zh-cn': '新闻与事件',
    },
    'upcoming events': {
      es: 'Próximos Eventos',
      'zh-cn': '即将举行的活动',
    },
    news: {
      es: 'Noticias',
      'zh-cn': '消息',
    },
    'load more': {
      es: 'Carga más',
      'zh-cn': '装载更多',
    },
    submit: {
      es: 'Enviar',
      'zh-cn': '提交',
    },
    next: {
      es: 'Próximo',
      'zh-cn': '下一个',
    },
    previous: {
      es: 'Previo',
      'zh-cn': '以前的',
    },
    'menu button': {
      es: 'Botón de menú',
      'zh-cn': '菜单按钮',
    },
    'close modal': {
      es: 'Cerrar modal',
      'zh-cn': '关闭模态',
    },
    translations: {
      es: 'Traducciones',
      'zh-cn': '翻译',
    },
    'follow along': {
      es: 'Seguir a lo largo',
      'zh-cn': '跟着',
    },
    visit: {
      es: 'Visita',
      'zh-cn': '访问',
    },
    vision: {
      es: 'Visión',
      'zh-cn': '想象',
    },
    'travel method': {
      es: 'Método de viaje',
      'zh-cn': '出行方式',
    },
    'get directions to': {
      es: 'Obtener indicaciones para',
      'zh-cn': '获取前往',
    },
    list: {
      es: 'Lista',
      'zh-cn': '列表',
    },
    month: {
      es: 'Mes',
      'zh-cn': '月',
    },
    free: {
      es: 'Gratis',
      'zh-cn': '自由',
    },
    'phase one': {
      es: 'Fase uno',
      'zh-cn': '第一阶段',
    },
    'the cut': {
      es: 'El Corte',
      'zh-cn': '分岔口',
    },
    'the tunnel': {
      es: 'El Túnel',
      'zh-cn': '隧道',
    },
    'the viaduct': {
      es: 'El Viaducto',
      'zh-cn': '高架桥',
    },
  };

  // If the provided term exists in the dictionary and has an associated
  //  translation return that text, otherwise return the original text
  if (dictionary[textToTranslateLower]) {
    return dictionary[textToTranslateLower][currentLanguage];
  }

  return textToTranslate;
}
