import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';
import { getActiveDocLanguage, getSiteMeta } from '../assets/js/utilities';
import linkResolver from '../utilities/link-resolver';

import Seo from '../components/Seo';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import DonateCallout from '../components/DonateCallout';
import LinkColumns from '../components/LinkColumns';
import AboutHero from '../components/AboutHero';
import AnimateImage from '../components/AnimateImage';
import AnimateFadeIn from '../components/AnimateFadeIn';

import memberPlant1_1 from '../assets/images/member-plant-1-1.png';
import memberPlant1_2 from '../assets/images/member-plant-1-2.png';
import memberPlant2_1 from '../assets/images/member-plant-2-1.png';
import memberPlant2_2 from '../assets/images/member-plant-2-2.png';

gsap.registerPlugin(ScrollTrigger);

const About = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;
  const donateCallout = data.prismicDonateCallout;

  // Get page data
  const about = data.prismicAbout;
  const {
    title,
    our_team_title,
    our_team_description,
    team_members,
    board_of_directors_title,
    board_of_directors_description,
    board_members,
    our_partners_image,
    our_partners_title,
    our_partners_description,
    our_partners,
    our_supporters_title,
    our_supporters_subtitle,
    our_supporters,
    meta_description,
    meta_image,
  } = about.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(about);
  const defaultMeta = getSiteMeta(layout.data);

  const ourTeamTitle = useRef(null);

  useEffect(() => {
    const splitText = new SplitText(ourTeamTitle.current, { type: 'words' });

    gsap.from(splitText.words, {
      y: 20,
      opacity: 0,
      scrollTrigger: { trigger: ourTeamTitle.current, start: 'top 80%' },
      duration: 0.5,
      stagger: 0.08,
      ease: 'cubic.inOut',
    });
  }, []);

  // Hover state images
  const memberPlantImages = [
    {
      left: memberPlant1_1,
      right: memberPlant1_2,
    },
    {
      left: memberPlant2_1,
      right: memberPlant2_2,
    },
  ];

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data} hideOverflowX>
      <Seo
        title={title}
        description={meta_description}
        image={meta_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
      />
      <AnimateFadeIn>
        <AboutHero data={about.data} />

        <Row>
          <Col base={12} xl={10} xlOffset={1}>
            <OurTeam>
              <div id="team" />
              <Heading
                level="h2"
                size="xl"
                content={our_team_title}
                ref={ourTeamTitle}
              />

              <div className="intro">
                <AnimateFadeIn>
                  <RichText render={our_team_description.richText} />
                </AnimateFadeIn>
              </div>

              <div className="team-members">
                {team_members.map((member, i) => (
                  <div className="member" key={member.name}>
                    <div className="member__image">
                      <img
                        src={memberPlantImages[i % 2].left}
                        alt=""
                        className="member__plant member__plant--1"
                      />
                      <img
                        src={memberPlantImages[i % 2].right}
                        alt=""
                        className="member__plant member__plant--2"
                      />
                      <AnimateImage duration={1}>
                        <Image
                          image={member.image}
                          aspectRatio={4 / 5}
                          borderRadius={5}
                        />
                      </AnimateImage>
                    </div>

                    <div className="member__info">
                      <AnimateFadeIn delay={0.2}>
                        <Heading level="h3" size="m" content={member.name} />
                        <p>{member.pronouns}</p>
                        <p>{member.title}</p>
                        <a
                          href={`mailto:${member.email}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {member.email}
                        </a>
                      </AnimateFadeIn>
                    </div>
                  </div>
                ))}
              </div>
            </OurTeam>
          </Col>
        </Row>

        <BoardOfDirectors>
          <Row>
            <Col base={12} lg={10} lgOffset={1}>
              <Row>
                <Col base={12} sm={6}>
                  <Heading
                    level="h2"
                    size="m"
                    content={board_of_directors_title}
                  />
                  <RichText render={board_of_directors_description.richText} />
                </Col>

                <Col base={12} sm={6}>
                  <Row as="ul">
                    {board_members.map(member => (
                      <Col base={12} sm_md={6} as="li" key={member.name}>
                        <span>{member.name}</span>
                        <span>{member.title}</span>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </BoardOfDirectors>

        <OurPartners>
          <Row>
            <Col base={12} lg={10} lgOffset={1}>
              <Row middle="sm">
                <Col base={12} sm={6}>
                  <AnimateImage>
                    <Image
                      image={our_partners_image}
                      aspectRatio={1 / 1}
                      borderRadius={5}
                    />
                  </AnimateImage>
                </Col>

                <Col base={12} sm={6} md={5} mdOffset={1}>
                  <Heading level="h3" size="m" content={our_partners_title} />
                  <RichText render={our_partners_description.richText} />

                  <ul>
                    {our_partners.map(partner => (
                      <li key={partner.name}>
                        <a
                          href={partner.website.url}
                          target="blank"
                          rel="noreferrer"
                        >
                          <span className="sr-only">{partner.name}</span>
                          <img src={partner.logo.url} alt={partner.logo.alt} />
                        </a>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </OurPartners>

        <OurSupporters>
          <Row>
            <Col base={12} lg={10} lgOffset={1}>
              <LinkColumns
                title={our_supporters_title}
                subtitle={our_supporters_subtitle}
                listItems={our_supporters}
              />
            </Col>
          </Row>
        </OurSupporters>

        <DonateCallout content={donateCallout.data} />
      </AnimateFadeIn>
    </Layout>
  );
};

export default withPrismicPreview(About, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const OurTeam = styled.section`
  margin-bottom: 9.6rem;

  @media (${bp.min.xs}) {
    margin-bottom: var(--space-xxxl);
  }

  @media (${bp.min.sm_md}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 0 var(--space-m);
  }

  @media (${bp.min.xl}) {
    grid-template-columns: repeat(10, 1fr);
  }

  #team {
    height: var(--header-height-mobile);
    margin-top: calc(var(--header-height-mobile) * -1);
    position: absolute;

    @media (${bp.min.sm}) {
      height: var(--header-height-desktop);
      margin-top: calc(var(--header-height-desktop) * -1);
    }
  }

  h2 {
    grid-column: 1 / -1;
    grid-row: 1 / 2;

    @media (${bp.max.xs}) {
      font-size: 5rem;
      line-height: 1;
      letter-spacing: -0.15rem;
    }
  }

  .intro {
    grid-column: 8 / -1;
    grid-row: 2 / 4;
    margin-top: var(--space-l);
    margin-bottom: var(--space-l);

    @media (${bp.min.xs}) {
      margin-top: var(--space-m);
      margin-bottom: var(--space-xl);
    }

    @media (${bp.min.sm_md}) {
      margin-top: 6rem;
      margin-bottom: 0;
    }

    @media (${bp.min.xl}) {
      grid-column: 7 / -1;
    }

    p + p {
      margin-top: var(--space-m);
    }
  }

  .team-members {
    grid-column: 1 / -1;
    grid-row: 3 / 5;

    @media (${bp.min.sm_md}) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 0 var(--space-m);
    }

    @media (${bp.min.lg}) {
      grid-row: 4 / 6;
    }

    @media (${bp.min.xl}) {
      grid-template-columns: repeat(10, 1fr);
    }
  }

  .member {
    @media (${bp.min.xs}) {
      display: flex;
      align-items: flex-end;
    }

    @media (${bp.min.sm_md}) {
      grid-column: 5 / 11;
    }

    @media (hover: hover) {
      &:hover {
        .member__plant {
          transform: scale(1);
          opacity: 1;
          clip-path: circle(150%);
        }

        &:nth-of-type(odd) {
          .image-wrapper {
            transform: rotate(-2deg);
          }
        }

        .image-wrapper {
          transform: rotate(2deg);
        }
      }
    }

    &:nth-of-type(odd) {
      grid-column: 1 / 7;

      @media (${bp.min.lg}) {
        grid-column: 2 / 8;
      }
    }

    & + .member {
      margin-top: var(--space-xxl);

      @media (${bp.min.sm_md}) {
        margin-top: var(--space-xl);
      }

      @media (${bp.min.sm_md}) {
        margin-top: var(--space-xxl);
      }
    }

    .heading-tag {
      white-space: pre-line;
    }

    p + p {
      margin-top: var(--space-m);
    }
  }

  .member__image {
    margin-bottom: var(--space-m);

    @media (${bp.min.xs}) {
      position: relative;
      width: 50%;
      margin-right: var(--space-m);
      margin-bottom: 0;
    }

    @media (${bp.min.sm_md}) {
      width: calc(60% - 1.2rem);
    }

    @media (${bp.min.lg}) {
      width: calc(50% - 1.2rem);
    }

    .image-wrapper {
      transition: transform 650ms var(--ease-cubic-in-out);

      img {
        height: 100%;
      }
    }
  }

  .member__plant {
    display: none;
    position: absolute;
    width: 70%;
    transform: scale(0.99);
    opacity: 0;
    will-change: transform;
    z-index: -1;
    transition: transform 550ms var(--ease-cubic-in-out),
      opacity 700ms var(--ease-cubic-in-out),
      clip-path 850ms var(--ease-cubic-in-out);
    transition-delay: 200ms;

    @media (${bp.min.sm_md}) {
      display: block;
    }
  }

  .member__plant--1 {
    left: -40%;
    bottom: 0;
    clip-path: circle(0%);
    transform-origin: right bottom;
  }

  .member__plant--2 {
    right: -40%;
    top: -20%;
    transition-delay: 150ms;
    clip-path: circle(0%);
    transform-origin: left bottom;
  }

  .member__info {
    width: 100%;
    font-size: 2rem;

    @media (${bp.min.xs}) {
      padding-bottom: var(--space-s);
      width: 50%;
    }

    @media (${bp.min.sm_md}) {
      width: calc(40% - 1.2rem);
    }

    @media (${bp.min.lg}) {
      width: calc(50% - 1.2rem);
      padding-bottom: 10%;
    }
  }
`;

const BoardOfDirectors = styled.section`
  margin-bottom: var(--space-xxl);

  h2 {
    margin-bottom: var(--space-l);
  }

  p {
    font-size: 2rem;

    @media (${bp.min.xs_sm}) {
      padding-right: 10%;
    }
  }

  ul {
    margin-top: 4.8rem;
  }

  li {
    padding-bottom: var(--space-l);

    span {
      display: block;
      font-size: 2rem;

      &:first-of-type {
        font-size: 3rem;
        margin-bottom: 0.2rem;
      }
    }
  }
`;

const OurPartners = styled.section`
  margin-bottom: 9.6rem;

  @media (${bp.min.sm}) {
    margin-bottom: var(--space-xxxl);
  }

  h3 {
    margin-top: var(--space-l);
    margin-bottom: var(--space-s);

    @media (${bp.min.sm}) {
      margin-top: 0;
      margin-bottom: var(--space-xxl);
    }
  }

  ul {
    margin-top: var(--space-xl);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (${bp.min.xs}) {
      flex-direction: row;
    }

    li:first-of-type {
      margin-right: var(--space-xxl);
    }

    li + li {
      margin-top: var(--space-s);

      @media (${bp.min.xs}) {
        margin-top: 0;
      }
    }
  }
`;

const OurSupporters = styled.section`
  margin-bottom: var(--space-m);

  @media (${bp.min.sm_md}) {
    margin-bottom: var(--space-xl);
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($language: String) {
    prismicAbout(lang: { eq: $language }) {
      _previewable
      data {
        title
        hero_title
        hero_subtitle
        hero_images {
          image {
            alt
            gatsbyImageData(width: 400)
          }
          background_color
        }
        our_team_title
        our_team_description {
          richText
        }
        team_members {
          name
          pronouns
          title
          email
          image {
            alt
            gatsbyImageData(width: 500)
          }
        }
        board_of_directors_title
        board_of_directors_description {
          richText
        }
        board_members {
          name
          title
        }
        our_partners_image {
          alt
          gatsbyImageData(width: 700)
        }
        our_partners_title
        our_partners_description {
          richText
        }
        our_partners {
          name
          logo {
            alt
            url
          }
          website {
            url
          }
        }
        our_supporters_title
        our_supporters_subtitle
        our_supporters {
          name
          website {
            url
          }
        }
        meta_description
        meta_image {
          url
        }
      }
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
    prismicDonateCallout(lang: { eq: $language }) {
      ...DonateCallout
    }
  }
`;
