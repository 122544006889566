import { RichText } from 'prismic-reactjs';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';
import { getElementHeight } from '../assets/js/utilities';

import Heading from './Heading';
import Image from './Image';

const AccordionItem = React.memo(({ point, number, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [itemHeight, setItemHeight] = useState(0);
  const itemBody = useRef(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setItemHeight(isOpen ? getElementHeight(itemBody.current) : 0);
  }, [isOpen]);

  return (
    <AccordionItemWrapper isOpen={isOpen} isDarkMode={isDarkMode}>
      <button aria-expanded={isOpen} onClick={handleClick}>
        <Heading level="h3" size="m" content={`${number} – ${point.name}`} />
      </button>

      <div className="accordion__body-wrapper" style={{ height: itemHeight }}>
        <div className="accordion__body" ref={itemBody}>
          <Image image={point.image} aspectRatio={16 / 9} />

          <RichText render={point.description.richText} />
        </div>
      </div>
    </AccordionItemWrapper>
  );
});

const VisitPOIAccordion = ({ content: points_of_interest, isDarkMode }) => {
  return (
    <AccordionWrapper>
      {points_of_interest.map((point, i) => (
        <AccordionItem
          point={point}
          number={`0${i + 1}`}
          key={point.name}
          isDarkMode={isDarkMode}
        />
      ))}
    </AccordionWrapper>
  );
};

export default VisitPOIAccordion;

const AccordionWrapper = styled.ul`
  margin-top: var(--space-xl);

  @media (${bp.min.sm}) {
    display: none;
  }
`;

const AccordionItemWrapper = styled.li`
  border-bottom: 0.1rem solid
    var(${props => (props.isDarkMode ? '--color-white' : '--color-black')});
  list-style-type: none;

  button {
    padding: var(--space-m) 0;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }

  .heading-tag {
    color: var(
      ${props => (props.isDarkMode ? '--color-white' : '--color-black')}
    );
  }

  .image-wrapper {
    margin-bottom: var(--space-s);
  }

  .accordion__body-wrapper {
    overflow: hidden;
    width: 100%;
    transition: height 500ms var(--ease-cubic-in-out);
  }

  .accordion__body {
    padding-bottom: var(--space-m);
    opacity: ${props => (props.isOpen ? 1 : 0)};
    transition: opacity 400ms cubic-bezier(0.65, 0.05, 0.36, 1) 50ms;

    p {
      font-size: 2rem;
    }
  }
`;
