import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard } from 'swiper';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';

import SwipeIndicator from '../components/SwipeIndicator';
import Image from '../components/Image';
import Heading from '../components/Heading';
import ImageShuffle from './ImageShuffle';

import 'swiper/css';

SwiperCore.use([Keyboard]);

const VisionFacts = ({ content: facts, lang }) => {
  const [imageToShow, setImageToShow] = useState(0);

  return (
    <CalloutWrapper>
      <Swiper
        className="mobile-facts"
        spaceBetween={32}
        slidesPerView={1.25}
        centeredSlides={true}
        loop={true}
        keyboard={{
          enabled: true,
        }}
        breakpoints={{
          650: {
            slidesPerView: 2,
          },
          750: {
            slidesPerView: 2.5,
          },
        }}
        lazy={{
          loadPrevNext: true,
          loadPrevNextAmount: facts.length,
        }}
      >
        {facts.map((fact, i) => (
          <SwiperSlide key={i}>
            <Image image={fact.image} aspectRatio={4 / 5} borderRadius={5} />

            <div className="fact-info">
              <Heading level="h3" size="xs" content={fact.title} />
              <RichText render={fact.description.richText} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <SwipeIndicator lang={lang} />

      <Row middle="xs" className="desktop-facts">
        <Col base={12} sm_md={6} xl={5} xlOffset={1} as="ul">
          {facts.map((fact, i) => (
            <Fact key={fact.title} isActive={i === imageToShow}>
              <button
                onMouseOver={() => {
                  setImageToShow(i);
                }}
                onFocus={() => {
                  setImageToShow(i);
                }}
                tabIndex="0"
              >
                <Heading level="h3" size="xs" content={fact.title} />
                <RichText render={fact.description.richText} />
              </button>
            </Fact>
          ))}
        </Col>

        <Col base={false} sm={6} lg={5} xl={4} lgOffset={1}>
          <ImageShuffle imageArray={facts} imageToShow={imageToShow} />
        </Col>
      </Row>
    </CalloutWrapper>
  );
};

export default VisionFacts;

/**
 * Styled Components
 *
 */
const CalloutWrapper = styled.section`
  margin: var(--space-xxxl) 0 var(--space-xxl);

  @media (${bp.min.sm}) {
    margin: var(--space-xxxl) 0;
  }

  h2 {
    margin-bottom: var(--space-m);
  }

  .mobile-facts {
    text-align: center;
    overflow: visible;

    @media (${bp.min.sm_md}) {
      display: none;
    }

    &:focus {
      outline: 0;
    }

    .heading-tag {
      font-weight: var(--font-weight-semi-bold);
      border-bottom: 0.1rem solid var(--color-black);
      padding-bottom: var(--space-xs);
      margin-top: 4.8rem;
      margin-bottom: var(--space-m);
      transition: all 200ms var(--ease-cubic-in-out);
    }

    p {
      font-size: 1.5rem;
      line-height: 1.4;
      letter-spacing: -0.01rem;
    }
  }

  .desktop-facts {
    @media (${bp.max.sm_md}) {
      display: none;
    }

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4.8rem var(--space-m);
    }
  }

  .swipe-callout {
    margin: var(--space-s) auto;
  }

  .image {
    will-change: transform;
    transition: all 300ms var(--ease-cubic-in-out);
  }

  .fact-info {
    transition: all 200ms var(--ease-cubic-in-out);
  }

  .swiper-slide {
    &.swiper-slide-active,
    &.swiper-slide-duplicate-active {
      .fact-info {
        opacity: 1;
      }

      .image {
        transform: scale(1.1);
        border-radius: 0.5rem;
        box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.1),
          0px 8px 24px rgba(17, 17, 26, 0.1),
          0px 16px 48px rgba(17, 17, 26, 0.1);
      }
    }

    .fact-info {
      opacity: 0;
    }
  }

  .swiper-slide-prev,
  .swiper-slide-next,
  .swiper-slide-duplicate-prev,
  .swiper-slide-duplicate-next {
    .image {
      opacity: 0.6;
    }
  }
`;

const Fact = styled.li`
  cursor: default;

  @media (hover: hover) {
    &:hover {
      .heading-tag {
        color: var(--color-neon-green);

        &:after {
          transform: scaleX(1);
          transform-origin: left center;
        }
      }
    }
  }

  button {
    text-align: left;
  }

  .heading-tag {
    position: relative;
    font-weight: var(--font-weight-semi-bold);
    padding-bottom: var(--space-xxs);
    margin-bottom: var(--space-m);
    transition: all 200ms var(--ease-cubic-in-out);
    color: ${props =>
      props.isActive ? 'var(--color-neon-green)' : 'var(--color-black)'};

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 0.1rem;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &:before {
      background-color: var(--color-black);
    }

    &:after {
      transform: ${props => (props.isActive ? 'scaleX(1)' : 'scaleX(0)')};
      transform-origin: ${props =>
        props.isActive ? 'left center' : 'right center'};
      background-color: var(--color-neon-green);
      transition: transform 300ms var(--ease-cubic-in-out);
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 1.4;
    letter-spacing: -0.01rem;
  }

  a {
    color: var(--color-neon-green);

    &:hover {
      color: var(--color-black);
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment VisionFacts on PrismicVision {
    data {
      facts {
        title
        description {
          richText
        }
        image {
          alt
          gatsbyImageData(width: 650)
        }
      }
    }
  }
`;
