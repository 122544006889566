const breakpoints = {
  xs: `30`, // 480px / 16px -> em
  xs_sm: `38.75`, // 620px / 16px -> em
  sm: `46.875`, // 750px / 16px -> em
  sm_md: `60.625`, // 970px / 16px -> em
  md: `68.75`, // 1100px / 16px -> em
  lg: `80`, // 1280px / 16px -> em
  xl: `87.5`, // 1400px / 16px -> em
};
Object.freeze(breakpoints);

const bp = {
  breakpoints: {
    ...breakpoints,
  },
  min: {
    xs: `min-width: ${breakpoints.xs}em`,
    xs_sm: `min-width: ${breakpoints.xs_sm}em`,
    sm: `min-width: ${breakpoints.sm}em`,
    sm_md: `min-width: ${breakpoints.sm_md}em`,
    md: `min-width: ${breakpoints.md}em`,
    lg: `min-width: ${breakpoints.lg}em`,
    xl: `min-width: ${breakpoints.xl}em`,
  },
  max: {
    xs: `max-width: ${breakpoints.xs}em`,
    xs_sm: `max-width: ${breakpoints.xs_sm}em`,
    sm: `max-width: ${breakpoints.sm}em`,
    sm_md: `max-width: ${breakpoints.sm_md}em`,
    md: `max-width: ${breakpoints.md}em`,
    lg: `max-width: ${breakpoints.lg}em`,
    xl: `max-width: ${breakpoints.xl}em`,
  },
};
Object.freeze(bp);

export default bp;
