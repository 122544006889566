import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

import bp from '../assets/js/breakpoints';

import Heading from '../components/Heading';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText);
}

const VisionHero = ({
  content: { hero_title, hero_subtitle, hero_description },
}) => {
  const heroRef = useRef(null);
  const tl = useRef();

  useEffect(() => {
    const query = gsap.utils.selector(heroRef);
    tl.current = gsap.timeline();
    const splitText = new SplitText(query('h1'), {
      type: 'words',
    });

    tl.current
      .to(heroRef.current, {
        autoAlpha: 1,
        duration: 0.8,
        delay: 0.3,
      })
      .from(
        splitText.words,
        {
          opacity: 0,
          yPercent: 20,
          duration: 0.7,
          stagger: 0.05,
          ease: 'power2.out',
        },
        '<'
      )
      .from(
        query('h2, p'),
        {
          opacity: 0,
          yPercent: 20,
          duration: 0.7,
          stagger: 0.05,
          ease: 'power2.out',
        },
        '>-.5'
      );

    return () => {
      tl.current.kill();
    };
  }, []);

  return (
    <HeroWrapper ref={heroRef}>
      <HeroTitle>{hero_title}</HeroTitle>
      <Heading level="h2" size="s" content={hero_subtitle} />
      <RichText render={hero_description.richText} />
    </HeroWrapper>
  );
};

export default VisionHero;

/**
 * Styled Components
 *
 */
const HeroWrapper = styled.section`
  position: relative;
  z-index: 5;
  margin: var(--space-l) 0 var(--space-xl);
  text-align: center;
  visibility: hidden;
  opacity: 0;

  @media (${bp.min.xs_sm}) {
    margin: var(--space-xl) 0;
  }

  @media (${bp.min.sm}) {
    margin: 6.4rem 0 8rem;
  }

  @media (${bp.min.md}) {
    margin: 6.4rem 0 0;
  }

  h2 {
    margin-bottom: var(--space-m);

    @media (${bp.max.xs_sm}) {
      font-weight: var(--font-weight-semi-bold);
      max-width: 30ch;
      margin: 0 auto var(--space-m);
    }

    @media (${bp.min.md}) {
      margin-bottom: var(--space-l);
    }
  }

  p {
    width: 100%;
    max-width: 70ch;
    margin: 0 auto;
    font-size: 2rem;
    padding: var(--space-xxxs);

    @media (${bp.min.xs_sm}) {
      padding: 0;
    }
  }
`;

const HeroTitle = styled.h1`
  font-family: var(--font-family-condensed);
  color: var(--color-neon-green);
  text-transform: uppercase;
  font-size: clamp(10.5rem, 16vw, 25.5rem);
  line-height: 0.75;
  letter-spacing: -0.2rem;
  padding: 0 var(--space-xs);
  margin-bottom: var(--space-m);

  @media (${bp.min.sm}) {
    letter-spacing: -0.3rem;
    padding: 0 var(--space-xl);
    margin-bottom: var(--space-s);
  }

  @media (${bp.min.md}) {
    letter-spacing: -0.5rem;
  }

  &:lang(zh-cn) {
    font-size: clamp(6rem, 12vw, 13rem);
    line-height: 1.1;
    letter-spacing: 0;
    text-transform: none;
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment VisionHero on PrismicVision {
    data {
      hero_title
      hero_subtitle
      hero_description {
        richText
      }
    }
  }
`;
