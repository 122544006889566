import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Image from './Image';

gsap.registerPlugin(ScrollTrigger);

const ImageShuffle = ({ imageArray, imageToShow }) => {
  const images = useRef([]);

  useEffect(() => {
    // Reverse stacking order of images to match list order
    images.current.forEach((image, i) => {
      const zIndex = image.attributes['data-z-index'].value;

      gsap.set(image, {
        zIndex: zIndex,
        attr: {
          'data-z-index': zIndex,
        },
      });
    });
  }, []);

  useEffect(() => {
    // Reset all images to baseline z-index and active state before animating
    //  the selected card
    images.current.forEach(image => {
      const zIndex = image.attributes['data-z-index'].value;

      gsap.set(image, {
        zIndex: zIndex - 1,
        attr: {
          'data-z-index': zIndex - 1,
          'data-active': 'false',
          transformOrigin: 'top right',
        },
      });
    });

    gsap.set(images.current[imageToShow], {
      zIndex: images.current.length,
      attr: {
        'data-z-index': images.current.length - 1,
        'data-active': 'true',
      },
    });

    gsap.fromTo(
      images.current[imageToShow],
      {
        rotation: 4,
        x: 100,
        y: -30,
      },
      {
        rotation: 0,
        x: 0,
        y: 0,
        duration: 0.75,
        ease: 'power3.out',
      }
    );
  }, [imageToShow]);

  return (
    <ImageWrapper>
      {/* Reverse the order of images through z-index */}
      {imageArray.map((mode, i) => (
        <div
          key={i}
          ref={el => (images.current[i] = el)}
          className="callout__image"
          data-z-index={(i - (imageArray.length - 1)) * -1}
          data-active={
            (i - (imageArray.length - 1)) * -1 === imageArray.length - 1
              ? 'true'
              : 'false'
          }
        >
          <Image image={mode.image} aspectRatio={4 / 5} borderRadius={5} />
        </div>
      ))}
    </ImageWrapper>
  );
};

export default ImageShuffle;

const ImageWrapper = styled.div`
  display: grid;

  .callout__image {
    position: relative;
    grid-area: 1 / 1;
    border-radius: 0.5rem;
    overflow: hidden;
  }
`;
