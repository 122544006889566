import React, { useState } from 'react';
import styled from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import EventsListView from './EventsListView';
import EventsMonthView from './EventsMonthView';

const Calendar = ({ events, locale }) => {
  const [currentView, setCurrentView] = useState('list');

  return (
    <CalendarWrapper>
      <ViewSwitcher>
        <button
          onClick={() => setCurrentView('list')}
          className={`view-button view-button--list ${
            currentView === 'list' ? 'view-button--active' : ''
          }`}
        >
          {createTranslation('List', locale)}
        </button>
        <button
          onClick={() => setCurrentView('month')}
          className={`view-button view-button--month ${
            currentView === 'month' ? 'view-button--active' : ''
          }`}
        >
          {createTranslation('Month', locale)}
        </button>
      </ViewSwitcher>

      <TransitionGroup component={null}>
        {currentView === 'list' && (
          <CSSTransition timeout={200} classNames="calendar-view-animation">
            <EventsListView events={events} locale={locale} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup component={null}>
        {currentView === 'month' && (
          <CSSTransition timeout={200} classNames="calendar-view-animation">
            <EventsMonthView events={events} locale={locale} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </CalendarWrapper>
  );
};

export default Calendar;

/**
 * Styled Components
 *
 */
const CalendarWrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: -4.8rem;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: var(--color-off-white);
    z-index: -1;
  }

  .calendar-view-animation-enter {
    opacity: 0;
  }

  .calendar-view-animation-enter-active {
    opacity: 1;
    transition: all 300ms var(--ease-cubic-in-out);
  }

  .calendar-view-animation-exit {
    opacity: 1;
  }

  .calendar-view-animation-exit-active {
    opacity: 0;
    transition: all 300ms var(--ease-cubic-in-out);
  }
`;

const ViewSwitcher = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: none;

  @media (${bp.min.sm}) {
    display: flex;
  }

  .view-button {
    padding: 0.4em 1em;
    cursor: pointer;
    outline: 0.1rem solid var(--color-neon-green);
    font-size: 1.6rem;
    min-width: 8rem;
    transition: all 100ms var(--ease-cubic-in-out);

    &--active {
      outline-color: var(--color-neon-green);
      background-color: var(--color-neon-green);
      z-index: 1;
    }

    &--list {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    &--month {
      border-radius: 0 0.4rem 0.4rem 0;
    }
  }
`;
