import React from 'react';
import styled from 'styled-components';
import Link from './Link';

import linkResolver from '../utilities/link-resolver';
import bp from '../assets/js/breakpoints';

const LanguageSelector = ({ activeDoc, displayLangCode = false }) => {
  if (!activeDoc) return null;
  return (
    <NavWrapper
      displayLangCode={displayLangCode}
      className={displayLangCode ? 'language-code' : 'language-name'}
    >
      {activeDoc.alternateLanguages
        .sort((a, b) => (a.lang !== b.lang ? (a.lang < b.lang ? -1 : 1) : 0))
        .map(altLang => {
          let language;

          if (altLang.lang === 'es-es') {
            language = displayLangCode ? 'ES' : 'Español';
          } else if (altLang.lang === 'zh-cn') {
            language = '中文';
          } else {
            language = displayLangCode ? 'EN' : 'English';
          }

          return (
            <NavItem displayLangCode={displayLangCode} key={altLang.lang}>
              <Link url={{ url: linkResolver(altLang), link_type: 'Document' }}>
                {language}
              </Link>
            </NavItem>
          );
        })}
    </NavWrapper>
  );
};

export default LanguageSelector;

/**
 * Styled Components
 *
 */
const NavWrapper = styled.ul`
  display: flex;
  align-items: center;
`;

const NavItem = styled.li`
  display: block;

  &:first-of-type:before {
    display: none;
  }

  &:before {
    content: '/';
    color: var(--color-white);
    position: relative;
    top: 0.3rem;
    font-size: 3rem;
    margin: 0 1.2rem 0 1.2rem;
  }

  ${NavWrapper}.language-code & {
    display: flex;
    flex: 1 0 auto;

    &:before {
      content: '';
      position: relative;
      top: 0.2rem;
      width: 0.1rem;
      height: 1.1rem;
      margin: 0 1rem 0 0.9rem;
      background-color: var(--color-black);
    }
  }

  a {
    font-size: ${p => (p.displayLangCode ? '1.3rem' : 'var(--space-m)')};
    line-height: 1.2;

    @media (${bp.min.lg}) {
      font-size: ${p => (p.displayLangCode ? '1.3rem' : '3rem')};
    }

    & + a {
      margin-left: 1.1rem;
    }
  }
`;
