const linkResolver = doc => {
  if (!doc) return;

  const properties = doc;
  const isHomepage = properties.type === 'homepage' || properties.isHomeLink;

  // Create path for homepage
  if (isHomepage) {
    return properties.lang === 'en-us'
      ? '/'
      : `/${properties.lang === 'es-es' ? 'es' : `${properties.lang}`}/`;
  }

  // Create paths for all pages that aren't the homepage or news/events/press
  if (
    !isHomepage &&
    properties.type !== 'event' &&
    properties.type !== 'news'
  ) {
    return properties.lang === 'en-us'
      ? `/${properties.uid}/`
      : properties.lang === 'es-es'
      ? `/es/${properties.uid}/`
      : `/${properties.lang}/${properties.uid}/`;
  }

  // Create paths for event pages
  if (properties.type === 'event') {
    return properties.lang === 'en-us'
      ? `/event/${properties.uid}/`
      : `${
          properties.lang === 'es-es'
            ? `/es/event/${properties.uid}/`
            : `/${properties.lang}/event/${properties.uid}/`
        }`;
  }

  // Create paths for news and press pages
  if (properties.type === 'news') {
    const path =
      properties.data && properties.data.post_type === 'Press'
        ? 'press'
        : 'news';

    return properties.lang === 'en-us'
      ? `/${path}/${properties.uid}/`
      : `${
          properties.lang === 'es-es'
            ? `/es/${path}/${properties.uid}/`
            : `/${properties.lang}/${path}/${properties.uid}/`
        }`;
  }

  return '/';
};

module.exports = linkResolver;
