import React, { useEffect, useRef } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { RichText } from 'prismic-reactjs';
import { Parallax } from 'react-scroll-parallax';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';
import { isBrowser, prefersReducedMotion } from '../assets/js/utilities';

import AmbientVideo from '../components/AmbientVideo';
import Button from '../components/Button';
import Image from '../components/Image';
import Heading from '../components/Heading';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText, ScrollTrigger);
}

const Intro = ({
  content: { title, subtitle, link_text, url, video },
  isMobile,
}) => {
  const introEl = useRef(null);
  const videoEl = useRef(null);
  const tl = useRef();
  const tl2 = useRef();

  useEffect(() => {
    const query = gsap.utils.selector(introEl);

    tl.current = gsap.timeline({
      scrollTrigger: { trigger: query('h2'), start: 'top 80%' },
    });
    const splitText = new SplitText(query('h2 p'), {
      type: 'lines',
    });

    tl.current
      .from(splitText.lines, {
        opacity: 0,
        yPercent: 40,
        duration: 0.7,
        stagger: 0.07,
        ease: 'power2.out',
      })
      .from(
        query('.subtitle, a'),
        {
          opacity: 0,
          duration: 0.7,
          stagger: 0.06,
          ease: 'power2.out',
        },
        '>-.7'
      );

    // Video cursor
    let highlight;
    const video = videoEl.current.querySelector('video');

    gsap.set(videoEl.current, {
      xPercent: -50,
      yPercent: -50,
    });

    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.1;

    const xSet = gsap.quickSetter(videoEl.current, 'x', 'px');
    const ySet = gsap.quickSetter(videoEl.current, 'y', 'px');

    gsap.ticker.add(() => {
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      xSet(pos.x);
      ySet(pos.y);
    });

    tl2.current = gsap.timeline({ paused: true });
    tl2.current.to(videoEl.current, {
      autoAlpha: 1,
      duration: 0.5,
      ease: 'power2.inOut',
    });

    function handleMouseEnter() {
      if (video === null) return;

      let playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise.then(_ => {
          video.play();
        });
      }

      tl2.current.play();
    }

    function handleMouseMove(e) {
      mouse.x = e.x;
      mouse.y = e.y;
    }

    function handleMouseLeave() {
      if (video === null) return;

      tl2.current.reverse();

      video.pause();
    }

    function handleClick() {
      if (video.paused) {
        let playPromise = video.play();

        if (playPromise !== undefined) {
          playPromise.then(_ => {
            video.play();
          });
        }
      } else {
        video.pause();
      }
    }

    if (introEl.current) {
      highlight = introEl.current.querySelector('.highlight');

      if (highlight) {
        highlight.addEventListener('mouseenter', handleMouseEnter, false);
        highlight.addEventListener('mousemove', handleMouseMove, false);
        highlight.addEventListener('click', handleClick, false);
        highlight.addEventListener('mouseleave', handleMouseLeave, false);
      }
    }

    return () => {
      if (highlight) {
        highlight.removeEventListener('mouseenter', handleMouseEnter, false);
        highlight.removeEventListener('click', handleClick, false);
        highlight.removeEventListener('mousemove', handleMouseMove, false);
        highlight.removeEventListener('mouseleave', handleMouseLeave, false);
      }

      tl.current.kill();
      tl2.current.kill();
    };
  }, []);

  return (
    <Col base={12} sm_md={10} xl={8} lgOffset={1}>
      <VideoContainer>
        {isMobile && <AmbientVideo src={video.url} />}
      </VideoContainer>

      <IntroWrapper ref={introEl}>
        <VideoCursor ref={videoEl}>
          {!isMobile && <video src={video.url} loop muted playsInline />}
        </VideoCursor>

        <Heading level="h2" size="l" content={title} />

        <Row middle="sm">
          <Col base={12} sm={8} md={6}>
            <div className="subtitle">{subtitle}</div>
          </Col>

          <Col>
            <Button link={url}>{link_text}</Button>
          </Col>
        </Row>
      </IntroWrapper>
    </Col>
  );
};

const SectionInfo = ({
  section: { name, neighborhood_1, neighborhood_2 },
  isMobile,
}) => {
  return (
    <div className="section__info">
      <Parallax disabled={isMobile || prefersReducedMotion} y={[-40, 10]}>
        <div className="section__heading">
          <Heading level="h3" size="m" content={name} />
        </div>

        <div className="section__neighborhood">
          {neighborhood_1}
          <span>→</span>
          {neighborhood_2}
        </div>
      </Parallax>
    </div>
  );
};

const VideoHover = ({ sections, sectionNumber, aspectRatio, isMobile }) => {
  const videoEl = useRef(null);

  function handleMouseEnter() {
    if (videoEl.current === null) return;

    videoEl.current.currentTime = 0;

    if (videoEl.current.paused) {
      videoEl.current.play();
    }
  }

  function handleMouseLeave() {
    if (videoEl.current === null) return;

    if (!videoEl.current.paused) {
      videoEl.current.pause();
    }
  }

  return (
    <MediaContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Image image={sections[sectionNumber].image} aspectRatio={aspectRatio} />

      {!isMobile && (
        <video
          ref={videoEl}
          src={sections[sectionNumber].video.url}
          poster={sections[sectionNumber].image.url}
          loop
          muted
          playsInline
          preload="none"
        />
      )}
    </MediaContainer>
  );
};

const HomepageVision = ({ content: { intro, overview, sections } }) => {
  const { text, link_text, url } = overview[0];
  const isMobile = isBrowser && window.innerWidth < 750;

  return (
    <VisionWrapper>
      <Row>
        <Intro content={intro[0]} isMobile={isMobile} />
      </Row>

      <SectionGrid>
        <div className="section section--1">
          <div>
            <VideoHover
              sections={sections}
              sectionNumber={0}
              aspectRatio={4 / 5}
              isMobile={isMobile}
            />

            <SectionInfo
              isMobile={isMobile}
              section={sections[0]}
              number="01"
            />
          </div>
        </div>

        <div className="section section--2">
          <div>
            <VideoHover
              sections={sections}
              sectionNumber={1}
              aspectRatio={16 / 9}
              isMobile={isMobile}
            />

            <SectionInfo section={sections[1]} number="02" />
          </div>
        </div>

        <div className="section section--3">
          <div>
            <VideoHover
              sections={sections}
              sectionNumber={2}
              aspectRatio={4 / 5}
              isMobile={isMobile}
            />

            <SectionInfo section={sections[2]} number="03" />
          </div>
        </div>

        <div className="overview">
          <Parallax disabled={isMobile || prefersReducedMotion} y={[-10, 25]}>
            <RichText render={text.richText} />
            <Link to={url.url} className="link-text">
              {link_text}
            </Link>
          </Parallax>
        </div>
      </SectionGrid>
    </VisionWrapper>
  );
};

export default HomepageVision;

/**
 * Styled Components
 *
 */
const VisionWrapper = styled.section`
  margin-bottom: 9.6rem;

  @media (${bp.min.sm_md}) {
    margin-bottom: 20rem;
  }
`;

const IntroWrapper = styled.div`
  position: relative;
  padding: var(--space-m) 0 4.8rem;

  @media (${bp.min.sm_md}) {
    padding: var(--space-xl) 0 20rem;
  }

  .heading-tag {
    margin-bottom: var(--space-s);

    @media (${bp.max.xs}) {
      font-size: 3.8rem;
    }

    @media (${bp.min.sm}) {
      margin-bottom: 5.6rem;
    }
  }

  .highlight {
    color: var(--color-neon-green);
    transition: color 200ms var(--ease-cubic-in-out);
    pointer-events: none;
    cursor: default;
    display: block;

    @media (${bp.min.sm}) {
      display: table;
      pointer-events: auto;
    }

    &:hover {
      color: var(--color-rail-park-green);
    }
  }

  .subtitle {
    font-size: 2rem;
    margin-bottom: var(--space-l);

    @media (${bp.min.sm}) {
      font-size: 3rem;
      margin-bottom: 0;
    }
  }

  .button-wrapper {
    padding: var(--space-xxs) var(--space-m);
    font-size: 1.5rem;
    line-height: 2.4rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    @media (${bp.min.sm}) {
      font-size: 1.7rem;
      padding: var(--space-xs) var(--space-l);
    }
  }
`;

const VideoContainer = styled.div`
  position: relative;
  border-radius: 0.4rem;
  overflow: hidden;

  @media (${bp.min.sm}) {
    display: none;
  }
`;

const VideoCursor = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-black);
  width: 40vw;
  pointer-events: none;
  z-index: -1;
  border-radius: 0.4rem;
  overflow: hidden;
  visibility: hidden;

  @media (${bp.min.sm}) {
    display: block;
  }

  &:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(9 / 16 * 100%);
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const MediaContainer = styled.div`
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 1;

  &:hover {
    video {
      opacity: 1;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    transition: opacity 400ms var(--ease-cubic-in-out);
    display: none;

    @media (${bp.min.xs}) {
      display: block;
    }
  }
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  gap: 3.2rem 0.8rem;
  align-items: end;

  @media (${bp.min.sm}) {
    gap: var(--space-m) var(--space-m);
    align-items: start;
  }

  .section {
    margin-top: var(--space-l);

    &,
    & > div:first-of-type {
      display: contents;
    }

    .section__heading {
      display: flex;
      align-items: flex-end;

      .heading-tag {
        font-size: clamp(2rem, 1.0588rem + 2.9412vw, 6rem);
      }
    }

    .section__number {
      font-size: 1.7rem;
      line-height: 1.2;
      margin-left: var(--space-xs);
      display: none;

      @media (${bp.min.xs_sm}) {
        display: block;
      }

      @media (${bp.min.sm_md}) {
        font-size: 3rem;
      }
    }

    .section__neighborhood {
      font-size: 1.3rem;
      margin-top: var(--space-xxs);
      white-space: nowrap;
      display: none;

      @media (${bp.min.xs_sm}) {
        display: block;
      }

      @media (${bp.min.sm_md}) {
        margin-top: var(--space-s);
      }

      span {
        margin: 0 0.4rem;
      }
    }
  }

  .section--1 {
    ${MediaContainer} {
      grid-column: 1 / 7;
      grid-row: 1 / 2;

      @media (${bp.min.xs_sm}) {
        grid-column: 1 / 5;
        grid-row: 1 / 3;
      }
    }

    .section__info {
      margin-top: var(--space-m);
      grid-column: 7 / -1;
      grid-row: 1 / 2;
      z-index: 10;

      @media (${bp.min.xs_sm}) {
        grid-column: 5 / 7;
        grid-row: 1 / 3;
      }

      @media (${bp.min.sm}) {
        margin-top: var(--space-xl);
      }

      @media (${bp.min.sm_md}) {
        margin-top: var(--space-xxl);
      }
    }
  }

  .section--2 {
    margin-top: var(--space-l);

    @media (${bp.min.xs_sm}) {
      display: flex;
      flex-direction: column;
      grid-column: 8 / -1;
      grid-row: 2 / 4;
    }

    ${MediaContainer} {
      grid-column: 5 / -1;
      grid-row: 2 / 3;
    }

    .section__info {
      margin-top: var(--space-s);
      grid-column: 1 / 5;
      grid-row: 2 / 3;

      @media (${bp.min.sm}) {
        margin-top: var(--space-l);
      }
    }
  }

  .section--3 {
    ${MediaContainer} {
      grid-column: 1 / 7;
      grid-row: 3 / 4;

      @media (${bp.min.xs_sm}) {
        grid-column: 4 / 7;
        grid-row: 4 / 5;
      }

      @media (${bp.min.md}) {
        grid-column: 5 / 7;
      }
    }

    .section__info {
      margin-top: auto;
      grid-column: 7 / -1;
      grid-row: 3 / 4;

      @media (${bp.min.xs_sm}) {
        grid-column: 7 / 9;
        grid-row: 4 / 5;
      }
    }
  }

  .overview {
    grid-column: 1 / -1;
    padding: var(--space-s) 0;

    @media (${bp.min.xs_sm}) {
      grid-column: 1 / 8;
      padding: var(--space-xl) var(--space-l) var(--space-xl) 0;
    }

    @media (${bp.min.md}) {
      grid-column: 2 / 8;
      padding: var(--space-xxl) var(--space-l) var(--space-xxl) 0;
    }

    @media (${bp.min.lg}) {
      grid-column: 2 / 7;
    }

    p {
      font-size: 2rem;
      line-height: 1.5;

      @media (${bp.min.xs_sm}) {
        font-size: 1.7rem;
        line-height: 1.6;
      }

      @media (${bp.min.sm_md}) {
        font-size: 2rem;
        line-height: 1.5;
      }

      @media (${bp.min.md}) {
        font-size: 3rem;
        line-height: 1.4;
      }
    }

    .link-text {
      --margin-top: var(--space-l);
      margin-top: var(--margin-top);
      display: inline-block;
      color: var(--color-black);
      font-size: 2rem;
      transition: color 200ms var(--ease-quart-in-out);

      @media (${bp.min.xs_sm}) {
        font-size: 1.7rem;
      }

      @media (${bp.min.sm_md}) {
        --margin-top: 4rem;
        font-size: 2rem;
      }

      @media (${bp.min.md}) {
        --margin-top: 4.8rem;
      }

      @media (hover: hover) {
        &:hover {
          color: var(--color-neon-green);

          &:after {
            transform: translateX(0.4rem);
          }
        }
      }

      &:after {
        content: '→';
        display: inline-block;
        margin-left: var(--space-s);
        transition: transform 200ms var(--ease-quart-in-out);
      }
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment HomepageVision on PrismicHomepage {
    data {
      intro {
        title {
          richText
        }
        subtitle
        link_text
        url {
          url
          link_type
        }
        video {
          url
        }
      }
      overview {
        text {
          richText
        }
        link_text
        url {
          url
          link_type
        }
      }
      sections {
        name
        neighborhood_1
        neighborhood_2
        image {
          gatsbyImageData(width: 700)
          alt
        }
        video {
          url
        }
      }
    }
  }
`;
