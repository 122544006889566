import React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';

import { linkResolver } from './src/utilities/link-resolver';
import { prismicPreviewPages } from './src/assets/js/utilities';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO,
        linkResolver: doc => linkResolver(doc),
        componentResolver: componentResolverFromMap(prismicPreviewPages),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
