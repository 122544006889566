import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import imageFallback from '../assets/images/image-fallback.jpg';

function Seo({ title, description, image, lang, defaultMeta, isHome, script }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  // If no title supplied, fallback to Layout meta. If no Layout meta,
  //  fallback to gatsby-config/local meta
  const metaTitle = title || defaultMeta?.site_title || site.siteMetadata.title;
  const metaDescription =
    description ||
    defaultMeta?.site_description ||
    site.siteMetadata.description;
  const metaImage = image || defaultMeta?.site_image.url || imageFallback;

  const gTag = [
    {
      type: 'text/javascript',
      src: 'https://www.googletagmanager.com/gtag/js?id=G-YT6ND86Z93',
    },
    {
      type: 'text/javascript',
      innerHTML: `
        window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-YT6ND86Z93');
      `,
    },
  ];

  const combinedScripts = script ? gTag.concat(script) : gTag;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultMeta?.site_title}
      titleTemplate={!isHome ? `${metaTitle} | %s` : metaTitle}
      script={combinedScripts}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: ``,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default Seo;
