import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { getActiveDocLanguage, getSiteMeta } from '../assets/js/utilities';
import { Col, Row } from '../components/grid-system';
import linkResolver from '../utilities/link-resolver';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import SliceZone from '../components/SliceZone';
import Heading from '../components/Heading';
import AnimateFadeIn from '../components/AnimateFadeIn';

const Basic = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;

  // Get page data
  const basic = data.prismicBasic;
  const { title, body, meta_description, meta_image } = basic.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(basic);
  const defaultMeta = getSiteMeta(layout.data);

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data}>
      <Seo
        title={title}
        description={meta_description}
        image={meta_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
        script={[
          {
            src: '//embed.typeform.com/next/embed.js',
            type: 'text/javascript',
          },
        ]}
      />

      <AnimateFadeIn>
        <Row>
          <Col
            base={12}
            sm={10}
            md={8}
            xl={6}
            smOffset={1}
            mdOffset={2}
            xlOffset={3}
          >
            <BasicWrapper>
              <Heading level="h1" size="l" content={title} />
              <SliceZone slices={body} />
            </BasicWrapper>
          </Col>
        </Row>
      </AnimateFadeIn>
    </Layout>
  );
};

export default withPrismicPreview(Basic, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const BasicWrapper = styled.section`
  margin: 9.6rem 0 var(--space-xxxl);
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($uid: String, $language: String) {
    prismicBasic(uid: { eq: $uid }, lang: { eq: $language }) {
      _previewable
      data {
        title
        body {
          __typename
          ... on PrismicBasicDataBodyText {
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
          ... on PrismicBasicDataBodyLargeImage {
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(width: 1080)
              }
            }
          }
          ... on PrismicBasicDataBodyEmbed {
            slice_type
            primary {
              embed {
                text
              }
            }
          }
          ... on PrismicBasicDataBodyYoutube {
            slice_type
            primary {
              youtube_id
            }
          }
          ... on PrismicBasicDataBodyTwoColumnText {
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
          ... on PrismicBasicDataBodyThreeColumnText {
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
        }
        meta_description
        meta_image {
          url
        }
      }
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
  }
`;
