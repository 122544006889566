import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { RichText } from 'prismic-reactjs';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';
import { getActiveDocLanguage, getSiteMeta } from '../assets/js/utilities';
import linkResolver from '../utilities/link-resolver';

import Seo from '../components/Seo';
import Image from '../components/Image';
import AmbientVideo from '../components/AmbientVideo';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import DonationLevels from '../components/DonationLevels';
import CustomDonation from '../components/CustomDonation';
import AnimateFadeIn from '../components/AnimateFadeIn';

const Donate = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;

  // Get page data
  const donate = data.prismicDonate;
  const {
    title,
    hero_title,
    hero_subtitle,
    hero_button_text,
    hero_video,
    hero_video_poster,
    donations_title,
    donations_description,
    donation_levels,
    membership_title,
    membership_description,
    membership_levels,
    membership_footer_text,
    custom_title,
    custom_description,
    email_address,
    meta_description,
    meta_image,
  } = donate.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(donate);
  const defaultMeta = getSiteMeta(layout.data);

  const heroRef = useRef(null);
  const tl = useRef();

  useEffect(() => {
    const query = gsap.utils.selector(heroRef);

    tl.current = gsap.timeline({ defaults: { ease: 'cubic.inOut' } });

    tl.current
      .to(heroRef.current, {
        autoAlpha: 1,
        duration: 0.5,
        delay: 0.3,
      })
      .from(
        query('.heading-tag'),
        {
          opacity: 0,
          x: -32,
          duration: 0.5,
        },
        '>-0.2'
      )
      .from(
        query('p'),
        {
          opacity: 0,
          y: 24,
          duration: 0.4,
        },
        '>-0.4'
      )
      .from(
        query('.hero__button'),
        {
          opacity: 0,
          y: 24,
          duration: 0.5,
        },
        '>-0.35'
      )
      .timeScale(0.8);

    return () => {
      tl.current.kill();
    };
  }, []);

  const donationPluginIds = {
    donationsMobile: [
      'eaa643f7-ca32-4cb2-a733-56d9d07ac56c',
      'd325463b-83b9-4ef8-bc35-5a8815948fde',
      '5bc1e6d8-5c0f-4595-816a-1e3909978d49',
    ],
    donationsDesktop: [
      '89cfe2bf-6f30-4811-b206-16a76b117538',
      '99db3dcb-cefc-4e8f-8467-75a8acace7d9',
      'd023ed59-6dde-4b1e-b9e5-bc12a010cb17',
    ],
    memberships: [
      'a7f16326-ec55-403e-be83-ed3d9147aec3',
      'ad6bd347-21cc-4b77-9988-9f90b0c46fa4',
      '38f3480d-388d-4e8c-9b9f-0a55714cc947',
    ],
  };

  return (
    <Layout
      activeDoc={activeDoc}
      layoutData={layout.data}
      variant="fullscreen"
      hideOverflowX
    >
      <Seo
        title={title}
        description={meta_description}
        image={meta_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
        script={[
          {
            src: 'https://therailpark.kindful.com/javascripts/jquery.js',
            type: 'text/javascript',
          },
          {
            src: `https://therailpark.kindful.com/embeds/${donationPluginIds.memberships[0]}/init.js`,
            'data-embed-id': `${donationPluginIds.memberships[0]}`,
            'data-lookup-type': 'jquery-selector',
            'data-lookup-value': `#kindful-donate-btn-${donationPluginIds.memberships[0]}`,
            'data-styles-off': 'true',
          },
          {
            src: `https://therailpark.kindful.com/embeds/${donationPluginIds.memberships[1]}/init.js`,
            'data-embed-id': `${donationPluginIds.memberships[1]}`,
            'data-lookup-type': 'jquery-selector',
            'data-lookup-value': `#kindful-donate-btn-${donationPluginIds.memberships[1]}`,
            'data-styles-off': 'true',
          },
          {
            src: `https://therailpark.kindful.com/embeds/${donationPluginIds.memberships[2]}/init.js`,
            'data-embed-id': `${donationPluginIds.memberships[2]}`,
            'data-lookup-type': 'jquery-selector',
            'data-lookup-value': `#kindful-donate-btn-${donationPluginIds.memberships[2]}`,
            'data-styles-off': 'true',
          },
          {
            src: `https://therailpark.kindful.com/embeds/${donationPluginIds.donationsDesktop[0]}/init.js`,
            'data-embed-id': `${donationPluginIds.donationsDesktop[0]}`,
            'data-lookup-type': 'jquery-selector',
            'data-lookup-value': `#kindful-donate-btn-${donationPluginIds.donationsDesktop[0]}`,
            'data-styles-off': 'true',
          },
          {
            src: `https://therailpark.kindful.com/embeds/${donationPluginIds.donationsDesktop[1]}/init.js`,
            'data-embed-id': `${donationPluginIds.donationsDesktop[1]}`,
            'data-lookup-type': 'jquery-selector',
            'data-lookup-value': `#kindful-donate-btn-${donationPluginIds.donationsDesktop[1]}`,
            'data-styles-off': 'true',
          },
          {
            src: `https://therailpark.kindful.com/embeds/${donationPluginIds.donationsDesktop[2]}/init.js`,
            'data-embed-id': `${donationPluginIds.donationsDesktop[2]}`,
            'data-lookup-type': 'jquery-selector',
            'data-lookup-value': `#kindful-donate-btn-${donationPluginIds.donationsDesktop[2]}`,
            'data-styles-off': 'true',
          },
          {
            src: `https://therailpark.kindful.com/embeds/${donationPluginIds.donationsMobile[0]}/init.js`,
            'data-embed-id': `${donationPluginIds.donationsMobile[0]}`,
            'data-lookup-type': 'jquery-selector',
            'data-lookup-value': `#kindful-donate-btn-${donationPluginIds.donationsMobile[0]}`,
            'data-styles-off': 'true',
          },
          {
            src: `https://therailpark.kindful.com/embeds/${donationPluginIds.donationsMobile[1]}/init.js`,
            'data-embed-id': `${donationPluginIds.donationsMobile[1]}`,
            'data-lookup-type': 'jquery-selector',
            'data-lookup-value': `#kindful-donate-btn-${donationPluginIds.donationsMobile[1]}`,
            'data-styles-off': 'true',
          },
          {
            src: `https://therailpark.kindful.com/embeds/${donationPluginIds.donationsMobile[2]}/init.js`,
            'data-embed-id': `${donationPluginIds.donationsMobile[2]}`,
            'data-lookup-type': 'jquery-selector',
            'data-lookup-value': `#kindful-donate-btn-${donationPluginIds.donationsMobile[2]}`,
            'data-styles-off': 'true',
          },
        ]}
      />

      <AnimateFadeIn>
        <HeroVideo ref={heroRef}>
          <AmbientVideo
            src={hero_video.url}
            poster={hero_video_poster.url}
            loop
            autoPlay
            muted
          />

          <Row className="hero__content">
            <Col base={10} xs_sm={9} sm={8} md={7} lg={6} smOffset={1}>
              <div>
                <Heading level="h1" size="l" content={hero_title} />
                <p>{hero_subtitle}</p>
                <a href="#donations" className="hero__button">
                  {hero_button_text}
                </a>
              </div>
            </Col>
          </Row>
        </HeroVideo>

        <DonationLevels
          content={{ donations_title, donations_description, donation_levels }}
          lang={activeDoc.locale}
          donationPluginIds={donationPluginIds}
        />

        <MembershipSection>
          <div id="membership" />

          <Heading level="h2" size="m" content={membership_title} />
          <div
            className="membership__description"
            dangerouslySetInnerHTML={{
              __html: membership_description.html,
            }}
          />

          <MembershipLevels>
            {membership_levels.map((level, i) => {
              return (
                <a
                  id={`kindful-donate-btn-${donationPluginIds.memberships[i]}`}
                  href="#!"
                  className="level kindful-donate-btn"
                  key={level.name}
                >
                  <Image image={level.image} />

                  <Heading level="h3" size="m" content={level.name} />

                  <RichText render={level.description.richText} />
                  <div className="level__button">{level.button_text}</div>
                </a>
              );
            })}
          </MembershipLevels>

          <div
            className="membership__footer"
            dangerouslySetInnerHTML={{
              __html: membership_footer_text.html,
            }}
          ></div>
        </MembershipSection>

        <CustomDonation
          content={{ custom_title, custom_description, email_address }}
          lang={activeDoc.locale}
        />
      </AnimateFadeIn>
    </Layout>
  );
};

export default withPrismicPreview(Donate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const HeroVideo = styled.section`
  width: 100vw;
  height: 95vh;
  min-height: 70rem;
  max-height: 100rem;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  visibility: hidden;
  opacity: 0;

  &:after {
    content: '';
    background-image: linear-gradient(
      to bottom,
      hsl(0, 0%, 0%) 0%,
      hsla(0, 0%, 0%, 0.94) 10.8%,
      hsla(0, 0%, 0%, 0.878) 19.9%,
      hsla(0, 0%, 0%, 0.812) 27.5%,
      hsla(0, 0%, 0%, 0.745) 33.9%,
      hsla(0, 0%, 0%, 0.676) 39.3%,
      hsla(0, 0%, 0%, 0.606) 44%,
      hsla(0, 0%, 0%, 0.535) 48.3%,
      hsla(0, 0%, 0%, 0.465) 52.5%,
      hsla(0, 0%, 0%, 0.394) 56.7%,
      hsla(0, 0%, 0%, 0.324) 61.4%,
      hsla(0, 0%, 0%, 0.255) 66.7%,
      hsla(0, 0%, 0%, 0.188) 73%,
      hsla(0, 0%, 0%, 0.122) 80.4%,
      hsla(0, 0%, 0%, 0.06) 89.4%,
      hsla(0, 0%, 0%, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    opacity: 0.6;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
    height: 12rem;
    z-index: 1;
  }

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color-black);
      z-index: 1;
      opacity: 0.3;
    }
  }

  .hero__content {
    color: var(--color-white);
    margin: 0 var(--space-m) 4.8rem;
    width: 100%;
    align-items: flex-end;
    z-index: 1;

    @media (${bp.min.xs_sm}) {
      margin: 0 4.8rem;
      align-items: center;
    }

    p {
      max-width: 32ch;
      font-size: 2rem;
      line-height: 1.3;
      margin: var(--space-s) 0 var(--space-xxs);

      @media (${bp.min.md}) {
        margin: var(--space-s) 0 var(--space-l);
        font-size: 3rem;
      }
    }
  }

  .hero__button {
    margin-top: var(--space-m);
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    background-color: var(--color-neon-green);
    color: var(--color-white);
    padding: var(--space-s) 4.8rem;
    border-radius: 4rem;
    font-size: 1.7rem;
    line-height: 1;
    letter-spacing: 0.09rem;
    cursor: pointer;
    transition: background-color 200ms var(--ease-quart-in-out);

    &:hover {
      background-color: var(--color-neon-green-light);
    }
  }
`;

const MembershipSection = styled.section`
  background-color: var(--color-white);
  border: 0.1rem solid #e1dfdf;
  padding: var(--space-l) var(--space-m);
  border-radius: 1rem;
  margin: var(--space-xl) 0 9.6rem;
  text-align: center;

  @media (${bp.min.sm_md}) {
    padding: var(--space-xxl) 0 5.6rem;
  }

  .membership__description {
    max-width: 50ch;
    margin: var(--space-l) auto var(--space-xxl);

    @media (${bp.min.sm}) {
      margin: var(--space-l) auto var(--space-xxl);
      font-size: 2rem;
    }

    p {
      line-height: 1.3;
      font-size: 1.7rem;

      @media (${bp.min.sm}) {
        font-size: 2rem;
      }
    }
  }

  a:not(.level) {
    color: var(--color-neon-green);

    &:hover {
      color: var(--color-black);
    }
  }

  #membership {
    height: var(--header-height-mobile);
    margin-top: calc(var(--header-height-mobile) * -1);

    @media (${bp.min.sm}) {
      height: var(--header-height-desktop);
      margin-top: calc(var(--header-height-desktop) * -1);
    }
  }

  .membership__footer {
    font-size: 1.5rem;
    line-height: 1.4;
    max-width: 65ch;
    margin: 0 auto;

    @media (${bp.min.sm_md}) {
      margin: var(--space-xxl) auto 0;
    }

    .link-with-arrow {
      color: var(--color-neon-green);

      &:hover {
        color: var(--color-black);

        &:after {
          transform: translateX(0.2rem);
        }
      }

      &:after {
        content: '→';
        display: inline-block;
        margin-left: var(--space-xxxs);
        transition: all 150ms var(--ease-quart-in-out);
        color: currentColor;
      }
    }
  }
`;

const MembershipLevels = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (${bp.min.sm_md}) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .level {
    width: 100%;
    cursor: pointer;
    margin-bottom: 5.6rem;
    padding-bottom: 5.6rem;
    border-bottom: 0.1rem solid rgba(49, 49, 49, 0.1);

    @media (${bp.min.sm_md}) {
      max-width: 30rem;
      margin: 0 var(--space-s);
      padding-bottom: 0;
      border-bottom: none;
    }

    @media (${bp.min.md}) {
      margin: 0 var(--space-xl);
    }

    &:hover {
      color: var(--color-black);

      .level__button {
        background-color: var(--color-neon-green);
        color: var(--color-white);
        border-color: var(--color-neon-green);
      }

      .image img {
        transform: scale(1.1);
      }
    }

    br {
      @media (${bp.min.xs_sm}) {
        display: none;
      }

      @media (${bp.min.sm_md}) {
        display: block;
      }
    }

    .image-wrapper {
      margin-bottom: var(--space-l);

      .image {
        max-width: 14.8rem;
        border-radius: 10rem;
        overflow: hidden;
        z-index: 1;

        img {
          transition: all 300ms var(--ease-cubic-in-out);
        }
      }
    }

    .heading-tag {
      margin-bottom: var(--space-xs);
    }

    .level__button {
      margin-top: var(--space-m);
      display: inline-flex;
      align-items: center;
      border: 0.1rem solid var(--color-black);
      padding: var(--space-xs) var(--space-m);
      border-radius: 4rem;
      font-size: 1.5rem;
      line-height: 1;
      letter-spacing: 0.05rem;
      cursor: pointer;
      transition: all 200ms var(--ease-quart-in-out);
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($language: String) {
    prismicDonate(lang: { eq: $language }) {
      _previewable
      data {
        title
        hero_title
        hero_subtitle
        hero_button_text
        hero_video {
          url
        }
        hero_video_poster {
          url
        }
        membership_title
        membership_description {
          richText
          html
        }
        membership_levels {
          name
          description {
            richText
          }
          button_text
          image {
            alt
            gatsbyImageData(width: 200)
          }
        }
        membership_footer_text {
          richText
          html
        }
        meta_description
        meta_image {
          url
        }
      }
      ...DonationLevels
      ...CustomDonations
      uid
      url
      type
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
  }
`;
