import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { getActiveDocLanguage, getSiteMeta } from '../assets/js/utilities';
import bp from '../assets/js/breakpoints';
import { Col, Row } from '../components/grid-system';
import linkResolver from '../utilities/link-resolver';

import Layout from '../components/Layout';
import Image from '../components/Image';
import Seo from '../components/Seo';
import NewsEventsCallout from '../components/NewsEventsCallout';
import SliceZone from '../components/SliceZone';
import Heading from '../components/Heading';
import AnimateFadeIn from '../components/AnimateFadeIn';

const News = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;
  const news = data.allPrismicNews;
  const events = data.allPrismicEvent;

  // Get page data
  const newsPress = data.prismicNews;
  const { title, publish_date, featured_image, body } = newsPress.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(newsPress);
  const defaultMeta = getSiteMeta(layout.data);

  const metaDescription = body.find(element => element.slice_type === 'text')
    ?.primary.text.richText[0].text;

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data}>
      <Seo
        title={title}
        description={metaDescription ? metaDescription : null}
        image={featured_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
      />

      <AnimateFadeIn>
        {featured_image.gatsbyImageData && (
          <ImageWrapper>
            <Image
              image={featured_image}
              aspectRatio={16 / 9}
              borderRadius={5}
            />
          </ImageWrapper>
        )}

        <Row>
          <Col
            base={12}
            sm={10}
            md={8}
            xl={6}
            smOffset={1}
            mdOffset={2}
            xlOffset={3}
          >
            <ArticleWrapper>
              <div className="article__date">
                {publish_date ? publish_date : newsPress.first_publication_date}
              </div>
              <Heading level="h1" size="l" content={title} />

              {body.length > 0 && (
                <div>
                  <SliceZone slices={body} />
                </div>
              )}
            </ArticleWrapper>
          </Col>
        </Row>

        <NewsEventsCallout
          news={news}
          events={events}
          lang={activeDoc.locale}
        />
      </AnimateFadeIn>
    </Layout>
  );
};

export default withPrismicPreview(News, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const ImageWrapper = styled.div`
  margin-top: var(--space-m);

  @media (${bp.min.xs_sm}) {
    margin-top: 0;
  }
`;

const ArticleWrapper = styled.section`
  margin: var(--space-xl) 0;

  @media (${bp.min.sm}) {
    margin: 7.2rem 0 var(--space-xxl);
  }

  @media (${bp.min.lg}) {
    margin: 9.6rem 0 var(--space-xxxl);
  }

  .article__date {
    font-size: clamp(1.5rem, 3.5vw, 3rem);
    line-height: 1.4;
  }

  .article__date {
    margin-bottom: var(--space-xxs);
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($uid: String, $language: String) {
    prismicNews(uid: { eq: $uid }, lang: { eq: $language }) {
      _previewable
      first_publication_date(
        formatString: "dddd, MMMM DD, YYYY"
        locale: $language
      )
      data {
        title
        publish_date(formatString: "dddd, MMMM DD, YYYY", locale: $language)
        post_type
        featured_image {
          alt
          gatsbyImageData(width: 1680)
          url
        }
        body {
          ... on PrismicNewsDataBodyText {
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
          ... on PrismicNewsDataBodyLargeImage {
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(width: 1080)
              }
            }
          }
          ... on PrismicNewsDataBodyEmbed {
            slice_type
            primary {
              embed {
                text
              }
            }
          }
          ... on PrismicNewsDataBodyYoutube {
            slice_type
            primary {
              youtube_id
            }
          }
          ... on PrismicNewsDataBodyTwoColumnText {
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
          ... on PrismicNewsDataBodyThreeColumnText {
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
        }
      }
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
    allPrismicNews(
      filter: { lang: { eq: $language }, data: { post_type: { eq: "News" } } }
    ) {
      edges {
        node {
          ...NewsItems
        }
      }
    }
    allPrismicEvent(filter: { lang: { eq: $language } }) {
      edges {
        node {
          ...EventItems
        }
      }
    }
  }
`;
