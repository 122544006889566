import styled, { css } from 'styled-components';

import config, { DIMENSION_NAMES } from './config';
import bp from '../../assets/js/breakpoints';

const Grid = styled.div`
  width: 100%;
  max-width: ${p => config(p).maxWidth}rem;
  height: 100%;
  flex: 1 1 auto;
  margin-right: auto;
  margin-left: auto;
  padding-right: ${p => config(p).outerMargin.mobile}rem;
  padding-left: ${p => config(p).outerMargin.mobile}rem;

  ${p =>
    !p.fluid &&
    css`
      ${DIMENSION_NAMES.map(
        t =>
          config(p).container[t] &&
          config(p).media[t]`
        width: ${p => config(p).container[t]}rem;
      `
      )}
    `}

  @media (${bp.min.sm_md}) {
    padding-right: ${p => config(p).outerMargin.desktop}rem;
    padding-left: ${p => config(p).outerMargin.desktop}rem;
  }
`;

Grid.displayName = 'Grid';

export default Grid;
