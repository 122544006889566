import React, { useState } from 'react';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';
import { createTranslation, getFormattedEvents } from '../assets/js/utilities';

import EventDetails from './EventDetails';
import Image from './Image';
import Link from './Link';

const EventItem = ({ event, locale }) => {
  const { featured_image, thumbnail } = event.data;

  return (
    <EventItemWrapper>
      <Link url={{ url: event.url, link_type: 'Document' }}>
        <Row>
          <Col base={12} sm={6} sm_md={7}>
            <EventDetails
              event={event.data}
              locale={locale}
              variant={'list-view'}
            />
          </Col>

          <Col base={12} sm={6} sm_md={5} baseOrder={-1} smOrder={1}>
            {!!featured_image.gatsbyImageData ? (
              <Image image={featured_image} aspectRatio={16 / 9} />
            ) : !!thumbnail.gatsbyImageData ? (
              <Image image={thumbnail} aspectRatio={16 / 9} />
            ) : null}
          </Col>
        </Row>
      </Link>
    </EventItemWrapper>
  );
};

const EventsListView = ({ events, locale }) => {
  const [maxLimit, setMaxLimit] = useState(5);

  const formattedEvents = getFormattedEvents(events, locale);

  const eventFeed = formattedEvents
    .filter(event => {
      return !event.data.isPastEvent;
    })
    .sort((a, b) => {
      return new Date(a.data.start) - new Date(b.data.start);
    });

  const truncatedEventFeed = eventFeed.slice(0, maxLimit);

  function handleClick() {
    setMaxLimit(maxLimit => maxLimit + maxLimit);
  }

  return (
    <ListViewWrapper>
      <TransitionGroup component={null}>
        {truncatedEventFeed.map((event, i) => (
          <CSSTransition key={i} timeout={200} classNames="load-more-animation">
            <EventItem event={event} locale={locale} key={i} />
          </CSSTransition>
        ))}
      </TransitionGroup>

      {maxLimit < eventFeed.length && (
        <LoadMore onClick={handleClick}>
          {createTranslation('Load more', locale)}
        </LoadMore>
      )}
    </ListViewWrapper>
  );
};

export default EventsListView;

/**
 * Styled Components
 *
 */
const ListViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0;
  margin-bottom: var(--space-xxl);

  @media (${bp.min.sm}) {
    padding-top: var(--space-xl);
    margin-bottom: var(--space-xxxl);
  }

  .load-more-animation-enter {
    opacity: 0;
    transform: translateY(1.6rem);
  }

  .load-more-animation-enter-active {
    opacity: 1;
    transform: translateY(0);

    transition: all 300ms var(--ease-cubic-in-out);
  }

  .load-more-animation-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .load-more-animation-exit-active {
    opacity: 0;
    transform: translateY(1.6rem);
    transition: all 300ms var(--ease-cubic-in-out);
  }
`;

const EventItemWrapper = styled.div`
  display: block;
  margin-top: var(--space-xl);

  @media (hover: hover) {
    a:hover {
      color: var(--color-black);

      .heading-tag {
        color: var(--color-neon-green);
      }
    }
  }

  & + & {
    padding-top: var(--space-xl);
    border-top: 0.1rem solid #e1dfdf;
  }

  .heading-tag {
    transition: color 200ms var(--ease-cubic-in-out);
  }

  .image-wrapper {
    margin-bottom: var(--space-l);

    @media (${bp.min.sm}) {
      margin-bottom: 0;
    }
  }

  ${Row} {
    min-width: 100%;
  }
`;

const LoadMore = styled.button`
  max-width: fit-content;
  align-self: center;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  border: 0.1rem solid var(--color-black);
  border-radius: 10rem;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  padding: var(--space-xs) var(--space-m);
  margin-top: var(--space-xl);
  transition: all 200ms var(--ease-quart-in-out);

  @media (${bp.min.xs_sm}) {
    font-size: 1.7rem;
    padding: var(--space-s) var(--space-xl);
    margin-top: var(--space-xl);
  }

  &:hover {
    color: var(--color-white);
    background-color: var(--color-neon-green);
    border-color: var(--color-neon-green);
  }
`;
