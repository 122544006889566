import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import ArrowIcon from './ArrowIcon';

const SwipeIndicator = ({ isDarkMode, lang }) => {
  const swipeIndicator = useRef(null);

  useEffect(() => {
    gsap.to(swipeIndicator.current, {
      autoAlpha: 1,
      duration: 0.4,
      delay: 0.2,
      ease: 'cubic.inOut',
    });
  }, []);

  return (
    <CalloutWrapper
      isDarkMode={isDarkMode}
      className="swipe-callout"
      ref={swipeIndicator}
    >
      <ArrowIcon rotation={270} width={8} />
      <span>{createTranslation('Swipe', lang)}</span>
      <ArrowIcon rotation={90} width={8} />
    </CalloutWrapper>
  );
};

export default SwipeIndicator;

/**
 * Styled Components
 *
 */
const CalloutWrapper = styled.div`
  color: ${props =>
    props.isDarkMode ? 'var(--color-white);' : 'var(--color-black)'};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  opacity: 0;
  visibility: hidden;

  .arrow-icon {
    stroke: currentColor;
  }

  @media (${bp.min.sm}) {
    margin: var(--space-s) auto;
  }

  @media (${bp.min.sm_md}) {
    margin: var(--space-s) auto;
    display: none;
  }

  span {
    font-family: var(--font-family-sans);
    font-weight: var(--font-weight-semi-bold);
    margin: 0 var(--space-xxs);
    font-size: 1.2rem;
    letter-spacing: -0.05rem;
    text-align: center;
  }
`;
