import React from 'react';

import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';

const Menu = ({ content, activeDoc, menuIsOpen, toggleMenuIsOpen }) => {
  return (
    <>
      <MenuDesktop
        content={content}
        lang={activeDoc.locale}
        menuIsOpen={menuIsOpen}
        toggleMenuIsOpen={toggleMenuIsOpen}
      />
      <MenuMobile
        content={content}
        activeDoc={activeDoc}
        menuIsOpen={menuIsOpen}
        toggleMenuIsOpen={toggleMenuIsOpen}
      />
    </>
  );
};

export default Menu;
