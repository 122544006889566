import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import FocusTrap from 'focus-trap-react';
import { RichText } from 'prismic-reactjs';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import Heading from '../components/Heading';
import Image from '../components/Image';
import VisitPOIAccordion from './VisitPOIAccordion';

gsap.registerPlugin(ScrollToPlugin);

const Map = ({ selectedItem, setSelectedItem, handleClick }) => (
  <MapWrapper>
    <StaticImage
      src="../assets/images/phase-one-map-bg.jpg"
      alt="Illustrated map of Phase One"
      className="image-wrapper"
      objectFit="contain"
      objectPosition="right"
    />
    <MapNumbers
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      handleClick={handleClick}
    />
  </MapWrapper>
);

const MapNumbers = ({ selectedItem, setSelectedItem, handleClick }) => (
  <svg
    width="1080"
    height="1512"
    viewBox="0 0 1080 1512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="one"
      onMouseEnter={() => setSelectedItem(0)}
      onMouseLeave={() => setSelectedItem(null)}
      onClick={() => handleClick(0)}
    >
      <path d="M553.672 244.368C549.584 244.368 545.588 243.155 542.189 240.882C538.79 238.61 536.14 235.379 534.576 231.6C533.011 227.82 532.602 223.661 533.4 219.649C534.197 215.637 536.166 211.951 539.056 209.058C541.947 206.166 545.63 204.196 549.64 203.397C553.649 202.599 557.805 203.009 561.582 204.574C565.359 206.14 568.587 208.791 570.858 212.193C573.13 215.594 574.342 219.593 574.342 223.684C574.334 229.167 572.154 234.424 568.279 238.301C564.405 242.179 559.152 244.36 553.672 244.368ZM553.672 204.142C549.801 204.137 546.016 205.28 542.795 207.428C539.574 209.577 537.063 212.633 535.579 216.211C534.095 219.788 533.705 223.726 534.458 227.525C535.212 231.324 537.075 234.815 539.812 237.553C542.55 240.292 546.037 242.157 549.834 242.911C553.631 243.666 557.566 243.275 561.141 241.79C564.716 240.305 567.77 237.792 569.917 234.569C572.063 231.346 573.206 227.558 573.2 223.684C573.192 218.504 571.132 213.538 567.472 209.875C563.811 206.212 558.849 204.15 553.672 204.142V204.142Z" />
      <circle
        cx="553.6"
        cy="223.7"
        r="20.8"
        style={{ fill: selectedItem === 0 ? 'var(--color-neon-green)' : '' }}
      />
      <path
        d="M555.355 215.296V232.132H552.351V220.107H548.625V217.942H548.986C549.835 218.028 550.685 217.782 551.357 217.255C552.029 216.728 552.471 215.962 552.591 215.116L555.355 215.296Z"
        style={{ fill: selectedItem === 0 ? 'var(--color-white)' : '' }}
      />
    </g>
    <g
      id="two"
      onMouseEnter={() => setSelectedItem(1)}
      onMouseLeave={() => setSelectedItem(null)}
      onClick={() => handleClick(1)}
    >
      <path d="M540.964 488.369C536.876 488.369 532.88 487.156 529.481 484.883C526.082 482.61 523.432 479.38 521.868 475.6C520.303 471.821 519.894 467.662 520.692 463.649C521.489 459.637 523.458 455.951 526.348 453.059C529.239 450.166 532.922 448.196 536.932 447.398C540.941 446.6 545.097 447.009 548.874 448.575C552.651 450.141 555.879 452.792 558.15 456.193C560.422 459.595 561.634 463.594 561.634 467.685C561.626 473.168 559.446 478.424 555.571 482.302C551.696 486.179 546.444 488.361 540.964 488.369ZM540.964 448.143C537.095 448.143 533.312 449.292 530.095 451.443C526.878 453.595 524.371 456.654 522.892 460.232C521.413 463.81 521.027 467.747 521.784 471.544C522.541 475.341 524.407 478.829 527.145 481.565C529.883 484.301 533.371 486.162 537.167 486.914C540.963 487.666 544.896 487.274 548.47 485.788C552.043 484.302 555.095 481.789 557.241 478.566C559.386 475.344 560.528 471.557 560.522 467.685C560.514 462.499 558.45 457.529 554.783 453.865C551.116 450.201 546.146 448.143 540.964 448.143V448.143Z" />
      <circle
        cx="540.9"
        cy="467.8"
        r="20.8"
        style={{ fill: selectedItem === 1 ? 'var(--color-neon-green)' : '' }}
      />
      <path
        d="M539.673 471.142C539.241 471.406 538.876 471.765 538.605 472.193C538.334 472.62 538.165 473.104 538.111 473.607H546.312V476.163H534.866V475.471C534.743 474.249 534.959 473.017 535.489 471.908C536.019 470.8 536.842 469.859 537.87 469.188L541.596 466.662C542.057 466.41 542.443 466.042 542.718 465.593C542.992 465.145 543.144 464.632 543.158 464.107C543.156 463.777 543.085 463.452 542.948 463.152C542.812 462.852 542.614 462.584 542.368 462.365C542.121 462.147 541.831 461.983 541.517 461.884C541.203 461.785 540.871 461.754 540.544 461.792C540.178 461.755 539.808 461.792 539.456 461.902C539.105 462.011 538.779 462.191 538.498 462.429C538.218 462.668 537.988 462.961 537.823 463.291C537.659 463.62 537.562 463.98 537.54 464.348L534.776 463.957C534.917 462.576 535.571 461.299 536.609 460.379C537.682 459.472 539.05 458.991 540.454 459.026C541.957 458.942 543.436 459.424 544.6 460.379C545.108 460.823 545.512 461.374 545.782 461.993C546.052 462.611 546.182 463.282 546.162 463.957C546.108 464.935 545.803 465.883 545.277 466.71C544.751 467.537 544.021 468.214 543.158 468.677L539.673 471.142Z"
        style={{ fill: selectedItem === 1 ? 'var(--color-white)' : '' }}
      />
    </g>
    <g
      id="three"
      onMouseEnter={() => setSelectedItem(2)}
      onMouseLeave={() => setSelectedItem(null)}
      onClick={() => handleClick(2)}
    >
      <path d="M642.33 723.079C638.242 723.079 634.246 721.866 630.847 719.594C627.448 717.321 624.798 714.09 623.234 710.311C621.67 706.531 621.26 702.372 622.058 698.36C622.855 694.348 624.824 690.662 627.715 687.769C630.605 684.877 634.288 682.907 638.298 682.109C642.307 681.311 646.463 681.72 650.24 683.286C654.017 684.851 657.245 687.502 659.517 690.904C661.788 694.305 663 698.304 663 702.395C663 705.112 662.466 707.801 661.427 710.311C660.388 712.82 658.865 715.1 656.946 717.021C655.027 718.942 652.748 720.465 650.24 721.505C647.733 722.544 645.045 723.079 642.33 723.079ZM642.33 682.854C638.461 682.854 634.678 684.002 631.461 686.154C628.244 688.306 625.738 691.365 624.258 694.943C622.779 698.521 622.393 702.457 623.15 706.255C623.907 710.052 625.773 713.539 628.511 716.275C631.25 719.011 634.737 720.873 638.533 721.625C642.329 722.376 646.263 721.985 649.836 720.499C653.409 719.013 656.462 716.499 658.607 713.277C660.752 710.054 661.895 706.267 661.889 702.395C661.881 697.21 659.816 692.239 656.149 688.575C652.482 684.912 647.512 682.854 642.33 682.854Z" />
      <circle
        cx="642.3"
        cy="702.4"
        r="20.8"
        style={{ fill: selectedItem === 2 ? 'var(--color-neon-green)' : '' }}
      />
      <path
        d="M647.919 706.183C647.945 706.869 647.812 707.552 647.53 708.178C647.249 708.803 646.826 709.356 646.296 709.791C645.085 710.723 643.587 711.201 642.06 711.144C640.552 711.194 639.078 710.692 637.914 709.731C637.363 709.268 636.91 708.7 636.58 708.06C636.249 707.42 636.05 706.721 635.992 706.003L638.545 705.612C638.568 706.039 638.676 706.457 638.863 706.841C639.051 707.225 639.313 707.568 639.636 707.848C639.958 708.129 640.333 708.342 640.74 708.474C641.146 708.606 641.575 708.656 642 708.619C642.377 708.661 642.759 708.623 643.12 708.508C643.482 708.393 643.815 708.203 644.098 707.951C644.382 707.698 644.609 707.389 644.765 707.043C644.921 706.697 645.003 706.322 645.004 705.943C645.004 704.47 643.683 703.478 641.7 703.478H640.768V701.133H641.429C643.382 701.133 644.704 700.231 644.704 698.908C644.706 698.533 644.625 698.163 644.467 697.823C644.31 697.483 644.08 697.182 643.793 696.941C643.506 696.7 643.17 696.525 642.808 696.429C642.447 696.332 642.068 696.316 641.7 696.382C641.281 696.34 640.857 696.387 640.458 696.519C640.058 696.651 639.69 696.866 639.378 697.149C639.067 697.433 638.818 697.779 638.649 698.164C638.479 698.55 638.393 698.967 638.395 699.389L635.841 698.998C636 697.586 636.687 696.286 637.764 695.36C638.926 694.463 640.352 693.977 641.82 693.977C643.288 693.977 644.714 694.463 645.876 695.36C646.388 695.821 646.793 696.387 647.063 697.021C647.333 697.655 647.461 698.34 647.438 699.028C647.447 699.779 647.224 700.515 646.799 701.134C646.374 701.753 645.768 702.225 645.065 702.486C645.875 702.712 646.59 703.194 647.105 703.861C647.619 704.527 647.904 705.342 647.919 706.183V706.183Z"
        style={{ fill: selectedItem === 2 ? 'var(--color-white)' : '' }}
      />
    </g>
    <g
      id="four"
      onMouseEnter={() => setSelectedItem(3)}
      onMouseLeave={() => setSelectedItem(null)}
      onClick={() => handleClick(3)}
    >
      <path d="M626.858 1013.47C622.77 1013.47 618.774 1012.26 615.375 1009.98C611.976 1007.71 609.326 1004.48 607.762 1000.7C606.197 996.921 605.788 992.762 606.586 988.75C607.383 984.737 609.352 981.052 612.243 978.159C615.133 975.266 618.816 973.296 622.826 972.498C626.835 971.7 630.991 972.11 634.768 973.675C638.545 975.241 641.773 977.892 644.044 981.293C646.316 984.695 647.528 988.694 647.528 992.785C647.52 998.268 645.34 1003.52 641.465 1007.4C637.591 1011.28 632.338 1013.46 626.858 1013.47V1013.47ZM626.858 973.243C622.997 973.249 619.225 974.4 616.017 976.551C612.81 978.702 610.311 981.756 608.838 985.327C607.365 988.899 606.982 992.827 607.739 996.616C608.496 1000.4 610.358 1003.88 613.09 1006.61C615.823 1009.34 619.302 1011.2 623.09 1011.95C626.877 1012.7 630.802 1012.32 634.368 1010.84C637.935 1009.36 640.983 1006.85 643.127 1003.64C645.272 1000.43 646.416 996.649 646.416 992.785C646.408 987.599 644.344 982.629 640.677 978.965C637.01 975.301 632.04 973.243 626.858 973.243V973.243Z" />
      <circle
        cx="626.9"
        cy="992.8"
        r="20.8"
        style={{ fill: selectedItem === 3 ? 'var(--color-neon-green)' : '' }}
      />
      <path
        d="M632.356 995.28V997.776H629.712V1001.35H626.918V997.776H619.317V995.16L626.828 984.487H629.833V995.28H632.356ZM622.111 995.28H626.918V988.426L622.111 995.28Z"
        style={{ fill: selectedItem === 3 ? 'var(--color-white)' : '' }}
      />
    </g>
    <g
      id="five"
      onMouseEnter={() => setSelectedItem(4)}
      onMouseLeave={() => setSelectedItem(null)}
      onClick={() => handleClick(4)}
    >
      <path d="M516.209 1048.22C512.121 1048.22 508.124 1047.01 504.725 1044.74C501.326 1042.46 498.677 1039.23 497.112 1035.45C495.548 1031.67 495.139 1027.52 495.936 1023.5C496.734 1019.49 498.702 1015.81 501.593 1012.91C504.484 1010.02 508.167 1008.05 512.176 1007.25C516.186 1006.45 520.342 1006.86 524.119 1008.43C527.896 1009.99 531.124 1012.65 533.395 1016.05C535.666 1019.45 536.879 1023.45 536.879 1027.54C536.871 1033.02 534.69 1038.28 530.816 1042.16C526.941 1046.03 521.688 1048.22 516.209 1048.22V1048.22ZM516.209 1008C512.338 1007.99 508.552 1009.13 505.332 1011.28C502.111 1013.43 499.599 1016.49 498.115 1020.07C496.631 1023.64 496.241 1027.58 496.995 1031.38C497.749 1035.18 499.612 1038.67 502.349 1041.41C505.086 1044.15 508.574 1046.01 512.371 1046.77C516.167 1047.52 520.102 1047.13 523.677 1045.65C527.252 1044.16 530.306 1041.65 532.453 1038.42C534.6 1035.2 535.743 1031.41 535.737 1027.54C535.729 1022.36 533.669 1017.39 530.009 1013.73C526.348 1010.07 521.386 1008.01 516.209 1008V1008Z" />
      <circle
        cx="516.3"
        cy="1027.6"
        r="20.8"
        style={{ fill: selectedItem === 4 ? 'var(--color-neon-green)' : '' }}
      />
      <path
        d="M520.475 1026.55C520.98 1027.08 521.373 1027.72 521.631 1028.41C521.889 1029.1 522.007 1029.84 521.977 1030.58C522.012 1031.36 521.88 1032.14 521.59 1032.86C521.299 1033.59 520.858 1034.24 520.295 1034.78C519.081 1035.86 517.498 1036.42 515.879 1036.35C514.399 1036.39 512.955 1035.89 511.823 1034.93C511.288 1034.46 510.855 1033.88 510.55 1033.24C510.245 1032.59 510.075 1031.89 510.05 1031.18L512.544 1030.79C512.576 1031.67 512.958 1032.51 513.606 1033.11C514.254 1033.71 515.114 1034.03 515.999 1034C516.883 1033.97 517.719 1033.59 518.322 1032.94C518.925 1032.29 519.245 1031.43 519.213 1030.55C519.239 1030.11 519.167 1029.67 519.004 1029.26C518.841 1028.85 518.59 1028.48 518.269 1028.18C517.948 1027.88 517.565 1027.65 517.147 1027.51C516.728 1027.38 516.285 1027.34 515.849 1027.39C515.25 1027.38 514.659 1027.52 514.132 1027.8C513.605 1028.09 513.162 1028.5 512.844 1029.01L510.17 1028.38L511.853 1019.36H520.866V1021.89H513.806L512.964 1026.52C513.438 1026.05 514.002 1025.68 514.622 1025.44C515.241 1025.2 515.904 1025.08 516.57 1025.1C517.286 1025.05 518.005 1025.15 518.678 1025.4C519.352 1025.65 519.964 1026.04 520.475 1026.55V1026.55Z"
        style={{ fill: selectedItem === 4 ? 'var(--color-white)' : '' }}
      />
    </g>
    <g
      id="six"
      onMouseEnter={() => setSelectedItem(5)}
      onMouseLeave={() => setSelectedItem(null)}
      onClick={() => handleClick(5)}
    >
      <path d="M505.453 1295.11C501.365 1295.11 497.369 1293.9 493.97 1291.62C490.571 1289.35 487.922 1286.12 486.357 1282.34C484.793 1278.56 484.383 1274.4 485.181 1270.39C485.978 1266.38 487.947 1262.69 490.838 1259.8C493.728 1256.91 497.411 1254.94 501.421 1254.14C505.431 1253.34 509.586 1253.75 513.363 1255.32C517.14 1256.88 520.368 1259.53 522.64 1262.93C524.911 1266.34 526.123 1270.33 526.123 1274.43C526.115 1279.91 523.935 1285.17 520.06 1289.04C516.186 1292.92 510.933 1295.1 505.453 1295.11V1295.11ZM505.453 1254.88C501.591 1254.88 497.816 1256.03 494.604 1258.18C491.393 1260.32 488.89 1263.38 487.412 1266.95C485.934 1270.52 485.547 1274.45 486.301 1278.24C487.054 1282.03 488.914 1285.51 491.645 1288.24C494.376 1290.98 497.856 1292.84 501.644 1293.59C505.432 1294.35 509.358 1293.96 512.927 1292.48C516.495 1291 519.545 1288.5 521.69 1285.28C523.836 1282.07 524.982 1278.29 524.982 1274.43C524.974 1269.25 522.914 1264.28 519.253 1260.62C515.593 1256.95 510.63 1254.89 505.453 1254.88V1254.88Z" />
      <circle
        cx="505.5"
        cy="1274.4"
        r="20.8"
        style={{ fill: selectedItem === 5 ? 'var(--color-neon-green)' : '' }}
      />
      <path
        d="M509.209 1273.61C510.214 1274.72 510.771 1276.16 510.771 1277.66C510.771 1279.15 510.214 1280.59 509.209 1281.7C508.626 1282.23 507.944 1282.65 507.202 1282.91C506.459 1283.18 505.671 1283.3 504.883 1283.26C503.976 1283.3 503.074 1283.12 502.253 1282.73C501.432 1282.34 500.716 1281.76 500.166 1281.04C498.953 1279.28 498.351 1277.16 498.454 1275.03C498.324 1272.67 498.933 1270.34 500.196 1268.35C500.706 1267.58 501.404 1266.95 502.224 1266.53C503.044 1266.1 503.959 1265.89 504.883 1265.92C506.251 1265.86 507.594 1266.3 508.669 1267.15C509.677 1267.96 510.343 1269.12 510.531 1270.4L508.038 1270.76C507.926 1270.06 507.556 1269.42 506.999 1268.98C506.442 1268.54 505.741 1268.33 505.033 1268.38C502.359 1268.38 500.857 1270.73 501.038 1274.67C501.452 1273.88 502.076 1273.23 502.841 1272.79C503.605 1272.34 504.479 1272.12 505.364 1272.14C506.07 1272.12 506.774 1272.24 507.434 1272.49C508.094 1272.74 508.697 1273.13 509.209 1273.61V1273.61ZM507.977 1277.61C507.977 1276.98 507.79 1276.36 507.439 1275.84C507.087 1275.31 506.588 1274.91 506.005 1274.67C505.421 1274.43 504.779 1274.36 504.161 1274.49C503.542 1274.61 502.975 1274.92 502.53 1275.37C502.086 1275.82 501.785 1276.39 501.666 1277.01C501.546 1277.63 501.614 1278.27 501.859 1278.85C502.105 1279.43 502.518 1279.93 503.046 1280.28C503.573 1280.62 504.192 1280.81 504.823 1280.8C505.246 1280.82 505.668 1280.75 506.063 1280.6C506.457 1280.45 506.815 1280.21 507.113 1279.91C507.411 1279.61 507.642 1279.25 507.791 1278.85C507.94 1278.46 508.003 1278.03 507.977 1277.61Z"
        style={{ fill: selectedItem === 5 ? 'var(--color-white)' : '' }}
      />
    </g>
    <g
      id="seven"
      onMouseEnter={() => setSelectedItem(6)}
      onMouseLeave={() => setSelectedItem(null)}
      onClick={() => handleClick(6)}
    >
      <path d="M266.67 1389.03C262.582 1389.03 258.585 1387.82 255.186 1385.54C251.787 1383.27 249.138 1380.04 247.573 1376.26C246.009 1372.48 245.6 1368.32 246.397 1364.31C247.195 1360.3 249.163 1356.61 252.054 1353.72C254.945 1350.83 258.628 1348.86 262.637 1348.06C266.647 1347.26 270.803 1347.67 274.58 1349.24C278.357 1350.8 281.585 1353.45 283.856 1356.85C286.127 1360.26 287.339 1364.26 287.339 1368.35C287.332 1373.83 285.151 1379.09 281.277 1382.96C277.402 1386.84 272.149 1389.02 266.67 1389.03V1389.03ZM266.67 1348.77C262.8 1348.77 259.016 1349.91 255.796 1352.06C252.575 1354.21 250.064 1357.26 248.579 1360.84C247.094 1364.41 246.702 1368.35 247.454 1372.15C248.205 1375.94 250.065 1379.43 252.799 1382.17C255.533 1384.91 259.018 1386.78 262.813 1387.54C266.608 1388.3 270.542 1387.91 274.117 1386.43C277.693 1384.95 280.749 1382.44 282.9 1379.22C285.05 1376 286.198 1372.22 286.198 1368.35C286.198 1363.16 284.141 1358.19 280.48 1354.52C276.819 1350.85 271.852 1348.78 266.67 1348.77V1348.77Z" />
      <circle
        cx="266.8"
        cy="1368.4"
        r="20.8"
        style={{ fill: selectedItem === 6 ? 'var(--color-neon-green)' : '' }}
      />
      <path
        d="M260.631 1360.05H272.648V1362.54C270.58 1364.3 268.944 1366.5 267.865 1368.99C266.787 1371.47 266.295 1374.18 266.429 1376.88H263.425C263.281 1374.2 263.747 1371.52 264.788 1369.04C265.829 1366.56 267.417 1364.35 269.433 1362.57H260.42L260.631 1360.05Z"
        style={{ fill: selectedItem === 6 ? 'var(--color-white)' : '' }}
      />
    </g>
    <g
      id="eight"
      onMouseEnter={() => setSelectedItem(7)}
      onMouseLeave={() => setSelectedItem(null)}
      onClick={() => handleClick(7)}
    >
      <path d="M300.408 1259.69C296.319 1259.69 292.321 1258.48 288.921 1256.21C285.522 1253.93 282.872 1250.7 281.309 1246.92C279.745 1243.14 279.338 1238.98 280.138 1234.96C280.938 1230.95 282.909 1227.27 285.803 1224.37C288.697 1221.48 292.382 1219.51 296.394 1218.72C300.406 1217.93 304.562 1218.34 308.339 1219.91C312.115 1221.48 315.341 1224.14 317.609 1227.54C319.876 1230.95 321.084 1234.95 321.078 1239.04C321.07 1244.52 318.889 1249.77 315.013 1253.65C311.138 1257.52 305.885 1259.69 300.408 1259.69V1259.69ZM300.408 1219.47C296.54 1219.47 292.758 1220.62 289.542 1222.77C286.326 1224.92 283.819 1227.97 282.339 1231.55C280.858 1235.13 280.471 1239.06 281.226 1242.86C281.98 1246.65 283.843 1250.14 286.578 1252.88C289.314 1255.62 292.799 1257.48 296.592 1258.24C300.386 1258.99 304.319 1258.6 307.893 1257.12C311.466 1255.64 314.521 1253.13 316.67 1249.91C318.819 1246.7 319.966 1242.91 319.966 1239.04C319.966 1233.85 317.906 1228.87 314.238 1225.2C310.57 1221.53 305.595 1219.47 300.408 1219.47V1219.47Z" />
      <circle
        cx="300.4"
        cy="1239"
        r="20.8"
        style={{ fill: selectedItem === 7 ? 'var(--color-neon-green)' : '' }}
      />
      <path
        d="M306.567 1242.77C306.596 1243.47 306.458 1244.17 306.166 1244.82C305.874 1245.46 305.435 1246.02 304.885 1246.47C303.614 1247.44 302.038 1247.93 300.438 1247.85C298.83 1247.93 297.244 1247.44 295.962 1246.47C295.357 1245.96 294.888 1245.31 294.599 1244.57C294.311 1243.84 294.211 1243.04 294.31 1242.26C294.409 1241.47 294.702 1240.73 295.164 1240.09C295.626 1239.44 296.241 1238.93 296.953 1238.59C296.313 1238.27 295.777 1237.78 295.41 1237.16C295.042 1236.55 294.858 1235.85 294.88 1235.13C294.849 1234.48 294.971 1233.84 295.237 1233.25C295.504 1232.66 295.906 1232.14 296.413 1231.73C297.586 1230.89 298.994 1230.44 300.438 1230.44C301.883 1230.44 303.29 1230.89 304.464 1231.73C304.965 1232.14 305.364 1232.66 305.63 1233.25C305.896 1233.84 306.021 1234.49 305.996 1235.13C306.019 1235.85 305.835 1236.55 305.467 1237.16C305.1 1237.78 304.564 1238.27 303.923 1238.59C304.716 1238.96 305.386 1239.55 305.854 1240.29C306.323 1241.03 306.57 1241.89 306.567 1242.77V1242.77ZM303.803 1242.53C303.803 1240.87 302.451 1239.76 300.438 1239.76C298.426 1239.76 297.044 1240.87 297.044 1242.53C297.044 1244.18 298.396 1245.38 300.438 1245.38C300.85 1245.43 301.267 1245.4 301.664 1245.28C302.061 1245.16 302.429 1244.96 302.745 1244.69C303.061 1244.42 303.318 1244.09 303.5 1243.72C303.682 1243.35 303.786 1242.94 303.803 1242.53V1242.53ZM300.438 1237.66C302.181 1237.66 303.293 1236.79 303.293 1235.4C303.287 1235.05 303.206 1234.69 303.056 1234.37C302.905 1234.05 302.688 1233.76 302.418 1233.53C302.149 1233.3 301.833 1233.12 301.492 1233.02C301.15 1232.92 300.791 1232.89 300.438 1232.94C300.076 1232.88 299.704 1232.89 299.349 1232.99C298.993 1233.09 298.663 1233.25 298.378 1233.49C298.094 1233.72 297.862 1234.01 297.7 1234.34C297.537 1234.67 297.446 1235.03 297.434 1235.4C297.524 1236.79 298.726 1237.66 300.438 1237.66V1237.66Z"
        style={{ fill: selectedItem === 7 ? 'var(--color-white)' : '' }}
      />
    </g>
  </svg>
);

const CloseIcon = () => (
  <svg
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="0.646447" y1="30.6462" x2="30.6464" y2="0.646203" />
    <line
      y1="-0.5"
      x2="42.4264"
      y2="-0.5"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 31 30.9998)"
    />
  </svg>
);

const VisitMap = ({
  content: { map_title, point_of_interest },
  isDarkMode,
  lang,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState(null);

  useEffect(() => {
    function handleKeyDown(e) {
      if (isModalOpen && e.code === 'Escape') {
        setIsModalOpen(false);
        setModalItem(null);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen]);

  function handleClick(i) {
    setModalItem(i);
    setIsModalOpen(true);

    gsap.to(window, {
      duration: 0.7,
      scrollTo: '#map',
    });
  }

  return (
    <MapContainer>
      <Row>
        <Col base={12} sm={7} sm_md={6} className="column-left">
          <Heading level="h2" size="l" content={map_title} />

          <TransitionGroup component={null}>
            {isModalOpen && (
              <CSSTransition timeout={200} classNames="modal-animation">
                <FocusTrap
                  focusTrapOptions={{
                    allowOutsideClick: true,
                    preventScroll: true,
                  }}
                >
                  <Modal aria-modal="true" isDarkMode={isDarkMode}>
                    <header>
                      <div className="number">0{modalItem + 1}</div>
                      <Heading
                        level="h3"
                        size="m"
                        content={point_of_interest[modalItem].name}
                      />
                      <button
                        onClick={() => {
                          setIsModalOpen(false);
                          setModalItem(null);
                        }}
                        aria-label={createTranslation('Close Modal', lang)}
                      >
                        <CloseIcon />
                      </button>
                    </header>

                    <Image
                      image={point_of_interest[modalItem].image}
                      aspectRatio={16 / 9}
                    />

                    <RichText
                      render={point_of_interest[modalItem].description.richText}
                    />
                  </Modal>
                </FocusTrap>
              </CSSTransition>
            )}
          </TransitionGroup>

          <PointOfInterestList isDarkMode={isDarkMode}>
            {point_of_interest.map((item, i) => (
              <li key={item.name}>
                <button
                  onMouseEnter={() => setSelectedItem(i)}
                  onMouseLeave={() => setSelectedItem(null)}
                  onFocus={() => setSelectedItem(i)}
                  onBlur={() => setSelectedItem(null)}
                  onClick={() => {
                    handleClick(i);
                  }}
                  style={{
                    color:
                      selectedItem === i
                        ? 'var(--color-neon-green)'
                        : 'inherit',
                  }}
                  tabIndex={isModalOpen ? -1 : 0}
                >
                  <span>0{i + 1}</span>
                  <Heading level="h3" size="m" content={item.name} />
                </button>
              </li>
            ))}
          </PointOfInterestList>
        </Col>

        <Col base={12} sm={5} sm_md={6}>
          <Map
            selectedItem={selectedItem !== null ? selectedItem : modalItem}
            setSelectedItem={setSelectedItem}
            handleClick={handleClick}
          />
        </Col>

        <VisitPOIAccordion
          content={point_of_interest}
          isDarkMode={isDarkMode}
        />
      </Row>
    </MapContainer>
  );
};

export default VisitMap;

/**
 * Styled Components
 *
 */
const MapContainer = styled.section`
  margin: var(--space-xxl) 0 var(--space-xxl);

  @media (${bp.min.sm}) {
    margin: var(--space-xxl) 0 var(--space-xxxl);
  }

  .column-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    h2 {
      margin-bottom: var(--space-xl);
    }
  }

  .modal-animation-enter {
    opacity: 0;
    transform: translateY(1.6rem);
  }

  .modal-animation-enter-active {
    opacity: 1;
    transform: translateY(0);

    transition: all 300ms var(--ease-cubic-in-out);
  }

  .modal-animation-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .modal-animation-exit-active {
    opacity: 0;
    transform: translateY(1.6rem);
    transition: all 300ms var(--ease-cubic-in-out);
  }
`;

const PointOfInterestList = styled.ul`
  display: none;

  @media (${bp.min.sm}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4.8rem var(--space-m);
  }

  li {
    border-bottom: 0.1rem solid
      ${props =>
        props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
    padding-bottom: var(--space-s);
  }

  button {
    width: 100%;
    text-align: left;
    font-size: 2rem;
    cursor: pointer;
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
    transition: all 200ms var(--ease-cubic-in-out);

    &:hover {
      color: var(--color-neon-green);
    }

    &.selected {
      color: var(--color-neon-green);
    }

    .heading-tag {
      line-height: 1.1;
    }
  }
`;

const MapWrapper = styled.div`
  position: relative;
  height: 100%;
  display: grid;

  @media (${bp.min.lg}) {
    height: 90vh;
    min-height: 60rem;
    display: block;
  }

  .image-wrapper,
  svg {
    width: 100%;
    height: auto;
    grid-area: 1 / 1;

    @media (${bp.min.sm}) {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    @media (${bp.min.sm_md}) {
      top: initial;
      bottom: 0;
      transform: none;
    }

    @media (${bp.min.md}) {
      bottom: initial;
      top: 0;
      width: auto;
      height: 100%;
    }
  }

  .image-wrapper {
    display: flex;
  }

  img {
    border-radius: 1rem;
    object-fit: cover;
  }

  svg {
    z-index: 1;

    g {
      pointer-events: none;

      @media (${bp.min.sm}) {
        pointer-events: bounding-box;
        cursor: pointer;
      }

      circle {
        fill: transparent;
      }
    }
  }
`;

const Modal = styled.div`
  position: absolute;
  top: 9.6rem;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: ${props =>
    props.isDarkMode ? 'var(--color-black)' : 'var(--color-off-white)'};
  color: ${props =>
    props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
  z-index: 90;
  padding: 0 var(--space-xs) var(--space-xl);

  @media (${bp.min.md}) {
    top: 11.2rem;
  }

  header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid
      ${props =>
        props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
    padding-bottom: var(--space-s);
    margin-bottom: var(--space-m);
  }

  .number {
    width: 100%;
  }

  button {
    cursor: pointer;
    transition: all 200ms var(--ease-cubic-in-out);
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};

    &:hover {
      color: var(--color-neon-green);
    }

    &:focus {
      outline: 0;
    }
  }

  p {
    font-size: 2rem;
    line-height: 1.3;
    max-width: 40ch;
    margin-top: var(--space-m);

    @media (${bp.min.md}) {
      font-size: 3rem;
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment VisitMap on PrismicVisit {
    data {
      map_title
      point_of_interest {
        name
        description {
          richText
        }
        image {
          alt
          gatsbyImageData(width: 800)
        }
      }
    }
  }
`;
