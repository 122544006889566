import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AnimateImage = ({ children, delay, duration, fromBottom }) => {
  const imageRef = useRef(null);
  const tl = useRef();

  const imageDirection = fromBottom
    ? {
        rotation: 6,
        x: -60,
        y: 80,
        duration: duration ? duration : 0.9,
      }
    : {
        rotation: -6,
        x: 100,
        y: -80,
        duration: duration ? duration : 0.9,
      };

  useEffect(() => {
    tl.current = gsap.timeline({
      defaults: { ease: 'power2.inOut' },
      scrollTrigger: { trigger: imageRef.current, start: 'top 75%' },
    });

    tl.current
      .set(imageRef.current, {
        backfaceVisibility: 'hidden',
        willChange: 'transform',
        transformOrigin: 'left center',
      })
      .to(imageRef.current, {
        autoAlpha: 1,
        delay: delay ? delay : 0.1,
        duration: duration ? duration : 0.6,
      })
      .from(imageRef.current, { ...imageDirection }, '<');

    return () => {
      tl.current.kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ImageWrapper ref={imageRef} className="animate-image-wrapper">
      {children}
    </ImageWrapper>
  );
};

export default AnimateImage;

const ImageWrapper = styled.div`
  opacity: 0;
  visibility: hidden;
`;
