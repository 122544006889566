import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { isBrowser, prefersReducedMotion } from '../assets/js/utilities';

import pause from '../assets/images/icons/pause.svg';
import play from '../assets/images/icons/play.svg';

const AmbientVideo = ({ src, poster, borderRadius = 0 }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const video = useRef(null);

  useEffect(() => {
    if (isBrowser && prefersReducedMotion) {
      setIsPlaying(false);
    }
  }, []);

  function handleClick() {
    if (isPlaying) {
      video.current.pause();
      setIsPlaying(false);
    } else {
      let playPromise = video.current.play();

      if (playPromise !== undefined) {
        playPromise.then(_ => {
          video.current.play();
          setIsPlaying(true);
        });
      }
    }
  }

  return (
    <VideoWrapper
      isPlaying={isPlaying}
      borderRadius={borderRadius}
      className="video-wrapper"
    >
      <video
        ref={video}
        src={src}
        autoPlay={isPlaying}
        poster={poster}
        playsInline
        muted
        loop
      />

      <button
        onClick={handleClick}
        aria-label="Toggle ambient video play pause state"
      />
    </VideoWrapper>
  );
};

export default AmbientVideo;

/**
 * Styled Components
 *
 */
const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(9 / 16 * 100%);
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* Convert whole number to decimal for rem units (ex: 5 -> 0.5) */
    border-radius: ${props => props.borderRadius / 10}rem;
  }

  button {
    --size: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    position: absolute;
    right: var(--space-s);
    bottom: var(--space-s);
    border-radius: 100%;
    border: 0.1rem solid rgba(255, 255, 255, 0.75);
    overflow: hidden;
    cursor: pointer;
    z-index: 5;
    transition: transform 500ms cubic-bezier(0.68, 0.55, 0.27, 1);

    &:hover {
      transform: scale(1.05);
    }

    &:before,
    &:after {
      content: '';
      background-size: 100%;
      background-position: center;
      width: 1.2rem;
      height: 1.6rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center;
      transition: all 350ms cubic-bezier(0.68, 0.55, 0.27, 1);
    }

    &:before {
      background-image: url(${pause});
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }

    &:after {
      background-image: url(${play});
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.3);
      margin-left: 0.2rem;
      transition-delay: 50ms;
    }

    ${props =>
      !props.isPlaying &&
      `
      &:before {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
      }
      &:after {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    `}
  }
`;
