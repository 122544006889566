import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useCopyToClipboard } from 'react-use';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import Heading from './Heading';

const CustomDonation = ({
  content: { custom_title, custom_description, email_address },
  lang,
}) => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (state.value) {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    }
  }, [state]);

  return (
    <CustomDonationSection>
      <Row>
        <Col base={12} sm={10} md={8} smOffset={1} mdOffset={2}>
          <Heading level="h2" size="m" content={custom_title} />
          <p className="custom-donation__description">{custom_description}</p>

          <div className="custom-donation__email">
            <a
              href={`mailto:${email_address}`}
              target="_blank"
              rel="noreferrer"
            >
              {email_address}
            </a>

            <button onClick={() => copyToClipboard(email_address)}>
              {state.error ? (
                <p>
                  {createTranslation('Unable to copy value', lang)}:{' '}
                  {state.error.message}
                </p>
              ) : showSuccess ? (
                <p>
                  {createTranslation('Copied', lang)} {state.value}
                </p>
              ) : (
                <p>{createTranslation('copy email address', lang)}</p>
              )}
            </button>
          </div>
        </Col>
      </Row>
    </CustomDonationSection>
  );
};

export default CustomDonation;

/**
 * Styled Components
 *
 */
const CustomDonationSection = styled.section`
  margin: var(--space-xxl) 0;
  text-align: center;

  @media (${bp.min.sm}) {
    margin: var(--space-xxl) 0 9.6rem;
  }

  .heading-tag {
    margin: 0 auto var(--space-m);
    max-width: 15ch;

    @media (${bp.min.sm}) {
      margin: 0 auto var(--space-xl);
    }
  }

  a {
    font-size: 3rem;
    color: var(--color-neon-green);
    transition: color 200ms var(--ease-cubic-in-out);

    &:hover {
      color: var(--color-black);
    }
  }

  .custom-donation__description {
    max-width: 50ch;
    line-height: 1.3;
    font-size: 1.7rem;
    margin: 0 auto var(--space-l);

    @media (${bp.min.sm}) {
      margin: 0 auto 4.8rem;
      font-size: 3rem;
    }
  }

  .custom-donation__email {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: var(--space-m);
      cursor: pointer;
      color: var(--color-black);
      transition: color 200ms var(--ease-cubic-in-out);

      @media (${bp.min.sm}) {
        margin-top: var(--space-xs);
      }

      &:hover {
        color: var(--color-neon-green);
      }
    }
  }
`;

/**
 * GraphQL Query
 *
 */
export const query = graphql`
  fragment CustomDonations on PrismicDonate {
    data {
      custom_title
      custom_description
      email_address
    }
  }
`;
