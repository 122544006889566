import React from 'react';
import styled from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';

import bp from '../assets/js/breakpoints';

const Main = React.forwardRef(({ variant, children }, ref) => {
  return (
    <MainWrapper variant={variant} ref={ref} id="main">
      <ParallaxProvider>{children}</ParallaxProvider>
    </MainWrapper>
  );
});

export default Main;

/**
 * Styled Components
 *
 */
const MainWrapper = styled.main`
  ${props =>
    props.variant !== 'fullscreen' &&
    `
   padding-top: 9.6rem;

   @media (${bp.min.xs_sm}) {
     padding-top: 16rem;
   }
 `}
`;
