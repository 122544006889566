import React from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

const Heading = React.forwardRef(
  ({ size = 'l', level = 'h2', content }, ref) => {
    const isRichText = content ? content.hasOwnProperty('richText') : false;

    return (
      <HeadingTag as={level} size={size} className="heading-tag" ref={ref}>
        {isRichText ? <RichText render={content.richText} /> : content}
      </HeadingTag>
    );
  }
);

export default Heading;

/**
 * Styled Components
 *
 */
const HeadingTag = styled.h2`
  ${props => {
    switch (props.size) {
      case 'xl':
        return `
           font-size: clamp(8rem, 6.3529rem + 5.1471vw, 15rem);
           font-weight: var(--font-weight-semi-bold);
           line-height: 0.88;
           letter-spacing: -.5rem;
         `;
      case 'l':
        return `
           font-size: clamp(4rem, 3.0303rem + 3.0303vw, 8rem);
           font-weight: var(--font-weight-semi-bold);
           line-height: 1.07;
           letter-spacing: -.2rem;
         `;
      case 'm':
        return `
           font-size: clamp(3.5rem, 3.1471rem + 1.1029vw, 5rem);
           line-height: 1.0;
           letter-spacing: -.15rem;
         `;
      case 's':
        return `
           font-size: clamp(2rem, 1.6471rem + 1.1029vw, 3.5rem);
           line-height: 1.2;
           letter-spacing: -.05rem;
         `;
      case 'xs':
      default:
        return `
           font-size: 2rem;
           line-height: 1.4;
         `;
    }
  }}
`;
