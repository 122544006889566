import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import Image from './Image';
import Heading from './Heading';

const Callout = ({ content, lang }) => {
  const { data, type, url } = content.node;
  const { thumbnail, title } = data;

  return (
    <li>
      <Link to={url}>
        <CalloutWrapper>
          {thumbnail && (
            <ImageContainer>
              <Image image={thumbnail} />
            </ImageContainer>
          )}

          <Heading level="h3" size="s" content={title} />

          <p className="link-text">
            {type === 'event'
              ? createTranslation('Details', lang)
              : createTranslation('Read more', lang)}
          </p>
        </CalloutWrapper>
      </Link>
    </li>
  );
};

const NewsEventsLoadMoreCallout = ({ news, lang }) => {
  const [maxLimit, setMaxLimit] = useState(10);

  useEffect(() => {
    function updateLimit() {
      if (window.innerWidth < 970) {
        setMaxLimit(4);
      } else {
        setMaxLimit(10);
      }
    }

    window.addEventListener('load', updateLimit);

    return () => {
      window.removeEventListener('load', updateLimit);
    };
  }, []);

  const feed = [...news.edges].sort(
    (a, b) =>
      new Date(
        b.node.data.publish_date
          ? b.node.data.publish_date
          : b.node.first_publication_date
      ) -
      new Date(
        a.node.data.publish_date
          ? a.node.data.publish_date
          : a.node.first_publication_date
      )
  );
  const truncatedFeed = feed.slice(0, maxLimit);

  function handleClick() {
    setMaxLimit(maxLimit => maxLimit + maxLimit);
  }

  return (
    <NewsEventsWrapper>
      <Heading level="h2" size="m" content={createTranslation('News', lang)} />

      <Row>
        <Col base={12} lg={10} lgOffset={1}>
          <ItemGrid>
            <TransitionGroup component={null}>
              {truncatedFeed.map((item, i) => (
                <CSSTransition
                  key={i}
                  timeout={200}
                  classNames="load-more-animation"
                >
                  <Callout
                    key={i}
                    content={item}
                    index={i}
                    limit={maxLimit}
                    lang={lang}
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </ItemGrid>
        </Col>
      </Row>

      {maxLimit < feed.length && (
        <LoadMore onClick={handleClick}>
          {createTranslation('Load more', lang)}
        </LoadMore>
      )}
    </NewsEventsWrapper>
  );
};

export default NewsEventsLoadMoreCallout;

/**
 * Styled Components
 *
 */
const NewsEventsWrapper = styled.section`
  margin: var(--space-xl) 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    align-self: flex-start;
    margin-bottom: var(--space-s);

    @media (${bp.min.xs_sm}) {
      align-self: center;
      margin-bottom: var(--space-xxl);
    }
  }
`;

const ItemGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 0.8rem;

  @media (${bp.min.xs_sm}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (${bp.min.sm}) {
    grid-gap: var(--space-xs) var(--space-m);
  }

  @media (${bp.min.sm_md}) {
    grid-template-columns: repeat(5, 1fr);
  }

  .load-more-animation-enter {
    opacity: 0;
    transform: translateY(1.6rem);
  }

  .load-more-animation-enter-active {
    opacity: 1;
    transform: translateY(0);

    transition: all 300ms var(--ease-cubic-in-out);
  }

  .load-more-animation-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .load-more-animation-exit-active {
    opacity: 0;
    transform: translateY(1.6rem);
    transition: all 300ms var(--ease-cubic-in-out);
  }
`;

const LoadMore = styled.button`
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  border: 0.1rem solid var(--color-black);
  border-radius: 10rem;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  padding: var(--space-xs) var(--space-m);
  margin-top: var(--space-m);
  transition: all 200ms var(--ease-quart-in-out);

  @media (${bp.min.xs_sm}) {
    font-size: 1.7rem;
    padding: var(--space-s) var(--space-xl);
    margin-top: var(--space-xl);
  }

  &:hover {
    color: var(--color-white);
    background-color: var(--color-neon-green);
    border-color: var(--color-neon-green);
  }
`;

const ImageContainer = styled.div`
  transition: all 200ms var(--ease-cubic-in-out);
`;

const CalloutWrapper = styled.div`
  --margin-bottom: var(--space-s);
  margin-bottom: var(--margin-bottom);

  @media (${bp.min.sm}) {
    --margin-bottom: var(--space-m);
  }

  &:hover {
    .heading-tag {
      color: ${props =>
        props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
    }

    .link-text {
      color: var(--color-neon-green);

      &:after {
        transform: translate(0.2rem, -0.2rem);
      }
    }

    ${ImageContainer} {
      transform: translateY(-1.2rem);
      opacity: 0.9;
    }
  }

  .heading-tag {
    --margin-top: var(--space-xxs);
    margin-top: var(--margin-top);
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};

    @media (${bp.min.sm}) {
      --margin-top: var(--space-s);
    }
  }

  .link-text {
    --margin-top: var(--space-xxxs);
    font-size: 1.5rem;
    margin-top: var(--margin-top);
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
    transition: color 200ms var(--ease-quart-in-out);

    @media (${bp.min.sm}) {
      --margin-top: var(--space-xxs);
    }

    &:after {
      content: '↗';
      display: inline-block;
      margin-left: var(--space-xxxs);
      transition: transform 200ms var(--ease-quart-in-out);
    }
  }
`;
