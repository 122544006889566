import React, { useState, useEffect, useRef } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Masonry from 'react-masonry-component';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import Heading from './Heading';

const Article = ({ content, lang }) => {
  const { data, first_publication_date, url } = content.node;
  const { title, publish_date, body } = data;

  return (
    <li>
      <Link to={url}>
        <ArticleWrapper>
          <div className="article__date">
            {publish_date ? publish_date : first_publication_date}
          </div>
          <h3 className="article__title">{title}</h3>

          <p className="article__excerpt">{body[0].primary.text.text}</p>

          <p className="link-text">{createTranslation('Read more', lang)}</p>
        </ArticleWrapper>
      </Link>
    </li>
  );
};

const PressReleases = ({ press_title, press, lang }) => {
  const [maxLimit, setMaxLimit] = useState(6);
  const [allArticlesLoaded, setAllArticlesLoaded] = useState(false);
  const [smallestColumnHeight, setSmallestColumnHeight] = useState(null);

  const masonry = useRef(null);
  const masonryOptions = {
    transitionDuration: 0,
  };

  // Set number of articles based on the screen width
  useEffect(() => {
    function updateLimit() {
      if (window.innerWidth < 620) {
        setMaxLimit(2);
      } else if (window.innerWidth >= 620 && window.innerWidth < 970) {
        setMaxLimit(4);
      } else {
        setMaxLimit(6);
      }
    }

    window.addEventListener('load', updateLimit);

    return () => {
      window.removeEventListener('load', updateLimit);
    };
  }, []);

  // Check if there are more articles left to load
  useEffect(() => {
    setAllArticlesLoaded(maxLimit >= press.edges.length);
  }, [maxLimit, press.edges.length]);

  // Update the Masonry container height when new articles are added
  useEffect(() => {
    const columnHeights = masonry.current.masonry.colYs;
    const smallestColumn = allArticlesLoaded
      ? '100%'
      : Math.min(...columnHeights);

    setSmallestColumnHeight(smallestColumn);
  }, [allArticlesLoaded]);

  // Sort press articles by published date and then truncate by the max limit
  const feed = press.edges.sort(
    (a, b) =>
      new Date(
        b.node.data.publish_date
          ? b.node.data.publish_date
          : b.node.first_publication_date
      ) -
      new Date(
        a.node.data.publish_date
          ? a.node.data.publish_date
          : a.node.first_publication_date
      )
  );
  const truncatedFeed = feed.slice(0, maxLimit);

  function handleClick() {
    setMaxLimit(maxLimit => maxLimit + maxLimit);
  }

  return (
    <PressEventsWrapper
      smallestColumnHeight={smallestColumnHeight}
      allArticlesLoaded={allArticlesLoaded}
    >
      <Heading level="h2" size="m" content={press_title} />

      <Masonry elementType={'ul'} options={masonryOptions} ref={masonry}>
        <TransitionGroup component={null}>
          {truncatedFeed.map((item, i) => (
            <CSSTransition
              key={i}
              timeout={200}
              classNames="load-more-animation"
            >
              <Article
                key={item.node.uid}
                content={item}
                index={i}
                limit={maxLimit}
                lang={lang}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Masonry>

      {maxLimit < feed.length && (
        <LoadMore onClick={handleClick}>
          {createTranslation('Load more', lang)}
        </LoadMore>
      )}
    </PressEventsWrapper>
  );
};

export default PressReleases;

/**
 * Styled Components
 *
 */
const PressEventsWrapper = styled.section`
  position: relative;
  margin: var(--space-xl) 0;

  h2 {
    margin-bottom: var(--space-l);

    @media (${bp.min.xs_sm}) {
      margin-bottom: 6rem;
    }
  }

  ul {
    position: relative;
    overflow: hidden;

    @media (${bp.min.xs_sm}) {
      margin: 0 -0.8rem;
    }

    @media (${bp.min.sm_md}) {
      max-height: ${props => props.smallestColumnHeight / 10}rem;
      margin: 0 -1.2rem;
    }

    &:after {
      content: '';
      display: ${props => props.allArticlesLoaded && `none`};
      width: 100%;
      height: 16rem;
      position: absolute;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(
        to top,
        hsl(40, 23%, 97%) 0%,
        hsla(40, 23%, 97%, 0.907) 15%,
        hsla(40, 23%, 97%, 0.817) 27.9%,
        hsla(40, 23%, 97%, 0.73) 38.7%,
        hsla(40, 23%, 97%, 0.646) 47.9%,
        hsla(40, 23%, 97%, 0.565) 55.5%,
        hsla(40, 23%, 97%, 0.488) 61.8%,
        hsla(40, 23%, 97%, 0.415) 67.2%,
        hsla(40, 23%, 97%, 0.346) 71.7%,
        hsla(40, 23%, 97%, 0.281) 75.6%,
        hsla(40, 23%, 97%, 0.221) 79.2%,
        hsla(40, 23%, 97%, 0.166) 82.7%,
        hsla(40, 23%, 97%, 0.116) 86.2%,
        hsla(40, 23%, 97%, 0.072) 90.2%,
        hsla(40, 23%, 97%, 0.033) 94.7%,
        hsla(40, 23%, 97%, 0) 100%
      );
    }

    li {
      @media (${bp.min.xs_sm}) {
        width: calc((100% / 2) - 2.4rem);
        margin: 0 var(--space-xxs);
      }

      @media (${bp.min.sm_md}) {
        width: calc((100% / 3) - 2.4rem);
        margin: 0 var(--space-xs);
      }
    }
  }

  .load-more-animation-enter {
    opacity: 0;
    transform: translateY(1.6rem);
  }

  .load-more-animation-enter-active {
    opacity: 1;
    transform: translateY(0);

    transition: all 300ms var(--ease-cubic-in-out);
  }

  .load-more-animation-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .load-more-animation-exit-active {
    opacity: 0;
    transform: translateY(1.6rem);
    transition: all 300ms var(--ease-cubic-in-out);
  }
`;

const ArticleWrapper = styled.div`
  --margin-bottom: var(--space-l);
  margin-bottom: var(--margin-bottom);
  border-top: 0.1rem solid var(--color-black);
  padding-top: var(--space-m);

  @media (${bp.min.sm}) {
    --margin-bottom: var(--space-m);
  }

  &:hover {
    .article__title {
      color: var(--color-neon-green);
    }
  }

  .article__title,
  .article__excerpt,
  .article__date {
    color: var(--color-black);
  }

  .article__title {
    --margin-top: var(--space-s);
    margin-top: var(--margin-top);
    margin-bottom: var(--space-m);
    font-size: clamp(2rem, 3.5vw, 3rem);
    line-height: clamp(1.4, 3.5vw, 1.2);
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: -0.05rem;
    transition: color 200ms var(--ease-quart-in-out);

    @media (${bp.min.sm}) {
      --margin-top: var(--space-s);
    }
  }

  .article__excerpt {
    --number-of-lines: 6;
    --line-height: 2.8rem;

    overflow: hidden;
    font-size: 1.7rem;
    line-height: var(--line-height);
    max-height: calc(var(--line-height) * var(--number-of-lines));
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--number-of-lines);

    @media (${bp.min.xs_sm}) {
      font-size: 2rem;
    }
  }

  .article__date {
    font-size: 1.5rem;
  }

  .link-text {
    margin-top: var(--space-m);
    color: var(--color-black);
    transition: color 200ms var(--ease-quart-in-out);

    &:hover {
      color: var(--color-neon-green);
    }
  }
`;

const LoadMore = styled.button`
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  border: 0.1rem solid var(--color-black);
  border-radius: 10rem;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  padding: var(--space-xs) var(--space-m);
  margin: var(--space-m) auto 0;
  transition: all 200ms var(--ease-quart-in-out);
  z-index: 10;

  @media (${bp.min.xs_sm}) {
    font-size: 1.7rem;
    padding: var(--space-s) var(--space-xl);
    margin-top: var(--space-xl);
  }

  &:hover {
    color: var(--color-white);
    background-color: var(--color-neon-green);
    border-color: var(--color-neon-green);
  }
`;

/**
 * GraphQL Query
 *
 */
export const query = graphql`
  fragment PressItems on PrismicNews {
    first_publication_date(formatString: "MMMM D, YYYY", locale: $language)
    uid
    url
    type
    data {
      title
      publish_date(formatString: "dddd, MMMM DD, YYYY", locale: $language)
      body {
        ... on PrismicNewsDataBodyText {
          primary {
            text {
              text
            }
          }
        }
      }
    }
  }
`;
