import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { gsap } from 'gsap';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import bp from '../assets/js/breakpoints';
import { getActiveDocLanguage, getSiteMeta } from '../assets/js/utilities';
import linkResolver from '../utilities/link-resolver';

import Seo from '../components/Seo';
import Image from '../components/Image';
import Layout from '../components/Layout';
import DonateCallout from '../components/DonateCallout';
import ArrowIcon from '../components/ArrowIcon';
import AnimateFadeIn from '../components/AnimateFadeIn';

import historyMap from '../assets/images/history-map.jpg';

const History = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;
  const donateCallout = data.prismicDonateCallout;

  // Get page data
  const history = data.prismicHistory;
  const {
    title,
    hero_title,
    hero_subtitle,
    hero_description,
    body,
    meta_description,
    meta_image,
  } = history.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(history);
  const defaultMeta = getSiteMeta(layout.data);

  const heroRef = useRef(null);
  const tl = useRef();

  useEffect(() => {
    const query = gsap.utils.selector(heroRef);
    tl.current = gsap.timeline({ defaults: { ease: 'cubic.inOut' } });

    tl.current
      .to(heroRef.current, {
        autoAlpha: 1,
        duration: 0.8,
        delay: 0.3,
      })
      .fromTo(
        query('h1'),
        {
          opacity: 0,
          backgroundSize: '120%',
          scale: 0.96,
          y: 20,
        },
        {
          opacity: 1,
          backgroundSize: '100%',
          scale: 1,
          y: 0,
          duration: 1,
        },
        '<'
      )
      .from(
        query('h2, p'),
        {
          opacity: 0,
          yPercent: 20,
          duration: 0.6,
          stagger: 0.05,
          ease: 'power2.out',
        },
        '>-.4'
      );

    return () => {
      tl.current.kill();
    };
  }, []);

  return (
    <Layout
      activeDoc={activeDoc}
      layoutData={layout.data}
      hideOverflowX
      variant="inverted"
    >
      <Seo
        title={title}
        description={meta_description}
        image={meta_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
      />

      <Hero ref={heroRef}>
        <h1 className="hero__title">{hero_title}</h1>

        <div className="hero__body">
          <p className="hero__subtitle">{hero_subtitle}</p>
          <RichText render={hero_description.richText} />
        </div>
      </Hero>

      {body.map(({ primary: era }) => {
        const layout =
          era.layout_type === 'Large image on right'
            ? 'right'
            : era.layout_type === 'Large image on left'
            ? 'left'
            : 'single';

        return (
          <AnimateFadeIn>
            <Era layout={layout}>
              <p className="era__year">{era.year}</p>

              <div className="era__image-large">
                <Image image={era.image_large} borderRadius={5} />
              </div>

              <h2 className="era__title">
                <span>{era.title}</span>
                <ArrowIcon
                  width={48}
                  rotation={
                    layout === 'single' ? 0 : layout === 'left' ? 270 : 90
                  }
                  color="var(--color-white)"
                />
              </h2>

              {era.subtitle && (
                <div className="era__subtitle">
                  <RichText render={era.subtitle.richText} />
                </div>
              )}

              {era.image_small.gatsbyImageData && (
                <div className="era__image-small">
                  <Image image={era.image_small} borderRadius={5} />
                </div>
              )}

              <div className="era__description">
                <RichText render={era.description.richText} />
              </div>
            </Era>
          </AnimateFadeIn>
        );
      })}

      <DonateCallout content={donateCallout.data} />
    </Layout>
  );
};

export default withPrismicPreview(History, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const Hero = styled.section`
  margin-top: var(--space-m);
  margin-bottom: var(--space-xxl);
  text-align: center;
  opacity: 0;
  visibility: hidden;

  @media (${bp.min.sm}) {
    margin-bottom: 11.2rem;
  }

  .hero__title {
    font-family: var(--font-family-condensed);
    text-transform: uppercase;
    font-size: clamp(14rem, 0.7077rem + 41.5385vw, 70rem);
    line-height: 0.88;
    letter-spacing: -0.3rem;
    color: var(--color-off-white);
    padding: 0 var(--space-xs);
    position: relative;
    left: -0.4rem;
    background-image: url(${historyMap});
    background-size: cover;
    background-position: center;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &::selection {
      -webkit-text-fill-color: var(--color-black);
    }

    @media (${bp.min.sm}) {
      letter-spacing: -0.5rem;
      padding: 0 var(--space-xl);
      margin-bottom: var(--space-xl);
      left: -1.6rem;
    }

    @media (${bp.min.md}) {
      letter-spacing: -0.8rem;
    }
  }

  .hero__subtitle {
    font-size: clamp(2rem, 3vw, 3rem);
    line-height: 1.4;
    margin-bottom: var(--space-s);
    margin: -2rem auto var(--space-xs);
    max-width: 20ch;

    @media (${bp.min.sm}) {
      margin-top: -7.2rem;
      max-width: initial;
    }

    @media (${bp.min.md}) {
      margin-top: -9.6rem;
    }
  }

  .hero__body {
    width: 100%;
    max-width: 88rem;
    margin: 0 auto;
  }
`;

const Era = styled.div`
  margin-bottom: var(--space-xxl);

  @media (${bp.min.sm}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 4.8rem var(--space-m);
    margin: ${props => props.layout === 'single' && `var(--space-xxxl)`} 0;
  }

  &:last-of-type {
    margin-bottom: var(--space-xxl);

    @media (${bp.min.sm}) {
      margin-bottom: var(--space-xxxl);
    }
  }

  .era__year {
    --left-columns: 2 / span 11;
    --right-columns: 1 / span 11;
    --single-columns: 3 / span 6;

    grid-column: ${props =>
      props.layout === 'left'
        ? 'var(--left-columns)'
        : props.layout === 'single'
        ? 'var(--single-columns)'
        : 'var(--right-columns)'};
    grid-row: 1 / 2;
    font-size: clamp(8rem, 6.3529rem + 5.1471vw, 15rem);
    font-weight: var(--font-weight-semi-bold);
    line-height: 0.88;
    letter-spacing: -0.5rem;
    text-align: center;
    margin-bottom: var(--space-m);

    @media (${bp.min.sm}) {
      margin-bottom: 0;
      text-align: ${props => (props.layout === 'left' ? 'left' : 'right')};
    }
  }

  .era__title {
    --left-columns: 8 / span 4;
    --right-columns: 1 / span 5;
    --single-columns: 2 / span 3;

    grid-column: ${props =>
      props.layout === 'left'
        ? 'var(--left-columns)'
        : props.layout === 'single'
        ? 'var(--single-columns)'
        : 'var(--right-columns)'};
    grid-row: ${props => (props.layout === 'single' ? '4 / 5' : '2 / 3')};
    font-size: clamp(3.5rem, 3.1471rem + 1.1029vw, 5rem);
    line-height: 1;
    letter-spacing: -0.15rem;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--space-s);

    @media (${bp.min.sm}) {
      justify-content: ${props => props.layout === 'single' && 'flex-end'};
      flex-direction: ${props =>
        props.layout === 'single'
          ? 'column-reverse'
          : props.layout === 'left'
          ? 'row-reverse'
          : ''};
      margin-bottom: 0;
    }

    @media (${bp.min.sm_md}) {
      --left-columns: 8 / span 4;
      --right-columns: 2 / span 4;
      --single-columns: 3 / span 3;
    }

    span {
      padding-right: var(--space-xs);
    }

    .arrow-icon {
      display: none;
      margin-top: ${props => props.layout !== 'single' && 'var(--space-xxs)'};
      margin-bottom: ${props => props.layout === 'single' && 'var(--space-l)'};

      @media (${bp.min.sm}) {
        display: block;
        margin-right: ${props => props.layout === 'left' && '6vw'};
      }
    }
  }

  .era__image-small {
    --left-columns: 8 / span 4;
    --right-columns: 1 / span 5;

    grid-column: ${props =>
      props.layout === 'left' ? 'var(--left-columns)' : 'var(--right-columns)'};
    grid-row: 3 / 4;
    display: ${props => props.layout === 'single' && 'none'};
    margin-bottom: var(--space-s);

    @media (${bp.min.sm}) {
      margin-bottom: 0;
    }

    @media (${bp.min.sm_md}) {
      --left-columns: 8 / span 4;
      --right-columns: 2 / span 4;
    }
  }

  .era__image-large {
    --left-columns: 1 / span 7;
    --right-columns: 6 / span 7;
    --single-columns: 2 / span 8;

    grid-column: ${props =>
      props.layout === 'left'
        ? 'var(--left-columns)'
        : props.layout === 'single'
        ? 'var(--single-columns)'
        : 'var(--right-columns)'};
    grid-row: 2 / 4;
    margin-bottom: var(--space-s);

    @media (${bp.min.sm}) {
      margin-bottom: 0;
    }

    @media (${bp.min.lg}) {
      --single-columns: 3 / span 6;
    }
  }

  .era__subtitle {
    grid-column: 1 / span 6;
    grid-row: 4 / 5;
    font-size: clamp(2rem, 3vw, 3rem);
    line-height: 1.4;
    display: ${props => props.layout === 'single' && 'none'};
    margin-bottom: var(--space-l);

    @media (${bp.min.sm}) {
      margin-bottom: 0;
    }

    @media (${bp.min.sm_md}) {
      grid-column: 2 / span 5;
    }
  }

  .era__description {
    grid-column: ${props =>
      props.layout === 'single' ? '6 / span 6' : ' 7 / span 6'};
    grid-row: 4 / 5;
    font-size: 2rem;

    @media (${bp.min.sm}) {
      margin-top: 4.8rem;
    }

    @media (${bp.min.sm_md}) {
      margin-top: 9.6rem;
      grid-column: ${props =>
        props.layout === 'single' ? '7 / span 4' : '8 / span 4'};
    }

    p + p {
      margin-top: var(--space-m);
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($language: String) {
    prismicHistory(lang: { eq: $language }) {
      _previewable
      data {
        title
        hero_title
        hero_subtitle
        hero_description {
          richText
        }
        body {
          __typename
          ... on PrismicHistoryDataBodyYear {
            primary {
              year
              title
              subtitle {
                richText
              }
              description {
                richText
              }
              layout_type
              image_large {
                alt
                gatsbyImageData(width: 1000)
              }
              image_small {
                alt
                gatsbyImageData(width: 600)
              }
            }
          }
        }
        meta_description
        meta_image {
          url
        }
      }
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
    prismicDonateCallout(lang: { eq: $language }) {
      ...DonateCallout
    }
  }
`;
