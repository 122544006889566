import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AnimateFadeIn = ({ children, delay = 0.1, duration = 0.6 }) => {
  const wrapperRef = useRef(null);
  const tl = useRef();

  useEffect(() => {
    tl.current = gsap.timeline({
      defaults: { ease: 'cubic.inOut' },
      scrollTrigger: { trigger: wrapperRef.current, start: 'top bottom-=100' },
    });

    tl.current.to(wrapperRef.current, {
      autoAlpha: 1,
      delay: delay,
      duration: duration,
    });

    return () => {
      tl.current.kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper ref={wrapperRef} className="animate-fadein-wrapper">
      {children}
    </Wrapper>
  );
};

export default AnimateFadeIn;

const Wrapper = styled.div`
  opacity: 0;
  visibility: hidden;
`;
