import React from 'react';
import styled from 'styled-components';

import bp from '../../assets/js/breakpoints';

const YouTube = ({ slice }) => {
  const data = slice.primary;

  return (
    <YouTubeWrapper>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${data.youtube_id}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </YouTubeWrapper>
  );
};

export default YouTube;

/**
 * Styled Components
 *
 */
const YouTubeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(9 / 16 * 100%);
  margin: var(--space-xxl) 0;

  @media (${bp.min.sm}) {
    margin: 9.6rem 0;

    & + & {
      margin-top: -2.4rem;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
