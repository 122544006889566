import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';
import { getActiveDocLanguage, getSiteMeta } from '../assets/js/utilities';
import linkResolver from '../utilities/link-resolver';

import Seo from '../components/Seo';
import Link from '../components/Link';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import VisitTravel from '../components/VisitTravel';
import VisitMap from '../components/VisitMap';
import AmbientVideo from '../components/AmbientVideo';
import ParkEntranceAlt from '../components/ParkEntranceAlt';
import VisitHero from '../components/VisitHero';
import NewsEventsCallout from '../components/NewsEventsCallout';
import LinkCallout from '../components/LinkCallout';
import DonateCallout from '../components/DonateCallout';
import AnimateFadeIn from '../components/AnimateFadeIn';

const Visit = ({ data }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const hours = new Date().getHours();
    const isDayTime = hours > 6 && hours < 20;

    setIsDarkMode(!isDayTime);
  }, []);

  // Get global data
  const layout = data.prismicLayout;
  const news = data.allPrismicNews;
  const events = data.allPrismicEvent;
  const entrances = data.allPrismicParkEntrances.edges[0].node.data.entrance;
  const donateCallout = data.prismicDonateCallout;

  // Get page data
  const visit = data.prismicVisit;
  const {
    title,
    hero_title,
    hero_image_day,
    hero_image_night,
    getting_here_title,
    travel_mode,
    map_title,
    point_of_interest,
    callout_title,
    link_item,
    video_title,
    video_description,
    video_link_text,
    video_link_url,
    video_poster,
    video,
    meta_description,
    meta_image,
  } = visit.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(visit);
  const defaultMeta = getSiteMeta(layout.data);

  return (
    <Layout
      activeDoc={activeDoc}
      layoutData={layout.data}
      variant={isDarkMode ? 'inverted' : ''}
      hideOverflowX
    >
      <Seo
        title={title}
        description={meta_description}
        image={meta_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
      />

      <AnimateFadeIn>
        <VisitHero
          content={{ hero_title, hero_image_day, hero_image_night }}
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          lang={activeDoc.locale}
        />

        <Row center="base">
          <Anchor id="location" />
          {entrances.map((entrance, i) => (
            <Col base={12} sm={6} xl={5} key={entrance.name}>
              <ParkEntranceAlt
                content={entrance}
                key={entrance.name}
                index={i}
                isDarkMode={isDarkMode}
              />
            </Col>
          ))}
        </Row>

        <VisitTravel
          content={{ getting_here_title, travel_mode }}
          isDarkMode={isDarkMode}
        />

        <Anchor id="map" offsetHeight={8} />
        <VisitMap
          content={{ map_title, point_of_interest }}
          isDarkMode={isDarkMode}
          lang={activeDoc.locale}
        />

        <LinkCallout
          content={{ callout_title, link_item }}
          isDarkMode={isDarkMode}
          lang={activeDoc.locale}
        />

        <VideoCallout isDarkMode={isDarkMode}>
          <Row>
            <Col base={10} xs={8} xs_sm={7} sm={false}>
              <Heading level="h2" size="m" content={video_title} />
            </Col>

            <Col base={12} lg={10} lgOffset={1}>
              <VideoContainer>
                <AmbientVideo
                  src={video.url}
                  poster={video_poster.url}
                  borderRadius={5}
                />
              </VideoContainer>
            </Col>
          </Row>

          <Row>
            <Col base={false} sm={5} md={5} lg={4} mdOffset={1} lgOffset={2}>
              <Heading level="h2" size="m" content={video_title} />
            </Col>

            <Col base={12} sm={7} sm_md={6} md={5} lg={4}>
              <RichText render={video_description.richText} />
              <Link url={video_link_url}>{video_link_text}</Link>
            </Col>
          </Row>
        </VideoCallout>

        <NewsEventsCallout
          news={news}
          events={events}
          isDarkMode={isDarkMode}
          lang={activeDoc.locale}
        />

        <DonateCallout content={donateCallout.data} />
      </AnimateFadeIn>
    </Layout>
  );
};

export default withPrismicPreview(Visit, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const Anchor = styled.div`
  --height-mobile: ${props =>
    props.offsetHeight
      ? `${props.offsetHeight / 10}rem`
      : 'var(--header-height-mobile)'};
  --height-desktop: ${props =>
    props.offsetHeight
      ? `${props.offsetHeight / 10}rem`
      : 'var(--header-height-desktop)'};

  height: var(--height-mobile);
  margin-top: calc(var(--height-mobile) * -1);

  @media (${bp.min.sm}) {
    height: var(--height-desktop);
    margin-top: calc(var(--height-desktop) * -1);
  }
`;

const VideoCallout = styled.section`
  margin: 7.2rem 0;
  font-size: 2rem;

  @media (${bp.min.sm}) {
    margin: 12rem 0;
  }

  .heading-tag {
    margin-bottom: var(--space-m);
    padding-right: 4vw;
  }

  .link-tag {
    --margin-top: var(--space-l);
    margin-top: var(--margin-top);
    display: inline-block;
    color: ${props =>
      props.isDarkMode ? 'var(--color-white)' : 'var(--color-black)'};
    font-size: 2rem;
    transition: color 200ms var(--ease-quart-in-out);

    @media (${bp.min.sm_md}) {
      --margin-top: var(--space-m);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-neon-green);

        &:after {
          transform: translateX(0.4rem);
        }
      }
    }

    &:after {
      content: '→';
      display: inline-block;
      margin-left: var(--space-s);
      transition: transform 200ms var(--ease-quart-in-out);
    }
  }
`;

const VideoContainer = styled.div`
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: var(--space-m);

  @media (${bp.min.sm}) {
    margin-bottom: var(--space-xl);
  }

  @media (${bp.min.md}) {
    margin-bottom: var(--space-xxl);
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($language: String) {
    prismicVisit(lang: { eq: $language }) {
      _previewable
      data {
        title
        video_title
        video_description {
          richText
        }
        video_link_text
        video_link_url {
          link_type
          url
        }
        video_poster {
          url
        }
        video {
          url
        }
        meta_description
        meta_image {
          url
        }
      }
      ...VisitHero
      ...LinkCallout
      ...VisitTravel
      ...VisitMap
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
    allPrismicNews(
      filter: { lang: { eq: $language }, data: { post_type: { eq: "News" } } }
    ) {
      edges {
        node {
          ...NewsItems
        }
      }
    }
    allPrismicEvent(filter: { lang: { eq: $language } }) {
      edges {
        node {
          ...EventItems
        }
      }
    }
    allPrismicParkEntrances(filter: { lang: { eq: $language } }) {
      edges {
        node {
          ...ParkEntrance
        }
      }
    }
    prismicDonateCallout(lang: { eq: $language }) {
      ...DonateCallout
    }
  }
`;
