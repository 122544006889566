import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import LanguageSelector from './LanguageSelector';
import SocialLinks from './SocialLinks';

import donateCoinVideo from '../assets/video/donate-coin.mp4';
import donateCoinPoster from '../assets/images/donate-coin.png';

const DonateCircleText = () => (
  <SVGWrapper
    width="239"
    height="239"
    viewBox="0 0 239 239"
    fill="var(--color-white)"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Click here to donate today</title>
    <path d="M25.2788 142.115C23.1723 144.85 20.1082 146.504 16.0865 147.077C12.0649 147.649 8.68163 146.95 5.87287 144.978C3.06411 143.006 1.34055 140.079 0.829866 136.199C0.383018 133.145 0.766031 130.474 2.04274 128.056C3.25561 125.639 5.17068 124.048 7.66026 123.158L9.703 126.911C8.04327 127.547 6.76656 128.629 5.9367 130.283C5.04301 131.873 4.78766 133.591 5.04301 135.435C5.42602 137.916 6.57506 139.825 8.42629 141.161C10.3413 142.497 12.6394 142.942 15.3844 142.497C18.1293 142.115 20.2358 140.97 21.7041 139.189C23.1723 137.407 23.683 135.245 23.2999 132.764C23.0446 130.855 22.2786 129.265 21.0019 128.056C19.7252 126.784 18.1931 126.084 16.4057 125.957L17.2356 121.758C19.8528 121.886 22.1509 122.903 24.066 124.875C25.9172 126.847 27.1301 129.265 27.5131 132.318C28.1514 136.072 27.3854 139.38 25.2788 142.115Z" />
    <path d="M22.4062 111.071L23.8106 99.2388L27.9599 99.7477L25.981 116.033L0 112.852L0.510684 108.399L22.4062 111.071Z" />
    <path d="M29.6197 94.8494L4.53235 87.2793L5.80906 83.0171L30.8964 90.5872L29.6197 94.8494Z" />
    <path d="M30.8326 80.6633C27.4493 81.5539 24.066 81.045 20.4912 79.073C16.9165 77.1009 14.6184 74.4927 13.6608 71.2484C12.6395 67.9404 13.0863 64.6325 14.9376 61.1973C16.4058 58.5255 18.3847 56.617 20.8743 55.472C23.3 54.3269 25.7896 54.1361 28.343 54.8994L27.7046 59.1616C25.9811 58.6527 24.3214 58.7799 22.6616 59.5433C21.0019 60.2431 19.7252 61.4517 18.8315 63.1693C17.6186 65.3958 17.3633 67.6223 18.0655 69.7852C18.7677 71.9481 20.3636 73.7293 22.7893 75.0653C25.2151 76.4012 27.577 76.7828 29.8112 76.2739C32.0455 75.7014 33.769 74.3019 35.0457 72.139C35.9394 70.485 36.3224 68.7674 36.0671 66.9862C35.8118 65.205 35.0457 63.7419 33.7052 62.5332L36.9608 59.7341C38.9397 61.4517 40.1526 63.6146 40.4717 66.3501C40.7909 69.0219 40.2164 71.6937 38.7482 74.3655C36.7054 77.6098 34.152 79.7727 30.8326 80.6633Z" />
    <path d="M58.6648 48.7288L44.8763 46.1842L45.2593 52.3548L50.1108 57.1895L46.919 60.3702L28.4067 41.7948L31.5985 38.6141L40.8547 47.9018L39.7056 30.5986L43.5996 26.7817L44.5571 41.6039L62.5587 44.8483L58.6648 48.7288Z" />
    <path d="M78.9007 7.31574L82.9861 5.59814L93.1998 29.7081L89.1143 31.4257L84.8374 21.3109L73.1555 26.2093L77.4324 36.324L73.2831 38.0416L63.0695 13.9317L67.2188 12.2141L71.4957 22.3288L83.1776 17.4305L78.9007 7.31574Z" />
    <path d="M102.775 23.6646L116.372 21.8198L116.947 25.9547L98.8812 28.3721L95.3702 2.48094L112.925 0.127197L113.5 4.26214L100.413 6.04335L101.371 12.9137L112.478 11.387L112.989 15.3311L101.881 16.8578L102.775 23.6646Z" />
    <path d="M136.799 27.8632L133.352 17.7485L127.16 16.9851L125.947 26.5273L121.479 25.9548L124.671 0L135.714 1.33591C138.331 1.65398 140.31 2.67181 141.715 4.38941C143.119 6.107 143.63 8.14267 143.374 10.6236C142.928 14.0588 140.885 16.5398 137.757 17.494L141.779 28.4357L136.799 27.8632ZM128.692 4.70748L127.671 12.8501L133.927 13.6135C136.544 13.9316 138.523 12.4685 138.842 10.0511C139.161 7.63375 137.629 5.78893 134.948 5.40724L128.692 4.70748Z" />
    <path d="M152.12 27.7359L164.887 32.825L163.355 36.7055L146.439 29.9624L156.142 5.66162L172.611 12.2139L171.079 16.0944L158.823 11.1961L156.269 17.6212L166.674 21.7561L165.206 25.4458L154.801 21.3108L152.12 27.7359Z" />
    <path d="M207.465 37.7235L204.656 40.8406L198.209 35.1153L183.527 51.5278L180.144 48.5379L194.826 32.1254L188.378 26.4001L191.187 23.283L207.465 37.7235Z" />
    <path d="M195.975 59.4797C196.805 56.1081 198.911 53.3727 202.295 51.1462C205.742 48.9196 209.125 48.2199 212.508 48.856C215.955 49.5558 218.7 51.5278 220.807 54.8358C222.913 58.1438 223.616 61.4517 222.786 64.8233C222.02 68.1949 219.913 70.9303 216.466 73.1568C213.019 75.3833 209.636 76.0831 206.252 75.4469C202.869 74.7472 200.124 72.7751 198.018 69.4672C195.847 66.1592 195.209 62.8512 195.975 59.4797ZM218.317 63.6146C218.892 61.4517 218.509 59.2888 217.168 57.1895C215.828 55.0903 213.977 53.818 211.742 53.4363C209.508 53.0546 207.21 53.6271 204.848 55.0903C202.486 56.5534 201.082 58.4618 200.443 60.6883C199.869 62.8512 200.252 65.0777 201.592 67.177C202.933 69.2763 204.784 70.485 207.018 70.8667C209.253 71.2484 211.551 70.6758 213.913 69.2127C216.275 67.6859 217.743 65.8411 218.317 63.6146Z" />
    <path d="M237.404 97.712C238.106 101.656 237.468 104.964 235.553 107.763C233.638 110.562 230.701 112.343 226.807 112.98C222.913 113.616 219.594 112.98 216.785 110.944C213.976 108.908 212.253 105.918 211.551 101.911L210.019 93.0682L235.872 88.5515L237.404 97.712ZM215.572 101.084C216.019 103.692 217.232 105.664 219.083 107C220.998 108.336 223.296 108.781 225.914 108.336C228.531 107.89 230.51 106.682 231.85 104.773C233.191 102.928 233.638 100.638 233.191 98.0301L232.425 93.7043L214.87 96.7578L215.572 101.084Z" />
    <path d="M216.913 122.458C219.722 120.422 223.105 119.659 227.127 120.168C231.148 120.677 234.276 122.267 236.383 125.003C238.553 127.738 239.383 130.982 238.872 134.863C238.362 138.743 236.766 141.67 233.957 143.769C231.212 145.868 227.829 146.632 223.743 146.123C219.722 145.614 216.594 144.023 214.423 141.288C212.253 138.616 211.487 135.308 211.998 131.428C212.508 127.484 214.104 124.557 216.913 122.458ZM231.276 139.952C233.127 138.68 234.276 136.771 234.595 134.29C234.915 131.809 234.34 129.646 232.872 127.929C231.404 126.211 229.297 125.13 226.552 124.748C223.807 124.366 221.509 124.875 219.594 126.148C217.743 127.42 216.594 129.392 216.275 131.873C215.955 134.354 216.53 136.453 217.998 138.171C219.466 139.888 221.573 140.97 224.382 141.351C227.127 141.733 229.425 141.288 231.276 139.952Z" />
    <path d="M225.722 175.322L223.871 179.329L200.06 168.388L202.231 163.68L225.339 160.754L207.401 152.548L209.253 148.476L233.063 159.418L230.893 164.126L207.848 167.052L225.722 175.322Z" />
    <path d="M185.059 186.582L190.294 188.617L198.018 179.839L195.273 174.94L198.337 171.441L211.678 195.997L208.295 199.877L181.931 190.144L185.059 186.582ZM200.06 183.528L194.315 190.144L206.444 194.915L200.06 183.528Z" />
    <path d="M180.271 222.969L177.846 219.534L184.868 214.572L172.1 196.696L175.739 194.088L188.506 211.964L195.528 206.938L197.954 210.373L180.271 222.969Z" />
    <path d="M158.567 208.91L145.736 213.809L144.204 209.928L161.248 203.376L170.696 227.804L154.163 234.165L152.631 230.285L164.951 225.577L162.461 219.152L151.992 223.16L150.588 219.47L161.057 215.462L158.567 208.91Z" />
    <path d="M111.074 239L111.01 234.801L119.628 234.61L119.181 212.663L123.649 212.6L124.096 234.547L132.714 234.356L132.778 238.555L111.074 239Z" />
    <path d="M104.754 215.272C106.414 218.262 106.797 221.76 105.775 225.641C104.754 229.585 102.775 232.448 99.8386 234.229C96.8383 236.01 93.5189 236.455 89.6888 235.438C85.8587 234.483 83.1776 232.448 81.454 229.458C79.7305 226.468 79.3474 223.033 80.3688 219.089C81.3902 215.145 83.3691 212.282 86.3055 210.501C89.2419 208.719 92.6252 208.338 96.4553 209.292C100.285 210.31 103.03 212.282 104.754 215.272ZM85.5395 227.295C86.6247 229.331 88.3482 230.667 90.774 231.239C93.1997 231.875 95.434 231.557 97.349 230.285C99.2641 229.013 100.605 227.104 101.307 224.432C102.009 221.76 101.818 219.407 100.732 217.371C99.6471 215.335 97.9236 213.999 95.434 213.363C92.9444 212.727 90.8378 213.045 88.9228 214.318C87.0077 215.59 85.6672 217.498 84.965 220.234C84.2628 222.969 84.4543 225.323 85.5395 227.295Z" />
    <path d="M58.0903 221.76C54.707 219.661 52.6005 216.989 51.7706 213.681C50.9407 210.373 51.5791 207.002 53.6218 203.694C55.6646 200.386 58.4095 198.287 61.7928 197.523C65.176 196.76 68.5593 197.46 72.0703 199.623L79.7305 204.33L65.9421 226.595L58.0903 221.76ZM70.0275 203.249C67.7933 201.849 65.4952 201.404 63.261 201.913C60.9629 202.422 59.1755 203.885 57.7073 206.175C56.3029 208.401 55.8561 210.692 56.4306 212.918C56.9413 215.145 58.3457 216.989 60.6437 218.389L64.41 220.679L73.7938 205.539L70.0275 203.249Z" />
    <path d="M46.2168 177.548L43.6634 182.574L51.7067 191.098L56.8774 188.872L60.0691 192.243L34.1519 202.93L30.641 199.177L43.0251 174.113L46.2168 177.548ZM47.7489 192.752L41.7484 186.327L35.7478 197.905L47.7489 192.752Z" />
    <path d="M10.1498 168.833L30.0664 168.133L37.5352 163.871L39.7694 167.752L32.3007 172.014L21.5125 188.744L18.8952 184.228L26.9385 172.523L12.767 173.413L10.1498 168.833Z" />
  </SVGWrapper>
);

const Footer = ({ activeDoc, layoutData }) => {
  const {
    copyright,
    charitable_disclaimer,
    address_title,
    address,
    hours_and_info_title,
    hours_and_info,
    contact_title,
    contact,
    entrance_title,
    entrance,
    menu_social,
  } = layoutData;

  const lang = activeDoc.locale;

  return (
    <FooterWrapper>
      <Row center="base">
        <Col sm_md={4}>
          <Donate to={'/donate'}>
            <video
              src={donateCoinVideo}
              poster={donateCoinPoster}
              preload="auto"
              muted
              autoPlay
              playsInline
              loop
            />
            <DonateCircleText />
          </Donate>
        </Col>

        <Col base={false} sm_md={4}>
          <Section>
            <h2>{hours_and_info_title}</h2>
            <RichText render={hours_and_info.richText} />
          </Section>

          <Section>
            <h2>{entrance_title}</h2>
            <RichText render={entrance.richText} />
          </Section>
        </Col>

        <Col base={12} sm_md={4}>
          <Section>
            <h2>{contact_title}</h2>
            <RichText render={contact.richText} />
          </Section>

          <Office>
            <h2>{address_title}</h2>
            <RichText render={address.richText} />
          </Office>
        </Col>
      </Row>

      <Row>
        <Col base={12} sm_md={4} baseOrder={1} sm_mdOrder={0}>
          <Copyright>
            <p>
              © {new Date().getFullYear()} {copyright}
            </p>
            <RichText render={charitable_disclaimer.richText} />
          </Copyright>
        </Col>

        <Col base={false} sm_md={4}>
          <Translations>
            <h2>{createTranslation('Translations', lang)}</h2>
            <LanguageSelector activeDoc={activeDoc} />
          </Translations>
        </Col>

        <Col base={12} sm_md={4}>
          <Social>
            <h2>{createTranslation('Follow Along', lang)}</h2>
            <SocialLinks content={menu_social} />
          </Social>
        </Col>
      </Row>
    </FooterWrapper>
  );
};

export default Footer;

/**
 * Styled Components
 *
 */
const FooterWrapper = styled.footer`
  text-align: center;
  position: relative;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: var(--space-xl) 0 var(--space-l);

  @media (${bp.min.sm_md}) {
    text-align: left;
    padding: var(--space-xxl) 0 var(--space-xl);
  }

  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: var(--color-black);
    z-index: -1;
  }

  ${Row} + ${Row} {
    --margin-top: var(--space-xl);

    @media (${bp.min.sm_md}) {
      --margin-top: var(--space-xxl);
    }

    margin-top: var(--margin-top);
  }
`;

const Donate = styled(GatsbyLink)`
  --margin-bottom: var(--space-xl);

  @media (${bp.min.sm_md}) {
    --margin-bottom: 0;
  }

  display: grid;
  align-items: center;
  width: max-content;
  margin-bottom: var(--margin-bottom);

  &:hover {
    svg {
      transform: scale(0.85);
    }
  }

  video {
    grid-area: 1 / 1;
    width: 18rem;
    justify-self: center;
  }

  svg {
    position: relative;
    z-index: 1;
    grid-area: 1 / 1;
    transition: transform 1s var(--ease-quart-in-out);
  }
`;

const Section = styled.div`
  & + & {
    margin-top: var(--space-xl);
  }

  h2 {
    --margin-bottom: var(--space-s);

    @media (${bp.min.sm_md}) {
      --margin-bottom: var(--space-m);
    }

    font-size: 1.5rem;
    line-height: 2.4rem;
    margin-bottom: var(--margin-bottom);
  }

  p {
    --font-size: 2.4rem;

    @media (${bp.min.lg}) {
      --font-size: 3rem;
    }

    font-size: var(--font-size);
    line-height: 1.35;
    letter-spacing: -0.03rem;
  }

  a {
    color: var(--color-white);
  }
`;

const Copyright = styled.div`
  margin-top: var(--space-l);

  @media (${bp.min.sm_md}) {
    margin-top: 0;
  }

  p {
    --font-size: 1rem;
    --line-height: 1.6rem;

    @media (${bp.min.sm}) {
      --font-size: 1.2rem;
    }

    @media (${bp.min.sm_md}) {
      --font-size: 1.5rem;
      --line-height: 2.4rem;
    }

    font-size: var(--font-size);
    line-height: var(--line-height);
  }

  p + p {
    @media (${bp.min.sm_md}) {
      margin-top: var(--space-xs);
    }
  }
`;

const Office = styled(Section)`
  display: none;

  @media (${bp.min.sm_md}) {
    display: block;
  }
`;

const Translations = styled(Section)`
  h2 {
    --margin-bottom: var(--space-xxxs);

    @media (${bp.min.sm_md}) {
      --margin-bottom: var(--space-m);
    }
  }
`;

const Social = styled(Section)`
  h2 {
    --margin-bottom: var(--space-m);

    @media (${bp.min.sm_md}) {
      --margin-bottom: var(--space-xxs);
    }

    @media (${bp.min.lg}) {
      --margin-bottom: var(--space-m);
    }
  }
`;

const SVGWrapper = styled.svg`
  animation: rotate 30s infinite 0.01s forwards linear;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
