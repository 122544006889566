import React, { useState } from 'react';
import styled from 'styled-components';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import ArrowIcon from './ArrowIcon';

const NewsletterSignup = ({ activeDoc, layoutData }) => {
  const [subscriberEmail, setSubscriberEmail] = useState(null);
  const [mailchimpResponse, setMailchimpResponse] = useState(null);

  const { newsletter_text, newsletter_placeholder } = layoutData;

  const handleSubmit = async e => {
    e.preventDefault();
    const result = await addToMailchimp(subscriberEmail);
    setMailchimpResponse(result);
  };

  return (
    <NewsletterWrapper>
      {!!mailchimpResponse ? (
        <div
          className="response"
          dangerouslySetInnerHTML={{ __html: mailchimpResponse.msg }}
        />
      ) : (
        <>
          <h2>{newsletter_text}</h2>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder={newsletter_placeholder}
              onChange={e => setSubscriberEmail(e.target.value)}
            />

            <button
              type="submit"
              aria-label={createTranslation('Submit', activeDoc.locale)}
              disabled={subscriberEmail === null || subscriberEmail === ''}
            >
              <ArrowIcon width={32} />
            </button>
          </form>
        </>
      )}
    </NewsletterWrapper>
  );
};

export default NewsletterSignup;

/**
 * Styled Components
 *
 */
const NewsletterWrapper = styled.section`
  position: relative;
  color: var(--color-black);
  background-color: var(--color-neon-green-light);
  padding: 3.2rem 0 3.6rem;
  text-align: center;

  @media (${bp.min.sm}) {
    padding: 9.6rem 0 11.2rem;
  }

  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: var(--color-neon-green-light);
    z-index: -1;
  }

  p {
    margin-bottom: var(--space-m);
    font-size: 2rem;
  }

  form,
  .response {
    position: relative;
    width: 100%;
    max-width: 68rem;
    margin: 0 auto;
  }

  .response {
    a {
      font-weight: var(--font-weight-semi-bold);

      &:hover {
        color: var(--color-rail-park-green);
      }
    }
  }

  input {
    width: 100%;
    padding: var(--space-xs) 0;
    background-color: transparent;
    border: none;
    border-bottom: 0.1rem solid var(--color-rail-park-green);
    font-size: 3rem;
    text-align: center;
    margin-bottom: var(--space-xl);
    transition: border-color 200ms var(--ease-cubic-in-out);

    @media (${bp.min.sm}) {
      padding: var(--space-xxs) 0;
      text-align: left;
      margin-bottom: 0;
    }

    &:focus {
      outline: 0;
      border-bottom-color: var(--color-black);

      &::placeholder {
        opacity: 0.25;
      }
    }

    &::placeholder {
      color: var(--color-black);
      opacity: 0.5;
      transition: opacity 200ms var(--ease-cubic-in-out);
    }
  }

  button {
    position: relative;
    cursor: pointer;
    transition: transform 250ms var(--ease-cubic-in-out);

    @media (${bp.min.sm}) {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      &:hover {
        transform: translateX(0.6rem) translateY(-50%);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: -1.2rem;
      right: -1.2rem;
      bottom: -1.2rem;
      left: -1.2rem;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;
