import React from 'react';
import styled from 'styled-components';
import bp from '../../assets/js/breakpoints';

const TwoColumnText = ({ slice }) => {
  const data = slice.primary;

  return (
    <ColumnWrapper>
      <div dangerouslySetInnerHTML={{ __html: data.text.html }} />
    </ColumnWrapper>
  );
};

const ColumnWrapper = styled.div`
  width: 100%;
  margin-top: var(--space-xs);
  column-count: 2;
  column-gap: var(--space-m);

  @media (${bp.min.sm}) {
    margin-top: var(--space-xl);
  }

  a {
    color: var(--color-neon-green);

    &:hover {
      color: var(--color-neon-green-light);
    }
  }

  p {
    & + p {
      margin-top: var(--space-m);
    }
  }

  ul,
  ol {
    li + li {
      margin-top: var(--space-xxxs);
    }

    & + p {
      margin-top: var(--space-m);
    }
  }

  ul {
    list-style-position: inside;
    margin-bottom: var(--space-l);

    li {
      margin-left: var(--space-m);
      position: relative;

      &:before {
        content: '–';
        margin-left: -2.4rem;
        position: absolute;
      }
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
`;

export default TwoColumnText;
