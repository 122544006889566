import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { Row, Col } from '../components/grid-system';
import bp from '../assets/js/breakpoints';
import { getActiveDocLanguage, getSiteMeta } from '../assets/js/utilities';
import linkResolver from '../utilities/link-resolver';

import Seo from '../components/Seo';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import NewsEventsCallout from '../components/NewsEventsCallout';
import LinkColumns from '../components/LinkColumns';
import PressReleases from '../components/PressReleases';
import AnimateFadeIn from '../components/AnimateFadeIn';

const Resources = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;
  const news = data.newsArticles;
  const press = data.pressArticles;
  const events = data.allPrismicEvent;

  // Get page data
  const resources = data.prismicResources;
  const {
    title,
    hero_title,
    hero_description,
    hero_contact,
    assets,
    support_title,
    ways_to_support,
    press_title,
    community_title,
    community_members,
    meta_description,
    meta_image,
  } = resources.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(resources);
  const defaultMeta = getSiteMeta(layout.data);

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data} hideOverflowX>
      <Seo
        title={title}
        description={meta_description}
        image={meta_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
      />

      <AnimateFadeIn>
        <Row>
          <Col base={12} lg={10} lgOffset={1}>
            <Hero>
              <Heading level="h1" size="l" content={hero_title} />

              <Row>
                <Col base={12} sm={6} md={7}>
                  <div className="hero__description">
                    <RichText render={hero_description.richText} />
                  </div>
                </Col>

                <Col base={12} sm={5} md={4} smOffset={1}>
                  <div className="hero__contact">
                    <RichText render={hero_contact.richText} />
                  </div>
                </Col>
              </Row>
            </Hero>

            <Assets>
              <Row as="ul">
                {assets.map(asset => (
                  <Col base={12} sm={4} key={asset.title} as="li">
                    <a href={asset.link.url} target="_blank" rel="noreferrer">
                      <Heading level="h2" size="m" content={asset.title} />
                      <Image
                        image={asset.image}
                        borderRadius={5}
                        aspectRatio={16 / 9}
                      />
                    </a>
                  </Col>
                ))}
              </Row>
            </Assets>

            <SupportThePark>
              <Heading level="h2" size="m" content={support_title} />

              <Row>
                <Col base={12} lg={11} lgOffset={1}>
                  <ul>
                    {ways_to_support.map(item => (
                      <li key={item.title}>
                        <Image
                          image={item.image}
                          borderRadius={100}
                          aspectRatio={1 / 1}
                        />
                        <h3>{item.title}</h3>
                        <RichText render={item.description.richText} />
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </SupportThePark>

            {press.edges.length > 0 && (
              <Press>
                <PressReleases
                  press_title={press_title}
                  press={press}
                  lang={activeDoc.locale}
                />
              </Press>
            )}

            <OurCommunity>
              <LinkColumns
                title={community_title}
                listItems={community_members}
              />
            </OurCommunity>
          </Col>
        </Row>
      </AnimateFadeIn>

      <NewsEventsCallout news={news} events={events} lang={activeDoc.locale} />
    </Layout>
  );
};

export default withPrismicPreview(Resources, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const Hero = styled.section`
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);

  @media (${bp.min.sm_md}) {
    margin-bottom: 9.6rem;
  }

  .heading-tag {
    max-width: 10ch;
    margin-bottom: var(--space-m);

    @media (${bp.min.sm_md}) {
      margin-bottom: var(--space-xl);
    }
  }

  .hero__description p,
  .hero__contact a {
    font-size: clamp(2rem, 1.7647rem + 0.7353vw, 3rem);
    line-height: 1.4;
    letter-spacing: -0.03rem;
  }

  .hero__contact {
    margin-top: var(--space-l);

    @media (${bp.min.sm}) {
      margin-top: var(--space-xxxs);
    }

    @media (${bp.min.sm_md}) {
      margin-top: var(--space-xxs);
    }

    p {
      font-size: 2rem;
    }
  }
`;

const Assets = styled.section`
  margin-bottom: var(--space-xxl);

  @media (${bp.min.sm_md}) {
    margin-bottom: 9.6rem;
  }

  .heading-tag {
    margin-bottom: var(--space-s);

    @media (${bp.min.sm_md}) {
      margin-bottom: var(--space-l);
    }
  }

  .image-wrapper {
    transition: all 200ms var(--ease-cubic-in-out);
    will-change: transform;
  }

  li + li {
    margin-top: var(--space-l);

    @media (${bp.min.sm}) {
      margin-top: 0;
    }
  }

  a {
    @media (hover: hover) {
      &:hover {
        .image-wrapper {
          transform: scale(1.02);
        }
      }
    }
  }
`;

const SupportThePark = styled.section`
  margin-bottom: var(--space-xxl);

  @media (${bp.min.sm_md}) {
    margin-bottom: 12rem;
  }

  .heading-tag {
    max-width: 14ch;
    margin-bottom: var(--space-xl);

    @media (${bp.min.xs_sm}) {
      max-width: initial;
      margin-bottom: var(--space-xl);
    }

    @media (${bp.min.sm_md}) {
      margin-bottom: var(--space-xxl);
    }
  }

  .image-wrapper {
    width: 11.2rem;
    height: 11.2rem;
    margin-bottom: var(--space-m);

    @media (${bp.min.xs_sm}) {
      margin-bottom: var(--space-s);
    }
  }

  h3 {
    font-size: clamp(2rem, 1.7647rem + 0.7353vw, 3rem);
    line-height: 1.4;
    letter-spacing: -0.03rem;
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: var(--space-s);
  }

  p {
    padding-right: var(--space-m);

    @media (${bp.min.xs_sm}) {
      max-width: 40ch;
    }

    & + p {
      margin-top: var(--space-s);
    }
  }

  ul {
    @media (${bp.min.xs_sm}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--space-xxl) var(--space-m);
    }
  }

  li + li {
    border-top: 0.1rem solid var(--color-black);
    padding-top: var(--space-xl);
    margin-top: var(--space-m);

    @media (${bp.min.xs_sm}) {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }
  }
`;

const Press = styled.section`
  margin-bottom: var(--space-xxl);

  @media (${bp.min.sm_md}) {
    margin-bottom: 12rem;
  }
`;

const OurCommunity = styled.div`
  margin-bottom: var(--space-l);

  @media (${bp.min.sm_md}) {
    margin-bottom: var(--space-xxl);
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($language: String) {
    prismicResources(lang: { eq: $language }) {
      _previewable
      data {
        title
        hero_title
        hero_description {
          richText
        }
        hero_contact {
          richText
        }
        assets {
          image {
            alt
            gatsbyImageData(width: 700)
          }
          title
          link {
            url
          }
        }
        support_title
        ways_to_support {
          image {
            alt
            gatsbyImageData(width: 200)
          }
          title
          description {
            richText
          }
        }
        press_title
        community_title
        community_members {
          name
          website {
            url
          }
        }
        meta_description
        meta_image {
          url
        }
      }
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
    newsArticles: allPrismicNews(
      filter: { lang: { eq: $language }, data: { post_type: { eq: "News" } } }
    ) {
      edges {
        node {
          ...NewsItems
        }
      }
    }
    pressArticles: allPrismicNews(
      filter: { lang: { eq: $language }, data: { post_type: { eq: "Press" } } }
    ) {
      edges {
        node {
          ...PressItems
        }
      }
    }
    allPrismicEvent(filter: { lang: { eq: $language } }) {
      edges {
        node {
          ...EventItems
        }
      }
    }
  }
`;
