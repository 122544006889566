import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import linkResolver from '../../utilities/link-resolver';
import bp from '../../assets/js/breakpoints';
import { createTranslation } from '../../assets/js/utilities';

import Logo from '../Logo';
import AnimateFadeIn from '../AnimateFadeIn';

const HeaderMobile = ({
  siteTitle,
  activeDoc,
  toggleMenuIsOpen,
  menuIsOpen,
  variant,
  isOnTop,
}) => {
  return (
    <AnimateFadeIn>
      <HeaderWrapper
        menuIsOpen={menuIsOpen}
        variant={variant}
        isOnTop={isOnTop}
      >
        <MenuButton
          aria-label={createTranslation('Menu Button', activeDoc.locale)}
          onClick={toggleMenuIsOpen}
          menuIsOpen={menuIsOpen}
          variant={variant}
          isOnTop={isOnTop}
        >
          <div className="bar--top" />
          <div className="bar--bottom" />
        </MenuButton>

        <Link
          to={linkResolver({ ...activeDoc, isHomeLink: true })}
          className="logo"
          onClick={() => menuIsOpen && toggleMenuIsOpen(false)}
        >
          <span className="sr-only">{siteTitle}</span>
          <Logo />
        </Link>
      </HeaderWrapper>
    </AnimateFadeIn>
  );
};

export default HeaderMobile;

/**
 * Styled Components
 *
 */
const HeaderWrapper = styled.header`
  align-items: center;
  justify-content: space-between;
  padding: var(--space-s) 0;
  display: flex;
  z-index: 100;
  position: relative;

  @media (${bp.min.sm}) {
    display: none;
  }

  .logo {
    width: 4.4rem;
    display: flex;

    svg {
      fill: ${props =>
        props.menuIsOpen ||
        props.variant === 'inverted' ||
        (props.variant === 'fullscreen' && props.isOnTop)
          ? 'var(--color-white)'
          : 'var(--color-black)'};

      @media (hover: hover) {
        &:hover {
          fill: var(--color-rail-park-green);
        }
      }
    }
  }
`;

const MenuButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 4rem;
  height: 1.4rem;
  position: relative;
  padding: 0;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem;
  }

  @media (hover: hover) {
    &:hover {
      div {
        background-color: var(--color-neon-green);
      }
    }
  }

  .bar--top,
  .bar--bottom {
    width: 100%;
    height: 0.2rem;
    background-color: ${props =>
      props.menuIsOpen ||
      props.variant === 'inverted' ||
      (props.variant === 'fullscreen' && props.isOnTop)
        ? 'var(--color-white)'
        : 'var(--color-black)'};
    transition: all 250ms var(--ease-cubic-in-out);
  }

  .bar--top {
    ${props =>
      props.menuIsOpen &&
      `
      transform: rotate(45deg) translateY(0.9rem);
    `}
  }

  .bar--bottom {
    ${props =>
      props.menuIsOpen &&
      `
      transform: rotate(-45deg) translateY(-0.9rem);
    `}
  }
`;
