import styled from 'styled-components';

import config, { DIMENSION_NAMES } from './config';
import bp from '../../assets/js/breakpoints';

const offsetProps = DIMENSION_NAMES.map(d => d + 'Offset');
const orderProps = DIMENSION_NAMES.map(d => d + 'Order');

const Col = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: ${p => config(p).gutterWidth.mobile / 2}rem;
  padding-left: ${p => config(p).gutterWidth.mobile / 2}rem;
  order: ${p => p.order};

  @media (${bp.min.sm}) {
    padding-right: ${p => config(p).gutterWidth.desktop / 2}rem;
    padding-left: ${p => config(p).gutterWidth.desktop / 2}rem;
  }

  ${p =>
    p.reverse &&
    `
    flex-direction: column-reverse;
  `}

  ${p =>
    Object.keys(p)
      .filter(k => ~DIMENSION_NAMES.indexOf(k))
      .sort(
        (k1, k2) => DIMENSION_NAMES.indexOf(k1) - DIMENSION_NAMES.indexOf(k2)
      )
      .map(
        k =>
          config(p).media[k]`${
            Number.isInteger(p[k])
              ? // Integer value
                `
        flex-basis: ${(100 / config(p).columns) * p[k]}%;
        max-width: ${(100 / config(p).columns) * p[k]}%;
        display: block;
      `
              : // Boolean
              p[k]
              ? // Auto-width
                `
          flex-grow: 1;
          flex-basis: 0;
          max-width: 100%;
          display: block;
        `
              : // Hide element
                'display: none;'
          }`
      )}

  ${p =>
    Object.keys(p)
      .filter(k => ~offsetProps.indexOf(k))
      .map(
        k =>
          config(p).media[k.replace(/Offset$/, '')]`
        margin-left: ${(100 / config(p).columns) * p[k]}%;
      `
      )}

  ${p =>
    Object.keys(p)
      .filter(k => ~orderProps.indexOf(k))
      .map(
        k =>
          config(p).media[k.replace(/Order$/, '')]`
        order: ${p[k]}
      `
      )}
`;

Col.displayName = 'Col';

export default Col;
