import React, { useRef, useEffect } from 'react';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { gsap } from 'gsap';

import { createTranslation, toKebabCase } from '../../assets/js/utilities';
import bp from '../../assets/js/breakpoints';

import SocialLinks from '../SocialLinks';
import Link from '../Link';
import LanguageSelector from '../LanguageSelector';
import SearchBar from '../SearchBar';

const MenuMobile = ({
  content: {
    menu_mobile_main,
    menu_mobile_secondary,
    menu_social,
    copyright,
    charitable_disclaimer,
    address_title,
    address,
    contact_title,
    contact,
    entrance_title,
    entrance,
    hours_and_info_title,
    hours_and_info,
  },
  activeDoc,
  menuIsOpen,
  toggleMenuIsOpen,
}) => {
  const menuRef = useRef(null);

  useEffect(() => {
    if (!menuIsOpen) return;

    const q = gsap.utils.selector(menuRef);

    gsap.from(q('li'), {
      opacity: 0,
      y: 24,
      duration: 0.32,
      stagger: 0.02,
      ease: 'power2.out',
    });
  }, [menuIsOpen]);

  return (
    <MenuWrapper menuIsOpen={menuIsOpen} ref={menuRef}>
      <SearchWrapper>
        <SearchBar
          lang={activeDoc.locale}
          toggleMenuIsOpen={toggleMenuIsOpen}
        />
      </SearchWrapper>

      <MenuInner>
        <MenuMain>
          {menu_mobile_main.map((item, i) => (
            <li key={i}>
              <Link
                url={item.link_url}
                anchorLink={
                  item.anchor_link ? toKebabCase(item.anchor_link_hash) : null
                }
              >
                {item.link_text}
              </Link>
            </li>
          ))}
        </MenuMain>

        <MenuSecondary>
          {menu_mobile_secondary.map((item, i) => (
            <li key={i}>
              <Link
                url={item.link_url}
                anchorLink={
                  item.anchor_link ? toKebabCase(item.anchor_link_hash) : null
                }
              >
                {item.link_text}
              </Link>
            </li>
          ))}
        </MenuSecondary>

        <Section>
          <h2>{hours_and_info_title}</h2>
          <RichText render={hours_and_info.richText} />
        </Section>

        <Section>
          <h2>{entrance_title}</h2>
          <RichText render={entrance.richText} />
        </Section>

        <Section>
          <h2>{contact_title}</h2>
          <RichText render={contact.richText} />
        </Section>

        <Section>
          <h2>{address_title}</h2>
          <RichText render={address.richText} />
        </Section>

        <Translations>
          <h2>{createTranslation('Translations', activeDoc.locale)}</h2>
          <LanguageSelector activeDoc={activeDoc} />
        </Translations>

        <Social>
          <SocialLinks content={menu_social} />
        </Social>

        <Copyright>
          <p>
            © {new Date().getFullYear()} {copyright}
          </p>
          <RichText render={charitable_disclaimer.richText} />
        </Copyright>
      </MenuInner>
    </MenuWrapper>
  );
};

export default MenuMobile;

/**
 * Styled Components
 *
 */
const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
  z-index: 100;
  padding: 8.8rem 0 var(--space-xxl);
  opacity: ${props => (props.menuIsOpen ? 1 : 0)};
  pointer-events: ${props => (props.menuIsOpen ? 'auto' : 'none')};
  transition: all 100ms var(--ease-quart-in-out);

  @media (${bp.min.sm}) {
    display: none;
  }
`;

const SearchWrapper = styled.div`
  margin: var(--space-xxs) var(--space-m) 0;
  border-bottom: 0.1rem solid #262626;
  padding-bottom: var(--space-xs);

  svg {
    width: 2rem;
  }

  input {
    padding-left: 3.6rem;
  }
`;

const MenuInner = styled.div`
  height: 100%;
  padding: var(--space-s) var(--space-m) 4.8rem;
  max-width: 168rem;
  margin: 0 auto;
  border-top: 0.1rem solid rgba(255, 255, 255, 0.2);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  h2,
  a,
  p {
    color: var(--color-white);
  }

  a {
    &:hover {
      color: var(--color-neon-green);
    }
  }

  .menu__bottom-row {
    margin-top: 9.6rem;
    margin-bottom: var(--space-xxl);

    h2,
    p,
    a {
      font-size: 1.2rem;
      line-height: 2rem;
    }

    svg {
      width: 7.2rem;
      height: auto;
    }
  }
`;

const MenuMain = styled.ul`
  margin-bottom: var(--space-l);

  &:after {
    content: '';
    display: block;
    position: relative;
    width: 100vw;
    height: 0.1rem;
    background-color: var(--color-white);
    opacity: 0.2;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 4.8rem;
    left: 50%;
    right: 50%;
  }

  li {
    font-family: var(--font-family-condensed);
    font-size: 10rem;
    text-transform: uppercase;
    line-height: 0.76;
    letter-spacing: -0.1rem;
    margin-top: var(--space-m);
    text-align: center;
  }
`;

const MenuSecondary = styled.ul`
  position: relative;
  padding-bottom: var(--space-m);
  margin-bottom: var(--space-l);
  column-count: 2;

  li {
    line-height: 4rem;
    font-size: 2rem;
    text-align: center;

    & + li {
      margin-top: var(--space-xs);
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    height: 0.1rem;
    background-color: var(--color-white);
    opacity: 0.2;
    margin-left: -50vw;
    margin-right: -50vw;
    left: 50%;
    right: 50%;
    bottom: 0;
  }
`;

const Section = styled.div`
  position: relative;
  text-align: center;

  & + & {
    margin-top: var(--space-l);
  }

  &:after {
    content: '';
    display: block;
    position: relative;
    width: 100vw;
    height: 0.1rem;
    background-color: var(--color-white);
    opacity: 0.2;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: var(--space-l);
    left: 50%;
    right: 50%;
  }

  h2 {
    --margin-bottom: var(--space-s);

    font-size: 1.5rem;
    line-height: 2.4rem;
    margin-bottom: var(--margin-bottom);
  }

  p {
    --font-size: 2rem;

    font-size: var(--font-size);
    line-height: 1.35;
    letter-spacing: -0.03rem;
  }

  a,
  p {
    color: var(--color-white);
  }

  a {
    display: inline-block;

    &:hover {
      color: var(--color-neon-green);
    }
  }
`;

const Social = styled(Section)`
  margin-top: var(--space-xl) !important;
  text-align: center;

  &:after {
    display: none;
  }

  h2 {
    --margin-bottom: var(--space-m);
  }
`;

const Translations = styled(Section)`
  .language-name {
    justify-content: center;
  }
`;

const Copyright = styled.div`
  margin-top: var(--space-l);
  text-align: center;

  p {
    --font-size: 1rem;
    --line-height: 1.6rem;

    font-size: var(--font-size);
    line-height: var(--line-height);
  }
`;
