import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Keyboard } from 'swiper';
import { gsap } from 'gsap';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import Link from '../components/Link';
import ArrowIcon from '../components/ArrowIcon';
import Image from '../components/Image';
import Heading from '../components/Heading';
import SwipeIndicator from '../components/SwipeIndicator';
import AnimateImage from './AnimateImage';

import 'swiper/css';

SwiperCore.use([A11y, Keyboard]);

const Arrow = ({ orientation }) => (
  <svg
    width="54"
    height="13"
    viewBox="0 0 54 13"
    fill="#131313"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: orientation === 'left' ? 'rotate(180deg)' : '' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.8697 5.99991L47.63 1.33625L48.37 0.663574L53.37 6.16357L53.6757 6.49991L53.37 6.83625L48.37 12.3362L47.63 11.6636L51.8697 6.99991H-1.14441e-05V5.99991H51.8697Z"
    />
  </svg>
);

const Slide = ({ slide, lang }) => {
  const circleBadge = useRef(null);
  const heroText = useRef(null);
  const tl = useRef();

  useEffect(() => {
    tl.current = gsap.timeline({ defaults: { ease: 'cubic.inOut' } });

    tl.current
      .to(heroText.current, {
        autoAlpha: 1,
        delay: 0.4,
        duration: 0.7,
      })
      .from(
        heroText.current,
        {
          y: 16,
          duration: 0.5,
        },
        '<'
      );

    if (circleBadge.current) {
      tl.current.to(
        circleBadge.current,
        {
          autoAlpha: 1,
          duration: 0.6,
        },
        '>+0.05'
      );
    }

    return () => {
      tl.current.kill();
    };
  }, []);

  return (
    <Row bottom="sm" className="slide">
      <Col
        base={10}
        sm={4}
        xl={3}
        smOffset={1}
        ref={heroText}
        style={{ opacity: 0, visibility: 'hidden' }}
      >
        <Heading level="h2" size="m" content={slide.title} />

        <Link url={slide.url}>
          {slide.link_text}
          <ArrowIcon rotation={90} width={16} />
        </Link>
      </Col>

      <Col base={12} sm={6} baseOrder={-1} smOrder={1} className="column-right">
        <AnimateImage delay={0.15} fromBottom>
          <Image image={slide.image} aspectRatio={3 / 2} loading="eager" />
        </AnimateImage>

        {slide.display_circle_badge && (
          <CircleBadge className="circle-badge" ref={circleBadge}>
            <Image image={slide.circle_badge} aspectRatio={1 / 1} />
          </CircleBadge>
        )}

        <SwipeIndicator lang={lang} />
      </Col>
    </Row>
  );
};

const HomepageHero = ({ content: slides, lang }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const arrowNavigation = useRef(null);

  const handleClick = orientation => {
    if (orientation === 'next') {
      setCurrentSlide(prevState => (prevState + 1) % slides.length);
    } else if (orientation === 'prev') {
      setCurrentSlide(prevState => Math.abs((prevState - 1) % slides.length));
    }
  };

  useEffect(() => {
    gsap.to(arrowNavigation.current, {
      autoAlpha: 1,
      duration: 0.4,
      delay: 0.5,
      ease: 'cubic.inOut',
    });
  }, []);

  const desktopCarouselSlides = slides.map((slide, i) => {
    return (
      <CSSTransition
        key={currentSlide}
        classNames="carousel-slide-animation"
        timeout={700}
      >
        <Slide slide={slide} lang={lang} />
      </CSSTransition>
    );
  });

  return (
    <HeroWrapper>
      <Swiper
        className="mobile-carousel"
        spaceBetween={32}
        slidesPerView={1}
        centeredSlides={true}
        keyboard={{
          enabled: true,
        }}
        loop={false}
        a11y={true}
        watchSlidesProgress={true}
        lazy={{
          loadPrevNext: true,
          loadPrevNextAmount: slides.length,
        }}
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={slide.title}>
            <Slide slide={slide} key={i} lang={lang} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="desktop-carousel">
        <SwitchTransition>
          {desktopCarouselSlides[currentSlide]}
        </SwitchTransition>
      </div>

      {slides.length > 1 && (
        <Navigation ref={arrowNavigation}>
          <button
            aria-label={createTranslation('Next', lang)}
            className="next"
            onClick={() => {
              handleClick('next');
            }}
          >
            <Arrow orientation="right" />
          </button>

          <button
            aria-label={createTranslation('Previous', lang)}
            className="previous"
            onClick={() => {
              handleClick('prev');
            }}
          >
            <Arrow orientation="left" />
          </button>
        </Navigation>
      )}
    </HeroWrapper>
  );
};

export default HomepageHero;

/**
 * Styled Components
 *
 */
const HeroWrapper = styled.section`
  position: relative;
  margin: var(--space-xl) 0;

  @media (${bp.min.sm}) {
    margin: -2.4rem 0 9.6rem;
  }

  @media (${bp.min.md}) {
    margin: -9.6rem 0 var(--space-xxxl);
  }

  .desktop-carousel {
    display: none;

    @media (${bp.min.sm}) {
      display: block;
    }

    .heading-tag,
    .link-tag {
      will-change: transform;
    }

    .carousel-slide-animation-enter {
      .heading-tag,
      .link-tag {
        opacity: 0;
        transform: translateY(1.6rem);
      }

      .image-wrapper {
        opacity: 0;
        transform: rotate(-6deg) translate(3rem, -8rem);
        transform-origin: center center;
      }

      .circle-badge {
        opacity: 0;
      }
    }

    .carousel-slide-animation-enter-active {
      .heading-tag,
      .link-tag {
        opacity: 1;
        transform: translate(0);
        transition: all 500ms var(--ease-cubic-in-out) 200ms;
      }

      .link-tag {
        transition-delay: 280ms;
      }

      .image-wrapper {
        opacity: 1;
        transform: rotate(0) translate(0);
        transition: all 300ms var(--ease-cubic-in-out);
      }

      .circle-badge {
        transition: all 200ms var(--ease-cubic-in-out) 600ms;
      }
    }

    .carousel-slide-animation-exit {
      .heading-tag,
      .link-tag,
      .circle-badge {
        opacity: 1;
      }

      .image-wrapper {
        opacity: 1;
        transform: rotate(0) translateY(0);
      }
    }

    .carousel-slide-animation-exit-active {
      .heading-tag,
      .link-tag {
        opacity: 0;
        transform: translateY(1.6rem);
        transition: all 500ms var(--ease-cubic-in-out);
      }

      .heading-tag {
        transition-delay: 80ms;
      }

      .image-wrapper {
        opacity: 0;
        transform: rotate(-10deg) translate(-24rem, 10rem);
        transform-origin: 200% left;
        transition: all 600ms var(--ease-cubic-in-out);
      }

      .circle-badge {
        opacity: 0;
        transition: all 100ms var(--ease-cubic-in-out);
      }
    }
  }

  .mobile-carousel {
    overflow: visible;

    @media (${bp.min.sm}) {
      display: none;
    }
  }

  .column-right {
    position: relative;

    .swipe-callout {
      @media (${bp.min.sm}) {
        display: none;
      }

      margin: var(--space-s) auto;
    }
  }

  .link-tag {
    --margin-top: var(--space-m);
    --margin-bottom: var(--space-xxs);

    margin-top: var(--margin-top);
    margin-bottom: var(--margin-bottom);
    display: inline-block;
    color: var(--color-neon-green);
    font-size: 2rem;
    transition: color 200ms var(--ease-quart-in-out);

    @media (${bp.min.sm_md}) {
      font-size: 3rem;
      --margin-top: var(--space-l);
      --margin-bottom: var(--space-xxs);
    }

    @media (${bp.min.md}) {
      font-size: 3rem;
      --margin-bottom: var(--space-l);
    }

    &:hover {
      color: var(--color-black);

      .arrow-icon {
        transform: translateX(0.4rem);
      }
    }

    .arrow-icon {
      stroke: currentColor;
      margin-left: var(--space-xs);
      transition: transform 200ms var(--ease-cubic-in-out);

      @media (${bp.max.sm_md}) {
        display: none;
      }
    }
  }
`;

const CircleBadge = styled.div`
  position: absolute;
  top: -1.6rem;
  right: 5.6rem;
  width: clamp(8rem, 8vw, 12rem);
  transform: translate(0, -50%);
  animation: rotateEventText 30s infinite forwards linear;
  opacity: 0;
  visibility: hidden;

  @media (${bp.min.md}) {
    top: 8rem;
    right: 1.2rem;
    transform: translate(50%, 0);
  }

  @media (${bp.min.lg}) {
    top: 9.6rem;
  }

  @keyframes rotateCircleBadge {
    0% {
      transform: translate(50%, 0) rotate(0deg);
    }
    100% {
      transform: translate(50%, 0) rotate(360deg);
    }
  }

  .image {
    overflow: visible;
  }
`;

const Navigation = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  z-index: 5;
  pointer-events: none;
  display: none;

  @media (${bp.min.sm}) {
    display: flex;
    align-items: center;
    height: 100%;
    opacity: 0;
    visibility: hidden;
  }

  .previous,
  .next {
    position: absolute;
    height: 4.8rem;
    width: auto;
    pointer-events: auto;
    cursor: pointer;
    transition: transform 250ms var(--ease-cubic-in-out);

    &:hover {
      transform: translateX(var(--offset));
    }
  }

  .previous {
    --offset: calc(-1 * var(--space-xxxs));
    left: 0;
  }

  .next {
    --offset: var(--space-xxxs);
    right: 0;
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment HomepageHero on PrismicHomepage {
    data {
      hero_slideshow {
        title
        link_text
        url {
          url
          link_type
        }
        image {
          alt
          gatsbyImageData(width: 800)
        }
        display_circle_badge
        circle_badge {
          alt
          gatsbyImageData(width: 150)
        }
      }
    }
  }
`;
