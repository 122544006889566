import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { Row, Col } from './grid-system';
import bp from '../assets/js/breakpoints';
import { isBrowser } from '../assets/js/utilities';

import Heading from './Heading';
import Image from './Image';
import ArrowIcon from './ArrowIcon';

const ADAIcon = ({ ada_accessible, ada_description, className }) => {
  return (
    <ADAWrapper>
      <button
        className={`ada-button ${className ? className : ''}`}
        aria-labelledby={`tooltip-${
          ada_accessible ? 'accessible' : 'non-accessible'
        }`}
      >
        {ada_accessible ? (
          <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M66 33.7C66 15.9 51.5 1.5 33.7 1.5C15.9 1.5 1.5 15.9 1.5 33.7C1.5 51.5 15.9 66 33.7 66C51.5 66 66 51.5 66 33.7ZM0 33.7C0 15.1 15.1 0 33.7 0C52.3 0 67.4 15.1 67.4 33.7C67.4 52.3 52.3 67.5 33.7 67.5C15.1 67.5 0 52.3 0 33.7Z" />
            <path d="M41.8 18.3C40.2 18.3 38.8 19.6 38.8 21.3C38.8 22.9 40.1 24.3 41.8 24.3C43.4 24.3 44.8 23 44.8 21.3C44.7 19.7 43.4 18.3 41.8 18.3Z" />
            <path d="M31.4 20.5C30.4 20.5 29.3 20.9 28.5 21.7L25 25L26.8 26.9L30.3 23.5C30.6 23.2 31 23.1 31.4 23.1C31.7 23.1 31.9 23.2 32.1 23.3L34.5 24.6L26.8 31.8C26.7 31.8 26.7 31.9 26.6 31.9L28.1 33.4C29 32.9 30 32.7 31.1 32.7C34.7 32.7 37.7 35.6 37.7 39.3C37.7 40.4 37.4 41.4 37 42.3L38.5 43.8C39.3 42.5 39.8 41 39.8 39.3C39.8 37.7 39.4 36.2 38.6 34.9L42.4 34.7L41.5 43.6L44.1 43.9L45.1 33.5C45.1 33.1 45 32.7 44.7 32.5C44.5 32.2 44.1 32.1 43.8 32.1H43.7L37.5 32.5L40.8 28.2C41.7 27.1 41.3 25.5 40.1 24.9L36.9 23L33.4 21C32.7 20.7 32 20.5 31.4 20.5Z" />
            <path d="M25.3 32.9C23.6 34.5 22.5 36.7 22.5 39.3C22.5 44 26.4 47.9 31.1 47.9C33.6 47.9 35.9 46.8 37.5 45.1L36 43.6C34.8 45 33 45.8 31.1 45.8C27.5 45.8 24.5 42.9 24.5 39.2C24.5 37.2 25.4 35.5 26.7 34.3L25.3 32.9Z" />
          </svg>
        ) : (
          <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M41.8 25.8H50.6V28H44V35.7H36.3V43.4H28.6V51.1H20.1V48.9H26.4V41.2H34.1V33.5H41.8V25.8ZM20.5 34.1L34.8 19.8V25H37V16H28V18.2H33.2L19 32.5L20.5 34.1ZM67.1 33.6C67.1 52.1 52 67.2 33.5 67.2C15.1 67.1 0 52.1 0 33.6C0 15.1 15.1 0 33.6 0C52.1 0 67.1 15.1 67.1 33.6ZM65.7 33.6C65.7 15.9 51.3 1.5 33.6 1.5C15.9 1.5 1.5 15.9 1.5 33.6C1.5 51.3 15.9 65.7 33.6 65.7C51.3 65.7 65.7 51.3 65.7 33.6Z" />
          </svg>
        )}

        <span
          className="ada-tooltip"
          id={`tooltip-${ada_accessible ? 'accessible' : 'non-accessible'}`}
        >
          {ada_description.text}
        </span>
      </button>
    </ADAWrapper>
  );
};

const ParkEntrance = ({ content, variant }) => {
  const videoEl = useRef(null);

  const {
    name,
    location,
    link_text,
    google_maps_url,
    ada_accessible,
    ada_description,
    image,
    video,
  } = content;

  const isRight = variant === 'right';
  const isMobile = isBrowser && window.innerWidth < 750;

  function handleMouseEnter() {
    if (videoEl.current === null) return;

    videoEl.current.currentTime = 0;

    if (videoEl.current.paused) {
      let playPromise = videoEl.current.play();

      if (playPromise !== undefined) {
        playPromise.then(_ => {
          videoEl.current.play();
        });
      }
    }
  }

  function handleMouseLeave() {
    if (videoEl.current === null) return;

    if (!videoEl.current.paused) {
      videoEl.current.pause();
    }
  }

  return (
    <Row>
      <Col base={12} xl={10} xlOffset={1}>
        <EntranceWrapper
          isRight={isRight}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Row>
            <Col base={12} sm_md={6} lg={5}>
              <MediaContainer>
                <Image image={image} aspectRatio={16 / 9} />

                {!isMobile && (
                  <video
                    ref={videoEl}
                    src={video.url}
                    poster={image.url}
                    preload="none"
                    loop
                    muted
                    playsInline
                  />
                )}
              </MediaContainer>
            </Col>

            <Col
              base={12}
              sm_md={6}
              xl={isRight ? 6 : 7}
              xlOffset={isRight ? 1 : 0}
              sm_mdOrder={isRight ? -1 : 0}
              className="entrance-info"
            >
              <ArrowIcon width={48} rotation={isRight ? 90 : `270`} />

              <ADAIcon
                ada_accessible={ada_accessible}
                ada_description={ada_description}
                className="ada--mobile"
              />

              <Row className="row-1" bottom="xs">
                <Col base={12} sm_md={6} xl={isRight ? 7 : 4}>
                  <Heading level="h3" size="m" content={name} />
                </Col>

                <Col base={false} sm_md={2}>
                  <ADAIcon
                    ada_accessible={ada_accessible}
                    ada_description={ada_description}
                  />
                </Col>
              </Row>

              <Row className="row-2">
                <Col base={8} xs={6} xl={isRight ? 5 : 4}>
                  <p>{location}</p>
                </Col>

                <Col base={4} xs={6} xl={isRight ? 5 : 4}>
                  <a
                    href={google_maps_url}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={`${link_text} to ${name}`}
                    className="link-text"
                  >
                    {link_text}
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </EntranceWrapper>
      </Col>
    </Row>
  );
};

export default ParkEntrance;

/**
 * Styled Components
 *
 */
const EntranceWrapper = styled.div`
  margin-bottom: 8rem;

  @media (${bp.min.md}) {
    margin-bottom: 12rem;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-black);

      .arrow-icon {
        stroke: var(--color-neon-green);
        transform: translateY(-0.4rem);

        @media (${bp.min.sm_md}) {
          transform: translateX(
            ${props => (props.isRight ? '0.8rem' : '-0.8rem')}
          );
        }
      }

      video {
        opacity: 1;
      }
    }
  }

  .arrow-icon {
    width: 4rem;
    height: auto;
    transition: all 200ms var(--ease-cubic-in-out);

    path {
      @media (${bp.max.sm_md}) {
        transform: none;
      }
    }

    @media (${bp.min.sm}) {
      width: 4.8rem;
    }
  }

  .entrance-info {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: var(--space-s);

    @media (${bp.min.sm}) {
      margin-top: var(--space-l);
    }

    @media (${bp.min.sm_md}) {
      margin-top: 0;
    }

    .row-1 {
      margin-top: var(--space-m);
      margin-bottom: var(--space-s);

      @media (${bp.min.sm}) {
        margin-top: var(--space-l);
      }

      @media (${bp.min.sm_md}) {
        margin-top: var(--space-xl);
        margin-bottom: auto;
      }

      @media (${bp.min.md}) {
        margin-top: 4.8rem;
      }
    }

    .row-2 {
      align-items: baseline;

      div:last-of-type {
        display: flex;
        justify-content: flex-end;

        @media (${bp.min.sm_md}) {
          display: block;
        }
      }
    }
  }

  p {
    line-height: 1;
  }

  .link-text {
    font-size: 1.3rem;
    line-height: 1;
    color: var(--color-black);
    transition: color 200ms var(--ease-quart-in-out);
    text-align: right;
    white-space: nowrap;
    display: flex;

    @media (${bp.min.xs}) {
      font-size: 1.7rem;
    }

    @media (${bp.min.sm}) {
      font-size: 2rem;
    }

    @media (${bp.min.sm_md}) {
      text-align: left;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-neon-green);

        &:after {
          transform: translate(0.2rem, -0.2rem);
        }
      }
    }

    &:after {
      content: '↗';
      display: inline-block;
      margin-left: 0.2rem;
      transition: transform 200ms var(--ease-quart-in-out);

      @media (${bp.min.xs}) {
        margin-left: var(--space-xxxs);
      }
    }
  }
`;

const MediaContainer = styled.div`
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 101%;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    transition: opacity 400ms var(--ease-cubic-in-out);
  }
`;

const ADAWrapper = styled.div`
  .ada--mobile {
    display: block;
    right: 0.4rem;

    @media (${bp.min.sm}) {
      right: 1.2rem;
    }

    @media (${bp.min.sm_md}) {
      display: none;
    }
  }

  .ada--mobile,
  .ada-button {
    position: absolute;
    top: 0;

    @media (${bp.min.sm_md}) {
      position: relative;
    }

    svg {
      width: 4.4rem;
      height: 4.4rem;

      @media (${bp.min.sm}) {
        width: 4.8rem;
        height: 4.8rem;
      }

      @media (${bp.min.sm_md}) {
        width: 6.8rem;
        height: 6.8rem;
        position: relative;
      }
    }
  }

  .ada-button {
    cursor: pointer;
    color: var(--color-black);

    &:hover,
    &:focus {
      color: var(--color-neon-green);

      .ada-tooltip {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .ada--mobile {
      right: 0.4rem;

      @media (${bp.min.sm}) {
        right: 1.2rem;
      }
    }

    svg {
      max-width: 100%;
      transition: all 200ms var(--ease-cubic-in-out);
    }
  }

  .ada-tooltip {
    position: absolute;
    bottom: 50%;
    right: 130%;
    width: 22rem;
    height: fit-content;
    font-size: 1.5rem;
    color: var(--color-white);
    padding: var(--space-xxs);
    background-image: linear-gradient(
      180deg,
      var(--color-neon-green-light) -40.64%,
      var(--color-neon-green) 102.15%
    );
    border-radius: 0.4rem 0.4rem 0 0.4rem;
    transform: translateX(0.4rem);
    opacity: 0;
    pointer-events: none;
    transition: all 300ms var(--ease-cubic-in-out);
    z-index: 5;

    @media (${bp.min.sm_md}) {
      width: 24rem;
      font-size: 2rem;
      bottom: 50%;
      right: 125%;
    }

    @media (${bp.min.lg}) {
      width: 28rem;
      left: 120%;
      right: initial;
      transform: translateX(-0.4rem);
      border-radius: 0.4rem 0.4rem 0.4rem 0;
    }

    &:before {
      --size: 0.8rem;

      content: '';
      position: absolute;
      right: calc(var(--size) * -1);
      bottom: 0;
      width: 0;
      height: 0;
      z-index: 5;
      border-style: solid;
      border-width: var(--size) 0 0 var(--size);
      border-color: transparent transparent transparent var(--color-neon-green);

      @media (${bp.min.lg}) {
        left: calc(var(--size) * -1);
        border-width: 0 0 var(--size) var(--size);
        border-color: transparent transparent var(--color-neon-green)
          transparent;
      }
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  fragment ParkEntrance on PrismicParkEntrances {
    data {
      entrance {
        name
        location
        link_text
        google_maps_url
        ada_accessible
        ada_description {
          text
        }
        image {
          alt
          url
          gatsbyImageData(width: 1000)
        }
        video {
          url
        }
      }
    }
  }
`;
