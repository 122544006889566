import React from 'react';
import styled from 'styled-components';
import linkResolver from '../../utilities/link-resolver';
import { Link as GatsbyLink } from 'gatsby';

import { Row, Col } from '../grid-system';
import bp from '../../assets/js/breakpoints';
import { createTranslation } from '../../assets/js/utilities';

import LanguageSelector from '../LanguageSelector';
import Logo from '../Logo';
import AnimateFadeIn from '../AnimateFadeIn';
import SearchBar from '../SearchBar';

const HeaderDesktop = ({
  siteTitle,
  activeDoc,
  content,
  toggleMenuIsOpen,
  menuIsOpen,
  variant,
  isOnTop,
}) => {
  return (
    <Container variant={variant}>
      <AnimateFadeIn>
        <Row center="xs">
          <Col base={12} lg={10}>
            <HeaderWrapper
              variant={variant}
              isOnTop={isOnTop}
              className="header-desktop"
            >
              <MenuButton
                aria-label={createTranslation('Menu Button', activeDoc.locale)}
                onClick={toggleMenuIsOpen}
                menuIsOpen={menuIsOpen}
                variant={variant}
                isOnTop={isOnTop}
              >
                <div className="bar--top" />
                <div className="bar--bottom" />
              </MenuButton>

              <NavCenter
                menuIsOpen={menuIsOpen}
                variant={variant}
                isOnTop={isOnTop}
              >
                <GatsbyLink
                  activeClassName="is-active"
                  to={content[0].link_url.url}
                >
                  {content[0].link_text}
                </GatsbyLink>

                <GatsbyLink
                  to={linkResolver({ ...activeDoc, isHomeLink: true })}
                  className="logo"
                >
                  <span className="sr-only">{siteTitle}</span>
                  <Logo />
                </GatsbyLink>

                <GatsbyLink
                  activeClassName="is-active"
                  to={content[1].link_url.url}
                >
                  {content[1].link_text}
                </GatsbyLink>
              </NavCenter>

              <SearchBarWrapper menuIsOpen={menuIsOpen}>
                <SearchBar
                  lang={activeDoc.locale}
                  toggleMenuIsOpen={toggleMenuIsOpen}
                />
              </SearchBarWrapper>

              <NavRight
                menuIsOpen={menuIsOpen}
                variant={variant}
                isOnTop={isOnTop}
              >
                <GatsbyLink
                  to={content[2].link_url.url}
                  className="donate-button"
                >
                  {content[2].link_text}
                </GatsbyLink>

                <div className="language-selector">
                  <LanguageSelector
                    activeDoc={activeDoc}
                    menuIsOpen={menuIsOpen}
                    displayLangCode
                  />
                </div>
              </NavRight>
            </HeaderWrapper>
          </Col>
        </Row>
      </AnimateFadeIn>
    </Container>
  );
};

export default HeaderDesktop;

/**
 * Styled Components
 *
 */
const Container = styled.header`
  display: none;
  z-index: 100;
  position: relative;

  @media (${bp.min.sm}) {
    display: block;
  }
`;

const SearchBarWrapper = styled.div`
  width: 49.5%;
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  display: none;
  transition: all 100ms var(--ease-cubic-in-out);

  ${props =>
    props.menuIsOpen &&
    `
    opacity: 1;
    pointer-events: auto;
    transition: all 500ms var(--ease-cubic-in-out);
  `}

  @media (${bp.min.sm}) {
    display: block;
  }
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  padding: 2.8rem 0 4rem;
  align-items: center;

  @media (${bp.max.sm_md}) {
    grid-template-columns: 2fr 2.5fr 2fr;
  }

  a {
    color: ${props =>
      props.variant === 'inverted' ||
      (props.variant === 'fullscreen' && props.isOnTop)
        ? 'var(--color-white)'
        : 'var(--color-black)'};

    &:hover {
      color: var(--color-neon-green);
    }
  }
`;

const MenuButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 4rem;
  height: 1.4rem;
  position: relative;
  padding: 0;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: -1.2rem;
    right: -1.2rem;
    bottom: -1.2rem;
    left: -1.2rem;
  }

  &:hover {
    div {
      background-color: var(--color-neon-green);
    }
  }

  .bar--top,
  .bar--bottom {
    width: 100%;
    height: 0.2rem;
    background-color: ${props =>
      props.variant === 'inverted' ||
      (props.variant === 'fullscreen' && props.isOnTop)
        ? 'var(--color-white)'
        : 'var(--color-black)'};
    transition: all 250ms var(--ease-cubic-in-out);

    ${props =>
      props.menuIsOpen &&
      `
      background-color: var(--color-white);
    `}
  }

  .bar--top {
    ${props =>
      props.menuIsOpen &&
      `
      transform: rotate(45deg) translateY(0.9rem);
    `}
    transition-delay: 0ms;
  }

  .bar--bottom {
    ${props =>
      props.menuIsOpen &&
      `
      transform: rotate(-45deg) translateY(-0.9rem);
    `}
    transition-delay: 30ms;
  }
`;

const NavCenter = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  will-change: transform;
  transition: all 200ms var(--ease-cubic-in-out);

  ${props =>
    props.menuIsOpen &&
    `
    opacity: 0;
    transform: translateY(-1.2rem) rotateX(-50deg);
    pointer-events: none;
  `}

  a {
    display: flex;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    @media (${bp.min.sm}) {
      font-size: 1.8rem;
    }

    &.is-active {
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 0.1rem;
        background-color: var(--color-neon-green);
        position: absolute;
        left: 0;
        bottom: -0.2rem;
      }
    }
  }

  .logo {
    width: 4.4rem;

    @media (${bp.min.sm}) {
      width: 4.8rem;
    }

    &:hover {
      svg {
        fill: var(--color-rail-park-green);
      }
    }

    svg {
      fill: ${props =>
        props.variant === 'inverted' ||
        (props.variant === 'fullscreen' && props.isOnTop)
          ? 'var(--color-white)'
          : 'var(--color-black)'};
    }
  }
`;

const NavRight = styled.div`
  justify-self: flex-end;
  position: relative;

  .language-selector {
    justify-self: center;
    position: absolute;
    bottom: calc(var(--space-xl) * -1);
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 200ms var(--ease-cubic-in-out);

    ${props =>
      props.menuIsOpen &&
      `
      opacity: 0;
      pointer-events: none;
    `}

    li:before {
      background-color: ${props =>
        props.variant === 'inverted' ||
        (props.variant === 'fullscreen' && props.isOnTop)
          ? 'var(--color-white)'
          : 'var(--color-black)'} !important;
    }
  }

  .donate-button {
    padding: var(--space-xxs) var(--space-m);
    font-size: 1.5rem;
    line-height: 2.4rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    border-radius: 4.8rem;
    color: ${props =>
      props.variant === 'inverted' ||
      (props.variant === 'fullscreen' && props.isOnTop)
        ? 'var(--color-white)'
        : 'var(--color-black)'};
    border: 0.1rem solid
      ${props =>
        props.variant === 'inverted' ||
        (props.variant === 'fullscreen' && props.isOnTop)
          ? 'var(--color-white)'
          : 'var(--color-black)'};

    ${props =>
      props.menuIsOpen &&
      `
      color: var(--color-white);
      background-color: var(--color-neon-green);
      border-color: var(--color-neon-green);
    `}

    @media (${bp.min.sm}) {
      font-size: 1.7rem;
      padding: var(--space-xs) var(--space-l);
    }

    &:hover {
      border-color: var(--color-neon-green);
      background-color: var(--color-neon-green);
      color: var(--color-white);

      ${props =>
        props.menuIsOpen &&
        `
      background-color: transparent;
    `}
    }
  }
`;
