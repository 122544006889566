import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Keyboard } from 'swiper';
import { gsap } from 'gsap';

import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import Image from '../components/Image';
import SwipeIndicator from '../components/SwipeIndicator';
import FeaturedEventCallout from '../components/FeaturedEventCallout';
import AnimateImage from './AnimateImage';

import 'swiper/css';

SwiperCore.use([A11y, Keyboard]);

const Arrow = ({ orientation }) => (
  <svg
    width="54"
    height="13"
    viewBox="0 0 54 13"
    fill="#131313"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: orientation === 'left' ? 'rotate(180deg)' : '' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.8697 5.99991L47.63 1.33625L48.37 0.663574L53.37 6.16357L53.6757 6.49991L53.37 6.83625L48.37 12.3362L47.63 11.6636L51.8697 6.99991H-1.14441e-05V5.99991H51.8697Z"
    />
  </svg>
);

const Slide = ({ slide, lang }) => {
  const calloutRef = useRef(null);

  useEffect(() => {
    gsap.from(calloutRef.current, {
      opacity: 0,
      y: 32,
      duration: 0.5,
      delay: 0.3,
      ease: 'cubic.inOut',
    });
  }, []);

  return (
    <FeaturedEvent>
      <AnimateImage duration={0.8}>
        <Image
          aspectRatio={16 / 9}
          image={slide.data.featured_image}
          loading="eager"
        />
      </AnimateImage>

      <FeaturedEventCallout
        content={slide}
        lang={lang}
        noImage
        fullWidth
        noBadge
        ref={calloutRef}
      />
    </FeaturedEvent>
  );
};

const FeaturedEventHero = ({ content: events, lang }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const heroWrapper = useRef(null);
  const [heroWrapperHeight, setHeroWrapperHeight] = useState('100%');

  useEffect(() => {
    setHeroWrapperHeight(heroWrapper.current.clientHeight + 160);
  }, [heroWrapper]);

  const handleClick = orientation => {
    if (orientation === 'next') {
      setCurrentSlide(prevState => (prevState + 1) % events.length);
    } else if (orientation === 'prev') {
      setCurrentSlide(prevState => Math.abs((prevState - 1) % events.length));
    }
  };

  const carouselSlides = events.map(event => {
    return (
      <CSSTransition
        key={currentSlide}
        classNames="carousel-slide-animation"
        timeout={800}
      >
        <Slide slide={event} lang={lang} />
      </CSSTransition>
    );
  });

  return (
    <HeroWrapper ref={heroWrapper}>
      <FeaturedEventBackground
        bgColor={events[currentSlide].data.featured_background_color}
        style={{ height: heroWrapperHeight }}
      />

      <div className="mobile-carousel">
        <Swiper
          spaceBetween={32}
          slidesPerView={1}
          keyboard={{
            enabled: true,
          }}
          loop={events.length > 1 ? true : false}
          a11y={true}
          className="swiper"
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: events.length,
          }}
        >
          {events.map((event, i) => (
            <SwiperSlide key={i}>
              <Slide slide={event} key={i} lang={lang} />
            </SwiperSlide>
          ))}
        </Swiper>

        {events.length > 1 && <SwipeIndicator lang={lang} />}
      </div>

      <div className="desktop-carousel">
        <SwitchTransition>{carouselSlides[currentSlide]}</SwitchTransition>

        {events.length > 1 && (
          <Navigation>
            <button
              aria-label={createTranslation('Next', lang)}
              className="next"
              onClick={() => {
                handleClick('next');
              }}
            >
              <Arrow orientation="right" />
            </button>

            <button
              aria-label={createTranslation('Previous', lang)}
              className="previous"
              onClick={() => {
                handleClick('prev');
              }}
            >
              <Arrow orientation="left" />
            </button>
          </Navigation>
        )}
      </div>
    </HeroWrapper>
  );
};

export default FeaturedEventHero;

/**
 * Styled Components
 *
 */
const HeroWrapper = styled.section`
  .desktop-carousel {
    display: none;
    position: relative;

    @media (${bp.min.sm}) {
      display: block;
    }

    .carousel-slide-animation-enter {
      .featured-event-wrapper {
        opacity: 0;
        transform: translateY(3.2rem);
      }

      .image-wrapper {
        opacity: 0;
        transform: rotate(-6deg) translate(3rem, -8rem);
        transform-origin: center center;
      }
    }

    .carousel-slide-animation-enter-active {
      .featured-event-wrapper {
        opacity: 1;
        transform: translate(0);
        transition: all 500ms var(--ease-cubic-in-out) 200ms;
      }

      .image-wrapper {
        opacity: 1;
        transform: rotate(0) translate(0);
        transition: all 700ms var(--ease-cubic-in-out);
      }
    }

    .carousel-slide-animation-exit {
      .featured-event-wrapper {
        opacity: 1;
      }

      .image-wrapper {
        opacity: 1;
        transform: rotate(0) translateY(0);
      }
    }

    .carousel-slide-animation-exit-active {
      .featured-event-wrapper {
        opacity: 0;
        transform: translateY(8rem);
        transition: all 500ms var(--ease-cubic-in-out);
      }

      .image-wrapper {
        opacity: 0;
        transform: rotate(0) translate(0, 10rem);
        transform-origin: 200% left;
        transition: all 700ms var(--ease-cubic-in-out) 100ms;
      }
    }
  }

  .mobile-carousel {
    @media (${bp.min.sm}) {
      display: none;
    }

    .swiper {
      overflow: visible;
    }
  }

  .swipe-callout {
    position: relative;
    top: -7.2rem;

    @media (${bp.min.xs_sm}) {
      display: none;
    }
  }
`;

const FeaturedEvent = styled.section`
  margin-top: var(--space-l);
  margin-bottom: 9.6rem;

  @media (${bp.min.sm}) {
    margin-top: 0;
    margin-bottom: var(--space-xxl);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--space-m);
  }

  .image-wrapper,
  .animate-image-wrapper {
    grid-column: 2 / span 9;
    grid-row: 1 / 3;

    @media (${bp.min.sm}) {
      border-radius: 5rem;
    }

    @media (${bp.min.lg}) {
      grid-column: 2 / span 8;
    }

    img {
      @media (${bp.min.sm}) {
        border-radius: 0.5rem;
      }
    }
  }

  .featured-event-wrapper {
    grid-column: 4 / span 8;
    grid-row: 2 / 4;
    z-index: 10;

    @media (${bp.min.sm}) {
      grid-column: 5 / span 7;
      border-radius: 0.5rem;
    }

    @media (${bp.min.sm_md}) {
      grid-column: 6 / span 6;
    }

    @media (${bp.min.lg}) {
      grid-column: 7 / span 5;
    }
  }
`;

const FeaturedEventBackground = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#f1f1f1')};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: background-color 400ms var(--ease-cubic-in-out) 100ms;

  @media (${bp.min.sm}) {
    transition: background-color 400ms var(--ease-cubic-in-out) 600ms;
  }
`;

const Navigation = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  top: -10%;
  z-index: 5;
  pointer-events: none;
  display: none;

  @media (${bp.min.sm}) {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .previous,
  .next {
    position: absolute;
    height: 4.8rem;
    width: auto;
    pointer-events: auto;
    cursor: pointer;
    transition: transform 250ms var(--ease-cubic-in-out);

    &:hover {
      transform: translateX(var(--offset));
    }
  }

  .previous {
    --offset: calc(-1 * var(--space-xxxs));
    left: 0;
  }

  .next {
    --offset: var(--space-xxxs);
    right: 0;
  }
`;
