import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard } from 'swiper';

import bp from '../assets/js/breakpoints';

import Image from './Image';
import Heading from './Heading';
import SwipeIndicator from '../components/SwipeIndicator';

import 'swiper/css';

SwiperCore.use([Keyboard]);

const DonationCard = ({ level, index, donationPluginIds }) => {
  return (
    <a
      className="level kindful-donate-btn"
      id={`kindful-donate-btn-${donationPluginIds[index]}`}
      href="#!"
    >
      <div className="level__amount">
        <Image image={level.image} aspectRatio={16 / 9} />
        <h3>${level.amount}</h3>
      </div>

      <div className="level__overview">
        <RichText render={level.description.richText} />
        <div className="level__button">{level.button_text}</div>
      </div>
    </a>
  );
};

const DonationLevels = ({
  content: { donations_title, donations_description, donation_levels },
  donationPluginIds,
  lang,
}) => {
  return (
    <DonationSection>
      <div id="donations" />
      <Heading level="h2" size="m" content={donations_title} />
      <RichText render={donations_description.richText} />

      <MobileDonationLevels>
        <Swiper
          className="mobile-carousel"
          spaceBetween={24}
          slidesPerView={1.2}
          centeredSlides={true}
          loop={false}
          keyboard={{
            enabled: true,
          }}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: donation_levels.length,
          }}
        >
          {donation_levels.map((level, i) => (
            <SwiperSlide key={level.amount}>
              <DonationCard
                level={level}
                index={i}
                donationPluginIds={donationPluginIds.donationsMobile}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <SwipeIndicator lang={lang} />
      </MobileDonationLevels>

      <DesktopDonationLevels>
        {donation_levels.map((level, i) => (
          <DonationCard
            level={level}
            index={i}
            donationPluginIds={donationPluginIds.donationsDesktop}
            key={level.amount}
          />
        ))}
      </DesktopDonationLevels>
    </DonationSection>
  );
};

export default DonationLevels;

/**
 * Styled Components
 *
 */
const DonationSection = styled.section`
  margin: var(--space-xxl) 0 var(--space-xxl);
  text-align: center;

  #donations {
    height: var(--header-height-mobile);
    margin-top: calc(var(--header-height-mobile) * -1);

    @media (${bp.min.sm}) {
      height: var(--header-height-desktop);
      margin-top: calc(var(--header-height-desktop) * -1);
    }
  }

  @media (${bp.min.sm}) {
    margin: var(--space-xxl) 0 var(--space-xxxl);
  }

  & > p:first-of-type {
    max-width: 55ch;
    line-height: 1.3;
    font-size: 1.7rem;
    margin: var(--space-l) auto var(--space-xl);

    @media (${bp.min.sm}) {
      margin: var(--space-l) auto var(--space-xxl);
      font-size: 2rem;
    }
  }
`;

const MobileDonationLevels = styled.div`
  @media (${bp.min.sm_md}) {
    display: none;
  }

  .mobile-carousel {
    overflow: visible;

    &:focus {
      outline: 0;
    }

    .swiper-wrapper {
      box-sizing: border-box;
    }
  }

  .swipe-callout {
    margin: var(--space-m) auto 0;
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    .level {
      transform: scale(1.05);
      border-color: var(--color-neon-green);
      box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.1),
        0px 8px 24px rgba(17, 17, 26, 0.1), 0px 16px 48px rgba(17, 17, 26, 0.1);

      .level__button {
        color: var(--color-white);
        background-color: var(--color-neon-green);
        border-color: var(--color-neon-green);
      }
    }
  }

  .kindful-donate-btn {
    &:hover {
      color: var(--color-black);
    }
  }

  .level {
    display: block;
    width: 100%;
    border-radius: 1rem;
    background-color: var(--color-white);
    overflow: hidden;
    border: 0.1rem solid #e1dfdf;
    cursor: pointer;
    backface-visibility: hidden;
    z-index: 1;
  }

  .level__amount {
    display: grid;
    align-items: center;

    .image-wrapper,
    h3 {
      grid-area: 1 / 1;
      will-change: transform;
    }

    h3 {
      font-size: 8rem;
      font-weight: var(--font-weight-semi-bold);
      color: var(--color-white);
      z-index: 5;
    }
  }

  .level__overview {
    padding: var(--space-m) var(--space-m);
  }

  .level__button {
    margin-top: var(--space-m);
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    border: 0.1rem solid var(--color-black);
    padding: var(--space-xs) var(--space-m);
    border-radius: 4rem;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.05rem;
    cursor: pointer;
    transition: all 200ms var(--ease-quart-in-out);
  }
`;

const DesktopDonationLevels = styled.div`
  display: none;

  @media (${bp.min.sm_md}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kindful-donate-btn {
    &:hover {
      color: var(--color-black);
    }
  }

  .level {
    width: 100%;
    max-width: 36rem;
    margin: 0 var(--space-xs);
    border-radius: 1rem;
    background-color: var(--color-white);
    overflow: hidden;
    z-index: 1;
    border: 0.1rem solid #e1dfdf;
    cursor: pointer;
    backface-visibility: hidden;
    will-change: transform;
    transition: transform 200ms var(--ease-cubic-in-out),
      border-color 200ms var(--ease-cubic-in-out),
      opacity 200ms var(--ease-cubic-in-out),
      box-shadow 200ms var(--ease-cubic-in-out);

    .image img {
      transition: all 300ms var(--ease-cubic-in-out);
    }

    @media (${bp.min.sm_md}) {
      transition: all 300ms var(--ease-cubic-in-out);

      &:hover {
        transform: scale(1.05);
        border-color: var(--color-neon-green);
        box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.1),
          0px 8px 24px rgba(17, 17, 26, 0.1),
          0px 16px 48px rgba(17, 17, 26, 0.1);

        .level__button {
          color: var(--color-white);
          background-color: var(--color-neon-green);
          border-color: var(--color-neon-green);
        }

        .image img {
          transform: scale(1.04);
        }
      }
    }
  }

  .level__amount {
    display: grid;
    align-items: center;
    justify-content: center;

    .image-wrapper,
    h3 {
      grid-area: 1 / 1;
    }

    h3 {
      font-size: 8rem;
      font-weight: var(--font-weight-semi-bold);
      color: var(--color-white);
      z-index: 5;
    }
  }

  .level__overview {
    padding: var(--space-m) var(--space-m);
  }

  .level__button {
    margin-top: var(--space-m);
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    border: 0.1rem solid var(--color-black);
    padding: var(--space-xs) var(--space-m);
    border-radius: 4rem;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.05rem;
    cursor: pointer;
    transition: all 200ms var(--ease-quart-in-out);
  }
`;

/**
 * GraphQL Query
 *
 */
export const query = graphql`
  fragment DonationLevels on PrismicDonate {
    data {
      donations_title
      donations_description {
        richText
      }
      donation_levels {
        amount
        description {
          richText
        }
        button_text
        image {
          alt
          gatsbyImageData(width: 600)
        }
      }
    }
  }
`;
