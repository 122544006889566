import React from 'react';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';
import { createTranslation } from '../assets/js/utilities';

import Heading from '../components/Heading';

const LocationLink = ({ url, children, variant }) => (
  <>
    {url && variant !== 'list-view' ? (
      <a href={url} target="_blank" rel="noreferrer">
        {children}
      </a>
    ) : (
      <span>{children}</span>
    )}
  </>
);

const EventDetails = ({ event, locale, variant }) => {
  const {
    title,
    start_day,
    start_time,
    end_time,
    all_day,
    cost,
    recurring_event,
    frequency,
    interval,
    location,
    isPastEvent,
  } = event;

  const eventTimes = `${start_time}${
    end_time && start_time !== end_time ? ` - ${end_time}` : ''
  }`;

  // Combine days and times into a date string. If it's an all day event,
  //  only return the days
  const eventDateString = `${start_day} @ ${eventTimes}`;
  const allDayEventDateString = `${start_day}`;

  return (
    <EventDetailsWrapper variant={variant}>
      {recurring_event && !isPastEvent && (
        <div className="event__badge event__badge--recurring">
          Recurs every {interval > 1 ? interval : ''} {frequency}
          {interval > 1 ? 's' : ''}
        </div>
      )}

      {isPastEvent && (
        <div className="event__badge event__badge--past">Past event</div>
      )}

      <div className="event__date">
        {all_day ? allDayEventDateString : eventDateString}
      </div>

      <Heading
        level={variant === 'list-view' ? 'h3' : 'h1'}
        size={variant === 'list-view' ? 'm' : 'l'}
        content={title}
      />

      {location[0].venue && (
        <div className="event__venue">
          <LocationLink url={location[0].website.url} variant={variant}>
            <strong>{location[0].venue}</strong>
          </LocationLink>

          {location[0].address.text && (
            <>
              {' '}
              -{' '}
              <LocationLink url={location[0].google_maps_url} variant={variant}>
                {location[0].address.text.replace('\n', ', ')}
              </LocationLink>
            </>
          )}
        </div>
      )}

      <div className="event__cost">
        {cost === null ? createTranslation('Free', locale) : `$${cost}`}
      </div>
    </EventDetailsWrapper>
  );
};

export default EventDetails;

/**
 * Styled Components
 *
 */
const EventDetailsWrapper = styled.div`
  .event__badge {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: var(--font-weight-semi-bold);
    border: 0.1rem solid var(--color-black);
    display: inline-block;
    padding: var(--space-xxs) var(--space-xs);
    border-radius: 0.5rem;
    margin-bottom: ${props =>
      props.variant === 'list-view' ? 'var(--space-m)' : 0};
  }

  .event__badge--recurring {
    color: var(--color-rail-park-green);
    border-color: var(--color-neon-green);
  }

  .event__date,
  .event__venue,
  .event__cost {
    font-size: ${props =>
      props.variant === 'list-view' ? '1.5rem' : 'clamp(1.5rem, 3.5vw, 3rem)'};
    line-height: 1.4;
  }

  .event__date {
    margin: ${props =>
      props.variant === 'list-view'
        ? '0 0 var(--space-xs)'
        : 'var(--space-m) 0 var(--space-s)'};

    @media (${bp.min.sm}) {
      margin: ${props =>
        props.variant === 'list-view'
          ? ' 0 0 var(--space-xxs)'
          : ' var(--space-l) 0'};
    }
  }

  .event__venue {
    margin-top: ${props =>
      props.variant === 'list-view' ? 'var(--space-s)' : 'var(--space-l)'};

    @media (${bp.min.sm}) {
      margin-top: var(--space-m);
    }
  }

  .event__cost {
    margin-top: ${props =>
      props.variant === 'list-view' ? 'var(--space-xxs)' : 0};
    margin-bottom: ${props =>
      props.variant === 'list-view' ? 0 : 'var(--space-xl)'};

    @media (${bp.min.sm}) {
      margin-bottom: 0;
    }
  }
`;
