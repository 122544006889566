import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import {
  getActiveDocLanguage,
  getFormattedEvents,
  getSiteMeta,
} from '../assets/js/utilities';
import { Col, Row } from '../components/grid-system';
import bp from '../assets/js/breakpoints';
import linkResolver from '../utilities/link-resolver';

import Layout from '../components/Layout';
import Image from '../components/Image';
import Seo from '../components/Seo';
import NewsEventsCallout from '../components/NewsEventsCallout';
import SliceZone from '../components/SliceZone';
import EventDetails from '../components/EventDetails';
import AnimateFadeIn from '../components/AnimateFadeIn';

const Event = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;
  const news = data.allPrismicNews;
  const events = data.allPrismicEvent;

  // Get page data
  const event = data.prismicEvent;
  const { title, body, featured_image, featured_excerpt } = event.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(event);
  const defaultMeta = getSiteMeta(layout.data);

  const formattedEvent = getFormattedEvents(
    [{ node: event }],
    activeDoc.locale
  );

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data}>
      <Seo
        title={title}
        description={featured_excerpt.text}
        image={featured_image.url}
        lang={activeDoc.locale}
        defaultMeta={defaultMeta}
      />

      <AnimateFadeIn>
        {featured_image.gatsbyImageData && (
          <ImageWrapper>
            <Image
              image={featured_image}
              aspectRatio={16 / 9}
              borderRadius={5}
            />
          </ImageWrapper>
        )}

        <EventBody>
          <Row>
            <Col
              base={12}
              sm={10}
              md={8}
              xl={6}
              smOffset={1}
              mdOffset={2}
              xlOffset={3}
            >
              <EventDetails
                event={formattedEvent[0].data}
                locale={activeDoc.locale}
              />

              {body.length > 0 && (
                <div>
                  <SliceZone slices={body} />
                </div>
              )}
            </Col>
          </Row>
        </EventBody>

        <NewsEventsCallout
          news={news}
          events={events}
          lang={activeDoc.locale}
        />
      </AnimateFadeIn>
    </Layout>
  );
};

export default withPrismicPreview(Event, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);

/**
 * Styled Components
 *
 */
const ImageWrapper = styled.div`
  margin-top: var(--space-m);

  @media (${bp.min.xs_sm}) {
    margin-top: 0;
  }
`;

const EventBody = styled.div`
  margin: var(--space-xl) 0;

  @media (${bp.min.sm}) {
    margin: 7.2rem 0 var(--space-xxl);
  }

  @media (${bp.min.lg}) {
    margin: 9.6rem 0 var(--space-xxxl);
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($uid: String, $language: String) {
    prismicEvent(uid: { eq: $uid }, lang: { eq: $language }) {
      _previewable
      data {
        title
        featured_image {
          alt
          gatsbyImageData(width: 1680)
          url
        }
        featured_excerpt {
          text
        }
        start
        end
        all_day
        cost
        recurring_event
        frequency
        interval
        recurring_end
        excluded_dates {
          date
        }
        location {
          venue
          address {
            richText
            text
          }
          google_maps_url
          website {
            url
          }
        }
        body {
          ... on PrismicEventDataBodyText {
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
          ... on PrismicEventDataBodyLargeImage {
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(width: 1080)
              }
            }
          }
          ... on PrismicEventDataBodyEmbed {
            slice_type
            primary {
              embed {
                text
              }
            }
          }
          ... on PrismicEventDataBodyYoutube {
            slice_type
            primary {
              youtube_id
            }
          }
          ... on PrismicEventDataBodyTwoColumnText {
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
          ... on PrismicEventDataBodyThreeColumnText {
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
        }
      }
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
    allPrismicNews(
      filter: { lang: { eq: $language }, data: { post_type: { eq: "News" } } }
    ) {
      edges {
        node {
          ...NewsItems
        }
      }
    }
    allPrismicEvent(filter: { lang: { eq: $language } }) {
      edges {
        node {
          ...EventItems
        }
      }
    }
  }
`;
