import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { getDuration, timeConvert } from '../assets/js/utilities';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import rrulePlugin from '@fullcalendar/rrule';

const EventsMonthView = ({ events, locale }) => {
  const [currentEvents, setCurrentEvents] = useState([]);

  const fullCalendar = useRef(null);

  useEffect(() => {
    const formattedEvents = events.map(event => {
      const {
        id,
        url,
        data: {
          title,
          start,
          end,
          all_day: allDay,
          recurring_event: isRecurring,
          frequency: freq,
          interval,
          recurring_end: recurringEnd,
          excluded_dates: excludedDates,
        },
      } = event.node;

      const singleEvent = {
        id,
        url,
        title,
        start,
        end,
        allDay,
      };

      // Get difference between start and end time to calculate duration in
      //  hours and minutes (HH:MM)
      const duration = timeConvert(getDuration(start, end));

      // Extracting start time since RRule requires 'exdate' and 'until' to
      //  include the same time as 'dtstart'
      const time = start.split('T')[1];
      const excluded = excludedDates
        .filter(date => date.date !== null)
        .map(date => `${date.date}T${time}`);

      const recurringEvent = {
        id,
        url,
        title,
        allDay,
        rrule: {
          dtstart: start,
          until: `${recurringEnd}T${time}`,
          freq,
          interval: parseInt(interval),
        },
        exdate: excluded,
        duration,
      };

      if (isRecurring) {
        return recurringEvent;
      } else {
        return singleEvent;
      }
    });

    setCurrentEvents(formattedEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MonthViewWrapper>
      <FullCalendar
        ref={fullCalendar}
        plugins={[dayGridPlugin, rrulePlugin]}
        initialView="dayGridMonth"
        editable={false}
        displayEventEnd={true}
        events={currentEvents}
        aspectRatio={1.7}
        fixedWeekCount={false}
        contentHeight="auto"
        locale={locale}
        views={{
          dayGridMonth: {
            eventTimeFormat: {
              hour: 'numeric',
              minute: 'numeric',
            },
          },
        }}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: '',
        }}
      />
    </MonthViewWrapper>
  );
};

export default EventsMonthView;

/**
 * Styled Components
 *
 */
const MonthViewWrapper = styled.div`
  margin: 0;

  --fc-border-color: #ededed;

  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }

  .fc-scrollgrid-section-sticky > * {
    background-color: var(--color-off-white);
    border: none;
  }

  .fc-button-primary.fc-prev-button,
  .fc-button-primary.fc-next-button {
    background-color: transparent;
    color: var(--color-black);
    border-color: transparent;
    padding: 0 0.4rem;

    &:hover,
    &:focus {
      background-color: transparent !important;
      color: var(--color-black) !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .fc-daygrid-block-event {
    background-color: var(--color-neon-green-light);
    padding: var(--space-xxxs) var(--space-xs);
    border-radius: 0.2rem;
    border: none;
    line-height: 1.2;
    margin-bottom: 0.4rem !important;

    &:hover {
      background-color: var(--color-neon-green);
    }

    .fc-event-title,
    .fc-event-time {
      color: var(--color-black);
    }

    &.fc-daygrid-event {
      margin: 0 -1rem;
    }
  }

  .fc-scrollgrid table {
    border-left-style: initial;
  }

  .fc-daygrid-day-top {
    flex-direction: row;
    font-weight: var(--font-weight-semi-bold);
  }

  .fc-toolbar {
    justify-content: flex-start;

    .fc-toolbar-title {
      margin-left: var(--space-s);
    }

    .fc-today-button {
      --color: var(--color-black);

      text-transform: capitalize;
      font-size: 1.6rem;
      background-color: transparent;
      border-color: var(--color);
      color: var(--color);
      cursor: pointer;
      transition: all 150ms var(--ease-cubic-in-out);

      &:hover:not(:disabled) {
        --color: var(--color-rail-park-green);
      }

      &:active {
        background-color: var(--color-rail-park-green);
        border-color: var(--color-rail-park-green);
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .fc-col-header-cell {
    border: none;
    border-bottom: 0.1rem solid var(--fc-border-color);
    text-transform: uppercase;
    font-size: 1.4rem;
    text-align: left;
    letter-spacing: 0.1rem;
    opacity: 0.6;
  }

  .fc-daygrid-day-frame {
    padding: var(--space-xxxs) var(--space-xs);
  }

  .fc-daygrid-event.fc-daygrid-dot-event {
    white-space: normal;

    &:hover {
      background-color: transparent;

      .fc-event-time {
        opacity: 1;
      }
    }
  }

  .fc-daygrid-event-dot {
    display: none;
  }

  .fc-daygrid-dot-event {
    display: block;
  }

  .fc-daygrid-day.fc-day-today {
    --fc-today-bg-color: none;

    .fc-daygrid-day-number {
      color: var(--color-rail-park-green);
    }
  }

  .fc-daygrid-day-number {
    &:hover {
      color: initial;
    }
  }

  .fc-daygrid-day.fc-day-past {
    opacity: 0.85;
    transition: opacity 150ms var(--ease-cubic-in-out);

    &:hover {
      opacity: 1;
    }
  }

  .fc-h-event .fc-event-main-frame {
    align-items: center;
  }

  .fc-event-time,
  .fc-event-title {
    line-height: 1.4;
  }

  .fc-event-time {
    font-size: 1.4rem;
    font-weight: var(--font-weight-normal);
    opacity: 0.6;
    text-transform: lowercase;
    transition: opacity 150ms var(--ease-cubic-in-out);
  }

  .fc-event-title {
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
  }

  .fc-daygrid-event-harness {
    margin-bottom: var(--space-xxs);
  }
`;
