import React from 'react';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';
import { Row, Col } from './grid-system';

import Heading from './Heading';
import ArrowIcon from '../components/ArrowIcon';

const LinkColumns = ({ title, subtitle, listItems }) => {
  return (
    <LinkColumnsWrapper>
      <Row baseline="xs">
        <Col base={12} xs_sm={6} md={4}>
          <Heading level="h3" size="m" content={title} />
        </Col>

        {subtitle && (
          <Col base={12} xs_sm={6} md={4}>
            <p className="subtitle">{subtitle}</p>
          </Col>
        )}
      </Row>

      <Row as="ul">
        {listItems.map(link => (
          <Col as="li" base={12} xs_sm={6} md={4} key={link.name}>
            <a href={link.website.url} target="blank" rel="noreferrer">
              {link.name}

              <span>
                <ArrowIcon
                  width={20}
                  rotation={45}
                  color="var(--color-neon-green)"
                />
              </span>
            </a>
          </Col>
        ))}
      </Row>
    </LinkColumnsWrapper>
  );
};

export default LinkColumns;

/**
 * Styled Components
 *
 */
const LinkColumnsWrapper = styled.div`
  .subtitle {
    display: none;

    @media (${bp.min.sm_md}) {
      display: block;
    }
  }

  ul {
    margin-top: var(--space-xxs);

    @media (${bp.min.xs_sm}) {
      margin-top: var(--space-xl);
    }

    @media (${bp.min.sm_md}) {
      margin-top: var(--space-xxl);
    }
  }

  a {
    height: 100%;
    position: relative;
    min-height: 9.6rem;
    display: flex;
    align-items: center;
    padding: var(--space-xs) 10% var(--space-xs) 0;
    font-size: 2rem;
    transition: color 150ms var(--ease-cubic-in-out);

    @media (hover: hover) {
      &:hover {
        &:after {
          transform: scaleX(1);
          transform-origin: left center;
        }

        span {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
      }
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 0.1rem;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &:before {
      background-color: var(--color-black);
    }

    &:after {
      transform: scaleX(0);
      transform-origin: right center;
      background-color: var(--color-neon-green);
      transition: transform 300ms var(--ease-cubic-in-out);
    }

    span {
      position: absolute;
      right: 0;
      margin-top: 0.4rem;
      opacity: 0;
      transform: translate3d(-0.4rem, 0.4rem, 0);
      transition: all 300ms var(--ease-cubic-in-out) 100ms;
    }
  }
`;
